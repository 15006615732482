import BaseReducer from "../../utilities/BaseReducer";
import MusicLabelsChangeRequestReleaseShadowAction from "./MusicLabelsChangeRequestReleaseShadowAction";

export default class musicLabelsChangeRequestReleaseShadowReducer extends BaseReducer {
  initialState = {
    musiclabelRequestChangeReleaseShadow: [],
  };
  [MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      musiclabelRequestChangeReleaseShadow: action.payload,
    };
  }
}
