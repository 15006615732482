import BaseReducer from "../../utilities/BaseReducer";
import MusicLabelsChangeRequestReleaseAction from "./MusicLabelsChangeRequestReleaseAction";

export default class RequestReleaseByIdReducer extends BaseReducer {
  initialState = {
    changeRequestReleaseById: [],
  };

  [MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      changeRequestReleaseById: action.payload,
    };
  }
}
