import {
  Box,
  Tab,
  Grid,
  Typography,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import environment from "environment";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import DeliveredReleasesTracks from "./DeliveredReleasesTracks";
// import ReleasesDeliveryTab from "./ReleasesDeliveryTab";
// import DeliveredReleaseHistory from "./DeliveredReleaseHistory";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import UpdatePlayLinks from "./TracksPlayLinks";
import TrackAction from "../../stores/track/TrackAction";
import LaunchIcon from "@mui/icons-material/Launch";
import MusicLabelsChangeRequestReleaseAction from "../../stores/musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseAction";
import ChangeRequestReleaseTracks from "./ChangeRequestReleaseTracks";
// import MainPlayLink from "./MainPlayLink";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReleaseChangeForm from "./subPages/ReleaseChangeForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiButton from "../../components/muiButton/MuiButton";
import MusicLabelsChangeRequestTrackAction from "../../stores/musicLabelsChangeRequestTrack/MusicLabelsChangeRequestTrackAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import CoverPicUploadDialog from "../../components/releaseFormComponents/releaseInfo/CoverPicUploadDialog";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChangedCoverPicUploadDialog from "./ChangedCoverPicUploadDialog";

const mapStateToProps = (state) => {
  return {
    isRequesting: selectRequesting(state, [
      ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID,
      MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID,
      MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK,
    ]),
    finalReleaseById: state.finalReleaseById.finalReleaseById || {},
    changeRequestReleaseById:
      state.changeRequestReleaseById.changeRequestReleaseById || {},
    musiclabelRequestChangeTrack:
      state.musiclabelRequestChangeTrack.musiclabelRequestChangeTrack || [],
  };
};

const ChangeRequestReleaseDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [imgUrl, setImgUrl] = useState("");
  const [imgChangedUrl, setImgChangedUrl] = useState("");

  const [imageLoader, setImageLoader] = useState(false);
  const [value, setValue] = React.useState("1");
  const [openReleaseForm, setOpenReleaseForm] = useState(false);

  const [tabvalue, settabvalue] = useState(null);
  const [fieldValue, setfieldValue] = useState(null);

  const [SubmitForReviewLoading, setSubmitForReviewLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    settabvalue(false);
    props.dispatch(TrackAction.requestTrackByIdClear());
  };

  function fetchChangeRequestReleaseById(requestId, params) {
    const callBack = (res) => {
      if (res?.found === true) {
        setImgChangedUrl(() => {
          res?._source?.thumbnail_url &&
            RetrieveImageChangedUrl(res?._source?.thumbnail_url);
        });
      }
    };

    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
        requestId,
        params,
        callBack
      )
    );
  }

  function fetchReleaseById(releaseId, params) {
    const callBack = (res) => {
      if (res?.found === true) {
        setImgUrl(() => {
          res?._source?.thumbnail_url &&
            RetrieveImageUrl(res?._source?.thumbnail_url);
        });
      }
    };
    props.dispatch(
      ReleasesAction.requestFinalReleaseById(releaseId, params, callBack)
    );
  }

  const isKeyPresent = (key) => {
    let obj = props?.changeRequestReleaseById?._source;
    return obj?.hasOwnProperty(key);
  };

  useEffect(() => {
    const param = {
      current_page: "changeRequestForm",
    };

    if (location?.state?.releaseId) {
      fetchChangeRequestReleaseById(location?.state?.requestId, param);
      fetchReleaseById(location?.state?.releaseId, param);
    }
  }, [location?.state?.releaseId]);

  function RetrieveImageUrl(thumbnail_url) {
    setImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=changeRequestForm&path=thumbnail/${thumbnail_url}&release_id=${location?.state?.releaseId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
      });
  }

  function RetrieveImageChangedUrl(thumbnail_url) {
    setImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=changeRequestForm&path=thumbnail/${thumbnail_url}&release_id=${location?.state?.releaseId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);
        imageUrl = URL.createObjectURL(blob);

        setImgChangedUrl(imageUrl);
      });
  }

  function convertHyphenatedString(string) {
    let hypenatedString = string?.replace(/ /g, "-");
    return hypenatedString;
  }

  const [badgeCount, setBadgeCount] = useState(null);
  const [badgeImageCount, setBadgeImageCount] = useState(null);

  const { _source } = props?.finalReleaseById && props.finalReleaseById;
  const { _source: _sourceFromChangeRequest } = props?.changeRequestReleaseById;

  useEffect(() => {
    function countKeysInChangesToRelease(keys) {
      let obj1 = _sourceFromChangeRequest;
      let obj2 = _source;

      return keys.reduce((count, key) => {
        if (obj1?.hasOwnProperty(key)) {
          const value1 = obj1[key];
          const value2 = obj2?.[key];

          // Check if values are different (deep comparison for complex types)
          if (!areValuesEqual(value1, value2)) {
            return count + 1;
          }
        }
        return count;
      }, 0);
    }

    function areValuesEqual(val1, val2) {
      if (val1 === val2) {
        return true; // Handles primitive equality and identical references
      }

      if (typeof val1 !== typeof val2) {
        return false; // Different types
      }

      if (Array.isArray(val1) && Array.isArray(val2)) {
        // Compare arrays
        return (
          val1.length === val2.length &&
          val1.every((item, index) => areValuesEqual(item, val2[index]))
        );
      }

      if (typeof val1 === "object" && val1 !== null && val2 !== null) {
        // Compare objects
        const keys1 = Object.keys(val1);
        const keys2 = Object.keys(val2);
        return (
          keys1.length === keys2.length &&
          keys1.every(
            (key) =>
              val2.hasOwnProperty(key) && areValuesEqual(val1[key], val2[key])
          )
        );
      }

      return false; // If none of the above cases matched, the values are different
    }

    const keysToCheck1 = [
      "title",
      "music/album",
      "artists",
      "featuring_artists",
      "genre_type",
      "sub_genre_type",
      "meta_language",
      "plan_id",
      "physical_release_date",
      "production_year",
      "copyright_year",
      "phonographic_copyright_year",
      "copyrighted_to",
      "published_by",
      "producer_catalogue_number",
    ];

    const keysToCheck2 = ["thumbnail_url"];

    if (!props.isRequesting) {
      setBadgeCount(countKeysInChangesToRelease(keysToCheck1));
      setBadgeImageCount(countKeysInChangesToRelease(keysToCheck2));
    }
  }, [_sourceFromChangeRequest, _source, props.isRequesting]);

  const SubmitForReview = () => {
    const newTrackChanges =
      props.changeRequestReleaseById?._source?.change_request_tracks?.map(
        (item) => {
          return {
            assets_id: item.assets_id?.id ?? "",
            track_id: item.track_id,
          };
        }
      );
    if (props.musiclabelRequestChangeTrack?.hits?.hits?.length !== 0) {
      props.musiclabelRequestChangeTrack?.hits?.hits?.forEach((track) => {
        if (!newTrackChanges.find((item) => item.track_id === track._id)) {
          newTrackChanges.push({
            track_id: track._id, // Push only if the track ID is not already present
          });
        }
      });
    }

    setSubmitForReviewLoading(true);
    let obj = {
      change_request_status: "under_review",
      release_id: location?.state?.releaseId,
      change_request_tracks: newTrackChanges,
    };

    const callBack = (res) => {
      if (res.status === "success") {
        navigate("/changeRequests");
      }
      setSubmitForReviewLoading(false);
    };

    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestPutMusicLabelsChangeRequestRelease(
        obj,
        props.changeRequestReleaseById?._id,
        {},
        callBack
      )
    );
  };

  const [openCoverPicUploader, setOpenCoverPicUploader] = useState(false);

  const [highImage, sethighImage] = useState("");
  const [prevImage, setprevImage] = useState("");

  // Hight Quality Image
  async function RetrieveHighImageUrl(thumbnail_url) {
    sethighImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let highQualityImage = "";
    const url = await fetch(
      `${environment.api.retriveAsset}?current_page=changeRequestForm&path=${thumbnail_url}&release_id=${props?.finalReleaseById._id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        sethighImageLoader(false);
        highQualityImage = URL.createObjectURL(blob);
        sethighImage(highQualityImage);

        return highQualityImage;
        // let id = localStorage.getItem("id");
        // fetchResource(id);
      });

    return url;
  }

  async function RetrievePrevImageUrl(thumbnail_url) {
    setPreviouseLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let highQualityImage = "";
    const url = await fetch(
      `${environment.api.retriveAsset}?current_page=changeRequestForm&path=${thumbnail_url}&release_id=${props?.finalReleaseById._id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setPreviouseLoader(false);
        highQualityImage = URL.createObjectURL(blob);
        setprevImage(highQualityImage);

        return highQualityImage;
      });

    return url;
  }

  useEffect(() => {
    if (openCoverPicUploader) {
      sethighImage(() => {
        if (
          !props.isRequesting &&
          _sourceFromChangeRequest &&
          _sourceFromChangeRequest.thumbnail_url
        ) {
          RetrieveHighImageUrl(_sourceFromChangeRequest.thumbnail_url);
        } else {
          !props.isRequesting &&
            _source &&
            _source.thumbnail_url &&
            RetrieveHighImageUrl(_source.thumbnail_url);
        }
      });
      setprevImage(() => {
        !props.isRequesting &&
          _source &&
          _source.thumbnail_url &&
          RetrievePrevImageUrl(_source.thumbnail_url);
      });
    }
  }, [openCoverPicUploader]);

  const [highImageLoader, sethighImageLoader] = useState(false);
  const [previouseLoader, setPreviouseLoader] = useState(false);

  const handleFileUpload = (value, setFieldValue, event) => {
    const MAX_FILE_SIZE_MB = 5;
    let formData = new FormData();

    const fileSizeMB = event.target?.files[0]?.size / (1024 * 1024); // Convert bytes to MB

    const image = new Image();
    const reader = new FileReader();

    reader.onloadend = function () {
      image.src = reader.result;

      image.onload = function () {
        const width = this.width;
        const height = this.height;

        if (fileSizeMB <= MAX_FILE_SIZE_MB) {
          if (width === 3000 && height === 3000) {
            sethighImageLoader(true);

            fetch(
              `${environment.api.fileUpload}/add?current_page=changeRequestForm&release_id=${props?.finalReleaseById._id}&thumbnail=true`,
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + access_tocken,
                },
                body: formData,
              }
            )
              .then((res) => res.json())
              .then((response) => {
                if (response?.status === "success") {
                  setfieldValue(response.url);
                  RetrieveHighImageUrl(response.url);
                } else {
                  sethighImageLoader(false);
                }
              });
          } else {
            // Image doesn't meet the required dimensions, handle the error.
            alert("Please select a 3000x3000 pixel image.");
          }
        } else {
          // Image doesn't meet the required dimensions, handle the error.
          alert(`Please select a file less than ${MAX_FILE_SIZE_MB}MB.`);
        }
      };
    };

    if (value) {
      reader.readAsDataURL(value);
    }

    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
  };

  function SaveThumbnailUrl() {
    let obj = {};

    Object.assign(
      obj,
      fieldValue && {
        thumbnail_url: fieldValue,
      },
      {
        release_id: location?.state?.releaseId,
      }
    );

    const callBack = () => {
      const param = {
        current_page: "changeRequestForm",
      };

      fetchChangeRequestReleaseById(location?.state?.requestId, param);
      fetchReleaseById(location?.state?.releaseId, param);
    };
    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestPutMusicLabelsChangeRequestRelease(
        obj,
        props.changeRequestReleaseById?._id,
        {},
        callBack
      )
    );
  }

  // Rejected comment box component
  const RejectedCommentBox = ({ comment }) => {
    return (
      <Box
        sx={{
          border: `1px solid ${theme?.palette?.text?.error}`,
          borderRadius: "8px",
          backgroundColor: theme?.palette?.background?.error,
          padding: "16px",
          marginBottom: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "#d80000", // Dark red text for rejection
            fontWeight: "bold",
          }}
        >
          Rejected Comment:
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "#333" }}>
          {comment}
        </Typography>
      </Box>
    );
  };

  return (
    <Box p={1}>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <IconButton>
          <ArrowCircleLeftIcon
            style={{
              height: "40px",
              width: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.removeItem("change_request_id");

              navigate("/changeRequests");
            }}
          />
        </IconButton>
      </Grid>
      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"flex-start"}
        // className="bg-warning"
        gap={1}
      >
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={8}
          container
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <Grid item>
            <h3>{formatName(`Edit Change Request`)}</h3>
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          alignItems={"center"}
          justifyContent="flex-end"
          // className="bg-success"
        >
          <Typography
            component={"div"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenReleaseForm(true);
            }}
          >
            <Badge badgeContent={badgeCount} color="secondary">
              <ModeEditIcon />
            </Badge>
            Edit Details
          </Typography>
        </Grid>

        {(_sourceFromChangeRequest?.change_request_status === "draft" ||
          _sourceFromChangeRequest?.change_request_status === "rejected") && (
          <Grid
            item
            xs={1}
            alignItems={"center"}
            justifyContent="flex-start"
            // className="bg-info"
          >
            <MuiButton
              name={
                _sourceFromChangeRequest?.change_request_status === "draft"
                  ? "Submit"
                  : "Re-Submit"
              }
              loading={SubmitForReviewLoading}
              onClick={() => {
                SubmitForReview();
              }}
            />
          </Grid>
        )}
      </Grid>
      <LoadingIndicator isActive={props.isRequesting}>
        {_sourceFromChangeRequest?.comments &&
          _sourceFromChangeRequest?.change_request_status === "rejected" && (
            <RejectedCommentBox comment={_sourceFromChangeRequest?.comments} />
          )}

        <div style={{ marginBottom: "20px" }}>
          <Box className="d-flex">
            <Grid
              item
              sx={{
                width: {
                  lg: "273px",
                  md: "200px",
                  sm: "150px",
                },
                height: {
                  lg: "250px",
                  md: "180px",
                  sm: "130px",
                },
                // border: `1px solid ${theme.palette.borderColor.imageBox}`,
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                m: 0,
                p: 0,
              }}
            >
              {(imgUrl || imgChangedUrl) && !props.isRequesting ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Set background color on hover
                    opacity: 1, // Show icons on hover
                    "& > .icon-container": {
                      display: "block", // Show icons container on hover
                    },

                    "&:hover": {
                      opacity: 0.7,
                    },
                  }}
                >
                  <Box
                    className="icon-container"
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 0,
                      display: "none",
                    }}
                  >
                    <Badge badgeContent={badgeImageCount} color="secondary">
                      <Tooltip title={"Edit"}>
                        <ModeEditIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleDownloadClick();
                            setOpenCoverPicUploader(true);
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Badge>

                    <ChangedCoverPicUploadDialog
                      open={openCoverPicUploader && _source}
                      highImage={highImage}
                      prevImage={prevImage}
                      highImageLoader={highImageLoader}
                      previouseLoader={previouseLoader}
                      onClose={() => {
                        setOpenCoverPicUploader(false);
                      }}
                      onSave={() => {
                        SaveThumbnailUrl();
                      }}
                      thumbnail_url={
                        _source && _source.thumbnail_url
                          ? _source.thumbnail_url
                          : null
                      }
                      modified={_sourceFromChangeRequest.thumbnail_url}
                      status={_sourceFromChangeRequest?.change_request_status}
                    />

                    <input
                      id="thumbnail-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUpload(e.target.files[0], setfieldValue, e);
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: {
                        lg: "273px",
                        md: "200px",
                        sm: "150px",
                      },
                      height: {
                        lg: "250px",
                        md: "180px",
                        sm: "130px",
                      },
                      backgroundColor: theme.palette.background.imageBox,
                    }}
                  >
                    <img
                      src={
                        !props.isRequesting
                          ? imgChangedUrl
                            ? imgChangedUrl
                            : !props.isRequesting && imgUrl
                          : null
                      }
                      alt="Cover Picture"
                      onError={() => {
                        // Error handling code goes here
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        // objectFit: "cover",
                      }}
                    />
                  </Box>
                </Box>
              ) : imageLoader ? (
                <CircleLoader />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileArrowUp}
                    style={{
                      color: theme.palette.common.grey,
                      width: "37px",
                      height: "50px",
                    }}
                  ></FontAwesomeIcon>
                  <Typography
                    component={"span"}
                    sx={{
                      color: theme.palette.common.grey,
                      fontSize: "16px",
                      mt: "15px",
                    }}
                  ></Typography>
                </Box>
              )}
            </Grid>

            <Grid container item lg={12} md={12} sm={12} ml={3} gap={2}>
              <Grid container item lg={12} md={12} sm={12}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <h2>
                    {isKeyPresent("title")
                      ? props?.changeRequestReleaseById?._source?.title
                      : props.finalReleaseById?._source?.title}
                  </h2>
                </Grid>
              </Grid>
              <Grid item lg={5.5} md={8} sm={12}>
                {[
                  {
                    label: "Title",
                    value: isKeyPresent("title")
                      ? props?.changeRequestReleaseById?._source?.title
                      : props.finalReleaseById?._source?.title,
                  },
                  {
                    label: "Version",
                    value: isKeyPresent("music/album")
                      ? props.changeRequestReleaseById?._source &&
                        props.changeRequestReleaseById?._source[
                          "music/album"
                        ] &&
                        Array.isArray(
                          props.changeRequestReleaseById?._source["music/album"]
                        ) &&
                        props.changeRequestReleaseById?._source["music/album"]
                          ?.length !== 0
                        ? props.changeRequestReleaseById?._source[
                            "music/album"
                          ].join(",")
                        : ""
                      : props.finalReleaseById?._source &&
                        props.finalReleaseById?._source["music/album"] &&
                        Array.isArray(
                          props.finalReleaseById?._source["music/album"]
                        ) &&
                        props.finalReleaseById?._source["music/album"]
                          ?.length !== 0
                      ? props.finalReleaseById?._source["music/album"].join(",")
                      : "",
                  },
                  {
                    label: "Label",
                    value: isKeyPresent("label")
                      ? props.changeRequestReleaseById?._source?.label
                          .label_name
                      : props.finalReleaseById?._source?.label.label_name,
                  },
                  { label: "Parent Label", value: null },
                  {
                    label: "UPC",
                    value: isKeyPresent("UPC_EAN")
                      ? props.changeRequestReleaseById?._source?.UPC_EAN
                      : props.finalReleaseById?._source?.UPC_EAN,
                  },
                  {
                    label: "Catalog no.",
                    value: isKeyPresent("producer_catalogue_number")
                      ? props.changeRequestReleaseById?._source
                          ?.producer_catalogue_number
                      : props.finalReleaseById?._source
                          ?.producer_catalogue_number,
                  },
                  {
                    label: "Genre",
                    value: isKeyPresent("genre_type")
                      ? `${props.changeRequestReleaseById?._source?.genre_type?.genre_name} > ${props.changeRequestReleaseById?._source?.sub_genre_type?.subgenre_name}`
                      : `${props.finalReleaseById?._source?.genre_type?.genre_name} > ${props.finalReleaseById?._source?.sub_genre_type?.subgenre_name}`,
                  },
                  { label: "Compilation", value: null },
                ].map((item, index) => (
                  <Typography
                    key={index}
                    component={"p"}
                    width="100%"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold" }}
                      width="50%"
                    >
                      {item.label} :
                    </Typography>
                    <Typography component={"span"} width="50%">
                      {item.value}
                    </Typography>
                  </Typography>
                ))}
              </Grid>
              <Grid item lg={5.5} md={8} sm={12}>
                {[
                  {
                    label: "Release Date",
                    value: isKeyPresent("main_release_date")
                      ? props.changeRequestReleaseById?._source
                          ?.main_release_date
                      : props.finalReleaseById?._source?.main_release_date,
                  },
                  {
                    label: "Original Release Date",
                    value: isKeyPresent("physical_release_date")
                      ? props.changeRequestReleaseById?._source?.physical_release_date?.slice(
                          0,
                          10
                        )
                      : props.finalReleaseById?._source?.physical_release_date?.slice(
                          0,
                          10
                        ),
                  },
                  {
                    label: "Pre-Order Date",
                    value: isKeyPresent("pre_order_release_date")
                      ? props.changeRequestReleaseById?._source
                          ?.pre_order_release_date
                      : props.finalReleaseById?._source?.pre_order_release_date,
                  },
                  {
                    label: "Price Tier",
                    value: isKeyPresent("plan_id")
                      ? props.changeRequestReleaseById?._source?.plan_id
                          .plan_name
                      : props.finalReleaseById?._source?.plan_id.plan_name,
                  },
                  {
                    label: "C Line",
                    value: isKeyPresent("copyrighted_to")
                      ? `${
                          props.changeRequestReleaseById?._source
                            ?.copyrighted_to
                            ? "©"
                            : ""
                        } ${
                          props.changeRequestReleaseById?._source
                            ?.copyright_year
                        } ${
                          props.changeRequestReleaseById?._source
                            ?.copyrighted_to
                        }`
                      : `${
                          props.finalReleaseById?._source?.copyrighted_to
                            ? "©"
                            : ""
                        } ${props.finalReleaseById?._source?.copyright_year} ${
                          props.finalReleaseById?._source?.copyrighted_to
                        }`,
                  },
                  {
                    label: "P Line",
                    value: isKeyPresent("published_by")
                      ? `${
                          props.finalReleaseById?._source?.published_by
                            ? "Ⓟ"
                            : ""
                        } ${
                          props.finalReleaseById?._source
                            ?.phonographic_copyright_year
                        } ${props.finalReleaseById?._source?.published_by}`
                      : `${
                          props.finalReleaseById?._source?.published_by
                            ? "Ⓟ"
                            : ""
                        } ${
                          props.finalReleaseById?._source
                            ?.phonographic_copyright_year
                        } ${props.finalReleaseById?._source?.published_by}`,
                  },
                  { label: "PDF Booklet", value: null },
                ].map((item, index) => (
                  <Typography
                    key={index}
                    component={"p"}
                    width="100%"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold" }}
                      width="50%"
                    >
                      {item.label} :
                    </Typography>
                    <Typography component={"span"} width="50%">
                      {item.value}
                    </Typography>
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>

          <br />

          <TabContext value={value}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.tab,
                boxShadow: "0 3px 6px 0px #c6bcbc33",
              }}
            >
              <TabList
                onChange={handleChange}
                sx={{
                  "& .MuiTabs-indicator": { display: "none" },
                  "& .MuiTab-root": {
                    position: "relative",
                    zIndex: 100,
                    border: 0,
                    height: "54px",
                    borderRadius: 0,
                    backgroundColor: theme.palette.background.tab,
                    textTransform: "none",
                    color: "#2a5e6c",
                    "&.Mui-selected": {
                      borderRadius: "0 0 15px 0",
                      border: `1px solid #6c64640a`,
                      boxShadow: theme.shadows,
                      zIndex: 2,
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.text.primary,
                    },
                  },
                }}
              >
                <Tab label="Tracks" value="1" />
                {/* {_sourceFromChangeRequest?.comments && (
                  <Tab label="Comments" value="2" />
                )} */}
                {/* <Tab label="History" value="3" /> */}
              </TabList>
            </Box>

            <Box className="scrollable" sx={{ height: `auto`, width: "100%" }}>
              <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
                <ChangeRequestReleaseTracks
                  releaseId={location?.state?.releaseId}
                  status={_sourceFromChangeRequest?.change_request_status}
                />
              </TabPanel>
              {/* <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
             
              </TabPanel> */}
              {/* <TabPanel value="3" sx={{ padding: 0, margin: 0 }}>
                  <DeliveredReleaseHistory
                    releaseId={location?.state?.requestId}
                  />
                </TabPanel>  */}
            </Box>
          </TabContext>
        </div>
      </LoadingIndicator>

      <ReleaseChangeForm
        open={openReleaseForm}
        onClose={() => setOpenReleaseForm(false)}
        requestId={props.changeRequestReleaseById?._id}
        status={_sourceFromChangeRequest?.change_request_status}
      />
    </Box>
  );
};

export default connect(mapStateToProps)(ChangeRequestReleaseDetails);
