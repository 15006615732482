import React, { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { LocalizationProvider, LicenseInfo } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./dateRangePicker.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import MuiTextField from "../muiTextField/MuiTextField";
import dayjs from "dayjs";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const DatePicker = forwardRef(
  ({ value, onChange, onKeyDown, open, disableFuture, disablePast }) => {
    const theme = useTheme();

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DateRangePicker"]}
          style={{}}
          sx={{
            margin: 0,
            padding: 0,
          }}
        >
          <DateRangePicker
            value={value && [dayjs(value[0]), dayjs(value[1])]}
            onChange={onChange}
            open={open}
            disableFuture={disableFuture}
            disablePast={disablePast}
            format="DD/MM/YYYY"
            slots={{
              textField: MuiTextField,
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                size: "small",
                label: "",
                focused: true,
                style: {
                  outline: `1px ${theme.palette.borderColor.textField}`,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "8px",
                },
              },
            }}
          />
        </DemoContainer>
        {/* <DateRangePicker
          // inputFormat="YYYY-MM-DD"
          value={value}
          open={open}
          onChange={onChange}
          maxDate={maxDate && maxDate}
          // onKeyDown={onKeyDown}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                // onKeyDown={onKeyDown}
              />
              <Box sx={{ mx: 0.5 }}> to </Box>
              <TextField
                {...endProps}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                // onKeyDown={onKeyDown}
              />
            </React.Fragment>
          )}
        />*/}
      </LocalizationProvider>
    );
  }
);

export default DatePicker;
