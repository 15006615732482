import { Box, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, FastField, Field, Form, Formik, getIn } from "formik";
import { connect } from "react-redux";
import TrackAction from "../../stores/track/TrackAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { theme } from "../App";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiDateField from "../../components/dateField/DateField";
import MuiCalendar from "../../components/calendar/MuiCalendar";
import BreadCrumbs from "./BreadCrumbs";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useLocation, useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import UploadPopper from "../../components/poppers/UploadPopper";
import CloseIcon from "@mui/icons-material/Close";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
  ]),
  track: state.track.track.hits || [],
  releaseById: state.releaseById.releaseById || {},
});

const Releasedate = (props) => {
  const [loading, setLoading] = useState(false);
  const [submissionRedirect, setSubmissionRedirect] = useState(false);
  const navigate = useNavigate();
  const boxRef = useRef(null);

  const { formProps } = props;

  let initial = {
    main_release_date: null,
    pre_order_release_date: null,
  };

  function fetchResource(resourceId) {
    let params = {
      current_page: "releaseDate",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  if (props.releaseById && props.releaseById._source) {
    Object.assign(
      initial,
      props.releaseById &&
        props.releaseById._source &&
        props.releaseById._source.main_release_date
        ? {
            main_release_date: props.releaseById._source.main_release_date,
          }
        : {
            main_release_date: null,
          },

      props.releaseById &&
        props.releaseById._source &&
        props.releaseById._source.pre_order_release_date
        ? {
            pre_order_release_date:
              props.releaseById._source.pre_order_release_date,
          }
        : {
            pre_order_release_date: null,
          }
    );
  }

  let id = localStorage.getItem("id");

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };
    // fetchGenreFilter(param);
    // fetchLabelFilter(param);
    // fetchSubgenreFilter(param);
    // fetchartistFilter(param);
    // fetchPlatformsFilter(param);
    fetchResource(id);
    // fetchStatusFilter(param);
    // handleChange(null, "1");
    // fetchlanguageFilter(param);
  }, [id]);

  const { _id, _source } = props?.releaseById && props?.releaseById;

  function Required() {
    return (
      <>
        <span style={{ color: theme.palette.secondary.main }}>Required</span>{" "}
        <span style={{ color: theme.palette.secondary.main }}>*</span>
      </>
    );
  }

  function Required2() {
    return (
      <>
        <span style={{ color: theme.palette.secondary.main }}>
          pre-order date should be less than main release Date
        </span>{" "}
        <span style={{ color: theme.palette.secondary.main }}>*</span>
      </>
    );
  }

  const location = useLocation();
  const formRef = useRef(null);
  const dateString = _source?.main_release_date;
  const targetDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const isReleaseBeforeToday = targetDate < yesterday;

  const handleSubmit = () => {
    if (!isReleaseBeforeToday || !dateString) {
      if (formRef?.current) {
        formRef?.current?.submitForm();
      }
    }
  };

  useEffect(() => {
    const shouldSubmit = location.state?.shouldSubmit;
    if (shouldSubmit) {
      setSubmissionRedirect(true);
      handleSubmit();
    }
  }, [!props.isRequesting && location.state]);

  const updateShouldSubmitInState = (newValue) => {
    // Check if the History API is available
    if (window.history && window.history.replaceState) {
      // Get the current state from the history object
      const currentState = window.history.state || {};

      // Create a new state object with the updated 'shouldSubmit' value
      const newState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          shouldSubmit: newValue,
        },
      };

      // Replace the current state with the updated state
      window.history.replaceState(newState, "");

      // Optional: You can also update the 'location.state' directly if needed
      location.state = newState;
    }
  };

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Box
      // sx={{ paddingX: 10 }}
      >
        <Formik
          initialValues={initial}
          validate={(values) => {
            var errors = {};

            if (!values.main_release_date) {
              errors.main_release_date = <Required />;
            }

            // if (!values.pre_order_release_date) {
            //   errors.pre_order_release_date = <Required />;
            // }

            // if (
            //   new Date(values.pre_order_release_date) >
            //   new Date(values.main_release_date)
            // ) {
            //   errors.pre_order_release_date = <Required2 />;
            // }

            return location.state?.shouldSubmit && errors;
          }}
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange
          onSubmit={(values) => {
            setLoading(true);

            let obj = {};
            Object.assign(
              obj,

              values.main_release_date && {
                main_release_date: values.main_release_date,
              },
              { pre_order_release_date: values.pre_order_release_date },
              _source.physical_release_date && {
                physical_release_date: _source.physical_release_date,
              }
            );

            let params = {
              current_page: "releaseDate",
            };
            if (localStorage.getItem("id")) {
              props
                .dispatch(
                  ReleasesAction.requestPutRelease(
                    obj,
                    localStorage.getItem("id"),
                    params
                  )
                )
                .then(() => {
                  setLoading(false);
                  updateShouldSubmitInState(false);
                  props.dispatch(
                    ReleasesAction.requestReleaseById(
                      localStorage.getItem("id"),
                      params
                    )
                  );
                  if (submissionRedirect) navigate("/submission");
                  else navigate("/promotion");
                });
            }
          }}
          innerRef={formRef}
        >
          {({
            errors,
            isValid,
            helperText,
            touched,
            dirty,
            handleChange,
            setFieldValue,
            values,
            name,
            options,
            placeholder,
          }) => (
            <Form style={{ width: "100%" }}>
              <>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  sm={12}
                  md={12}
                  alignItems="center"
                  justifyContent={"space-between"}
                  style={{
                    position: "sticky",
                    top: `${boxRef?.current?.offsetTop}px`,
                    backgroundColor: theme.palette.background.default,
                    zIndex: 10,
                  }}
                >
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    container
                    alignItems={"center"}
                    justifyContent="flex-start"
                    gap={2}
                    paddingTop={1}
                  >
                    {/* <Grid item>
                          <IconButton>
                            <ArrowBackIcon
                              title="Back"
                              sx={{
                                color: "#2a5e6c",
                                height: "25px",
                                width: "25px",
                              }}
                              onClick={(event) => {
                                event.ignore = true;
                                navigate("/releases");
                                localStorage.removeItem("id");
                              }}
                            />
                          </IconButton>
                        </Grid> */}

                    <Grid item>
                      <h2>{formatName(`${_source?.title}`)}</h2>
                    </Grid>

                    <Grid item>
                      <BreadCrumbs
                        pathname={window.location.pathname}
                        releaseType={_source?.type_of_release}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    md={12}
                    xl={12}
                    className="d-flex justify-content-end"
                    sx={{
                      paddingTop: "10px",
                      paddingX: {
                        lg: 20,
                        md: 1,
                        sm: 1,
                      },
                    }}
                  >
                    <Grid item lg={1} md={2} sm={2}>
                      <MuiButton
                        disabled={
                          _source
                            ? _source.status &&
                              ["1", "4", "10", "9"].includes(_source.status.id)
                              ? false
                              : true
                            : false
                        }
                        loading={loading}
                        type="submit"
                        name="Save"
                        width="100%"
                        save
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  lg={12}
                  sm={12}
                  md={12}
                  xl={12}
                  columnSpacing={5}
                >
                  <Grid item lg={6} xl={6} md={12} sm={12}>
                    <Grid item lg={12} md={12} sm={12} container gap={1}>
                      <Typography sx={{ fontSize: "16px" }}>
                        <UploadPopper
                          format={
                            " The release date when the product becomes available on music services for public access."
                          }
                        >
                          <InfoIcon />
                          <Typography sx={{ fontSize: "16px" }}>
                            Choose a main release Date *
                          </Typography>
                        </UploadPopper>{" "}
                      </Typography>
                      <Grid item lg={12} md={12} sm={12} gap={1}>
                        <Field
                          disabled={
                            _source
                              ? _source.status &&
                                ["1", "4", "10", "9"].includes(
                                  _source.status.id
                                )
                                ? false
                                : true
                              : false
                          }
                          component={MuiDateField}
                          size={"small"}
                          // disablePast
                          value={
                            values.main_release_date
                              ? values.main_release_date
                              : null
                          }
                          dateformat="DD/MM/YYYY"
                          name={`main_release_date`}
                          fullWidth
                          textFieldProps={{
                            label: false,
                            margin: "normal",
                            variant: "outlined",
                            fullWidth: true,
                          }}
                        />
                        <ErrorMessage name="main_release_date" />
                      </Grid>

                      <Grid
                        item
                        className="d-flex justify-content-center align-items-center"
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                          width: "100%",
                          padding: "30px",
                          boxShadow: theme.shadows,
                        }}
                      >
                        <Field
                          disabled={
                            _source
                              ? _source.status &&
                                ["1", "4", "10", "9"].includes(
                                  _source.status.id
                                )
                                ? false
                                : true
                              : false
                          }
                          component={MuiCalendar}
                          size={"small"}
                          value={
                            values.main_release_date
                              ? values.main_release_date
                              : null
                          }
                          name={`main_release_date`}
                          label={false}
                          textFieldProps={{
                            fullWidth: true,
                            margin: "normal",
                            variant: "outlined",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xl={6} md={12} sm={12}>
                    <Grid item lg={12} md={12} sm={12} container gap={1}>
                      <Typography sx={{ fontSize: "16px" }}>
                        Add a pre-order date
                      </Typography>
                      <Grid item lg={12} md={12} sm={12} gap={1}>
                        <Tooltip
                          title="Add a pre-order date if required"
                          arrow
                          placement="top"
                        >
                          <Field
                            disabled={
                              _source
                                ? _source.status &&
                                  ["1", "4", "10", "9"].includes(
                                    _source.status.id
                                  )
                                  ? false
                                  : true
                                : false
                            }
                            component={MuiDateField}
                            dateformat="DD/MM/YYYY"
                            maxDate={values ? values.main_release_date : null}
                            size={"small"}
                            disablePast
                            value={
                              values.pre_order_release_date
                                ? values.pre_order_release_date
                                : null
                            }
                            onClose
                            name={`pre_order_release_date`}
                            fullWidth
                            textFieldProps={{
                              label: false,
                              margin: "normal",
                              variant: "outlined",
                              fullWidth: true,
                            }}
                            InputProps={{
                              endAdornment: values.pre_order_release_date && (
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(
                                      "pre_order_release_date",
                                      null
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </Tooltip>
                        <ErrorMessage name="pre_order_release_date" />
                      </Grid>

                      <Grid
                        item
                        className="d-flex justify-content-center align-items-center"
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                          width: "100%",
                          padding: "30px",
                          boxShadow: theme.shadows,
                        }}
                      >
                        <Field
                          disabled={
                            _source
                              ? _source.status &&
                                ["1", "4", "10", "9"].includes(
                                  _source.status.id
                                )
                                ? false
                                : true
                              : false
                          }
                          component={MuiCalendar}
                          size={"small"}
                          value={
                            values.pre_order_release_date
                              ? values.pre_order_release_date
                              : null
                          }
                          name={`pre_order_release_date`}
                          label={false}
                          textFieldProps={{
                            fullWidth: true,
                            margin: "normal",
                            variant: "outlined",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(Releasedate);
