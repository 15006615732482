import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, FastField, Field, Form, Formik, getIn } from "formik";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { theme } from "../App";
import MuiButton from "../../components/muiButton/MuiButton";
import BreadCrumbs from "./BreadCrumbs";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useLocation, useNavigate } from "react-router";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
  ]),
  track: state.track.track.hits || [],
  releaseById: state.releaseById.releaseById || {},
});

function Promotion(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const boxRef = useRef(null);

  let id = localStorage.getItem("id");

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: `promotion`,
    };

    fetchRelease(id, param);
  }, [id]);

  let initial = {
    description: null,
    link: null,
  };

  function fetchRelease(resourceId, params) {
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  const { _id, _source } = props?.releaseById && props?.releaseById;

  if (props.releaseById && props.releaseById._source) {
    Object.assign(
      initial,
      props.releaseById &&
        props.releaseById._source &&
        props.releaseById._source.release_promotion?.description
        ? {
            description:
              props.releaseById._source.release_promotion.description,
          }
        : {
            description: null,
          },

      props.releaseById &&
        props.releaseById._source &&
        props.releaseById._source.release_promotion?.link
        ? {
            link: props.releaseById._source.release_promotion.link,
          }
        : {
            link: null,
          }
    );
  }

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Box
      // sx={{ paddingTop: 10, paddingX: 10 }}
      >
        <Formik
          initialValues={initial}
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange
          onSubmit={(values) => {
            setLoading(true);

            let release_promotion = {};

            Object.assign(
              release_promotion,

              values.description && {
                description: values.description,
              },
              values.link && {
                link: values.link,
              }
            );

            let obj = {
              release_promotion,
            };

            let params = { current_page: "promotion" };

            if (localStorage.getItem("id")) {
              props
                .dispatch(
                  ReleasesAction.requestPutRelease(
                    obj,
                    localStorage.getItem("id"),
                    params
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.dispatch(
                    ReleasesAction.requestReleaseById(
                      localStorage.getItem("id"),
                      params
                    )
                  );
                  navigate("/submission");
                });
            }
          }}
          innerRef={formRef}
        >
          {({
            errors,
            isValid,
            helperText,
            touched,
            dirty,
            handleChange,
            setFieldValue,
            values,
            name,
            options,
            placeholder,
          }) => (
            <Form style={{ width: "100%" }}>
              <>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  sm={12}
                  md={12}
                  alignItems="center"
                  justifyContent={"space-between"}
                  style={{
                    position: "sticky",
                    top: `${boxRef?.current?.offsetTop}px`,
                    backgroundColor: theme.palette.background.default,
                    zIndex: 10,
                  }}
                >
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    container
                    alignItems={"center"}
                    justifyContent="flex-start"
                    gap={2}
                    paddingTop={1}
                  >
                    <Grid item>
                      <h2>{formatName(`${_source?.title}`)}</h2>
                    </Grid>

                    <Grid item>
                      <BreadCrumbs
                        pathname={window.location.pathname}
                        releaseType={_source?.type_of_release}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    lg={12}
                    sm={12}
                    md={12}
                    xl={12}
                    className="d-flex justify-content-end"
                    sx={{
                      paddingTop: "10px",
                      paddingX: {
                        lg: 20,
                        md: 1,
                        sm: 1,
                      },
                    }}
                  >
                    <Grid item lg={1} md={2} sm={2}>
                      <MuiButton
                        disabled={
                          _source
                            ? _source.status &&
                              ["1", "4", "10", "9"].includes(_source.status.id)
                              ? false
                              : true
                            : false
                        }
                        loading={loading}
                        type="submit"
                        name="Save"
                        width="100%"
                        save
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container lg={12} sm={12} md={12} xl={12}>
                  <Grid item lg={12} sm={12} md={12} xl={12}>
                    <h3>{"Description"}</h3>
                    <Typography>
                      Brief description about song / Album / Artist and other
                      information related to this release
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    lg={7}
                    md={10}
                    sm={12}
                    className="labelFieldBox"
                    spacing={2}
                  >
                    <Grid item lg={12} md={12} sm={12}>
                      <FastField
                        className={
                          touched.title && errors.title
                            ? "ticket_desc_test_area_error scrollable"
                            : "ticket_desc_test_area scrollable"
                        }
                        name="description"
                        component="textarea"
                        value={values.description}
                        onChange={handleChange}
                        type="text"
                        maxRows={4}
                        minRows={4}
                        style={{
                          width: "100%",
                          height: 200,
                          padding: "10px",
                          boxSizing: "border-box",
                          color: `${theme.palette.text.primary}`,
                        }}
                        aria-label="maximum height"
                        placeholder="Type here..."
                        label="description"
                        error={
                          Boolean(errors.description) &&
                          Boolean(touched.description)
                        }
                        helperText={
                          Boolean(touched.description) && errors.description
                        }
                      />
                      <Box px={1}>
                        <ErrorMessage name="description" />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} mt={1}>
                      <FastField name="link" type="text" varient="outlined">
                        {({ field, form, meta }) => (
                          <MuiTextField
                            name="link"
                            size="small"
                            width="100%"
                            value={values.link}
                            onChange={handleChange}
                            placeholder="Paste the link here"
                            error={
                              Boolean(errors.link) && Boolean(touched.link)
                            }
                            helperText={Boolean(touched.link) && errors.link}
                          />
                        )}
                      </FastField>
                    </Grid>
                  </Grid>

                  <Grid item container lg={7} sm={12} md={10} mt={1}>
                    High resolution Artist image, Art work design PSD file and
                    others to be uploaded on google drive and paste the link in
                    the above box
                  </Grid>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps)(Promotion);
