import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import NetRevenueByMonthAction from "../../stores/netRevenueReportByMonth/NetRevenueByMonthAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import MonthwiseRevenueTabView from "./MonthwiseRevenueTabView";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { theme } from "../App";
import NetRevenueByMonthChart from "./NetRevenueByMonthChart";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import { format, toDate } from "date-fns";
import MuiMonthPicker from "../../components/datePicker/MuiMonthPicker";
import dayjs from "dayjs";
import Zoom from "@mui/material/Zoom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import MuiButton from "../../components/muiButton/MuiButton";
import UserAction from "../../stores/user/UserAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH,
    UserAction.REQUEST_USER_BY_ID,
  ]),
  netRevenueByMonth: state.netRevenueByMonth.netRevenueByMonth || [],
  userprofile: state.userprofile.userprofile || [],
});

function NetRevenueByMonth(props) {
  const boxRef = useRef(null);
  const { isRequesting } = props;

  const [retailers, setRetailers] = useState("all");
  const [timeRange, setTimeRange] = useState("Past 6 months");
  const [reportViewType, setReportViewType] = useState("chart");
  const [monthSelected, setMonthSelected] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [openMonthRangePicker, setOpenMonthRangePicker] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [fromMonth, setFromMonth] = useState(null);
  const [toMonth, setToMonth] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [customApplyClicked, setCustomApplyClicked] = useState(false);

  // Helper function to calculate the from date based on the given number of months
  const calculateFromDate = (months) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - months);
    currentDate.setDate(1); // Set the day to the 1st
    return currentDate.toISOString().split("T")[0];
  };

  // Helper function to calculate the to date based on the from date and number of months
  const calculateToDate = (fromDate, months) => {
    const toDate = new Date(fromDate);
    toDate.setMonth(toDate.getMonth() + months);
    toDate.setDate(0); // Set the day to the last day of the previous month
    return toDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (
      (isDatePickerOpen && !customApplyClicked) ||
      (customApplyClicked && !fromMonth && !toMonth)
    ) {
      return;
    }

    if (customApplyClicked && new Date(fromMonth.$d) > new Date(toMonth.$d)) {
      setCustomApplyClicked(false);
      setAlertPopUp(true);
      return;
    }

    const today = new Date();
    let fromDate;
    let toDate;

    let params;

    switch (timeRange) {
      case "Past 3 months":
        fromDate = calculateFromDate(2);
        toDate = calculateToDate(fromDate, 3);
        setOpenMonthRangePicker(false);
        break;

      case "Past 6 months":
        fromDate = calculateFromDate(5);
        toDate = calculateToDate(fromDate, 6);
        setOpenMonthRangePicker(false);
        break;

      case "Past 12 months":
        fromDate = calculateFromDate(11);
        toDate = calculateToDate(fromDate, 12);
        setOpenMonthRangePicker(false);
        break;

      default:
        fromDate = "2020-01-01";
        toDate = format(today, "yyyy-MM-dd");
        break;
    }

    if (timeRange === "Custom") {
      let toDateValue = new Date(toMonth);
      let toYearValue = toDateValue.getFullYear();
      let toMonthvalue = toDateValue.getMonth() + 1;

      setOpenMonthRangePicker(true);
      fromDate =
        fromMonth && format(new Date(fromMonth).setDate(1), "yyyy-MM-dd");
      toDate =
        toMonth &&
        `${toYearValue}-${toMonthvalue}-${new Date(
          toYearValue,
          toMonthvalue,
          0
        ).getDate()}`;

      if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
        return setAlertPopUp(true);
      }
      setCustomApplyClicked(false);
    }

    params = {
      from: format(new Date(fromDate), "yyyy-MM-dd"),
      to: format(new Date(toDate), "yyyy-MM-dd"),
      source: retailers,
      current_page: "monthwise_revenue",
    };

    fetchNetRevenueByMonth(params);
  }, [timeRange, retailers, customApplyClicked]);

  function fetchNetRevenueByMonth(params) {
    props
      .dispatch(NetRevenueByMonthAction.requestNetRevenueByMonth(params))
      .then(() => {
        setFilterLoader(false);
      });
  }

  function keyBeautification(key) {
    const words = key.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  let clientList = [];
  props?.clientFilter?.hits?.hits?.map((item) => {
    if (item?._source?.client_name)
      clientList.push({
        id: item?._id,
        client_name: item._source.client_name,
      });
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "key",
      headerName: `Month`,
      flex: 3,
      renderCell: (params) => {
        return <span>{returnMonthName(params.row.key)}</span>;
      },
    },
    {
      field: "count",
      headerName: "Total Revenue ($)",
      flex: 3,
    },
  ];

  const rows = [];
  if (props.netRevenueByMonth["Net Revenue By Month"]) {
    props.netRevenueByMonth["Net Revenue By Month"]?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        count: item.total_revenue,
        key: item.key,
        summary: item.summary,
      });
    });
  }

  function returnMonthName(monthValue) {
    // expected the month value be like 2023-09
    const dateString = monthValue;
    const [year, month] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-01`).toLocaleDateString(
      "en-US",
      { year: "numeric", month: "long" }
    );

    return formattedDate;
  }

  function fromMonthHandleChange(fromMonth) {
    setFromMonth(fromMonth);
  }
  function toMonthHandleChange(toMonth) {
    setToMonth(toMonth);
  }

  function customDateFormatter(dateObj) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[dateObj.$M]} ${dateObj.$y}`;
  }

  useEffect(() => {
    if (
      customApplyClicked &&
      fromMonth &&
      toMonth &&
      new Date(fromMonth.$d) < new Date(toMonth.$d)
    ) {
      setTimeRange("Custom");
    }
  }, [isDatePickerOpen]);

  const clearFunction = () => {
    if (retailers == "all" && timeRange == "Past 6 months") {
      setAlertPopUp(true);
      setClearLoader(false);
      return;
    }
    setDatePickerOpen(false);
    setFilterLoader(false);
    setClearLoader(false);
    setRetailers("all");
    setTimeRange("Past 6 months");
    setFromMonth(null);
    setToMonth(null);
  };

  return (
    <Box
      ref={boxRef}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      {navigator.onLine ? (
        <>
          {props.userprofile?._source?.owned_labels?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "20px",
              }}
            >
              <h1>Owned Label Missing</h1>
              <h2>
                The Owned Label is not specified. Please contact your client{" "}
                {props.userprofile?._source?.created_by?.user_name} to access
                your revenue data.
              </h2>
            </Box>
          ) : (
            <>
              {!monthSelected && (
                <Box>
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    justifyContent="flex-start"
                    style={{
                      position: "sticky",
                      top: `${boxRef?.current?.offsetTop}px`,
                      backgroundColor: theme.palette.background.default,
                      zIndex: 10,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      px={2}
                      py={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        {["chart", "grid"].map((item) => (
                          <IconButton
                            sx={{
                              marginX: 1,
                              backgroundColor:
                                item === reportViewType
                                  ? theme.palette.background.paper
                                  : "none",
                              borderRadius: item === reportViewType ? 2 : 0,
                              padding: item === reportViewType ? 1 : 0,
                              boxShadow:
                                item === reportViewType
                                  ? theme.shadows
                                  : "none",
                              borderBottom:
                                item === reportViewType
                                  ? `2px solid ${theme.palette.primary.main}`
                                  : "none",
                            }}
                            onClick={() => {
                              setReportViewType(item);
                            }}
                          >
                            {item === "chart" ? (
                              <Typography
                                sx={{ color: theme.palette.primary.main }}
                              >
                                Chart View
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ color: theme.palette.primary.main }}
                              >
                                Table View
                              </Typography>
                            )}
                          </IconButton>
                        ))}
                      </Grid>

                      <Grid
                        item
                        sx={{ display: "flex", alignItems: "center" }}
                        columnGap={1}
                      >
                        <Grid>
                          <AutoCompleteComponent
                            disableClearable={true}
                            value={timeRange}
                            list={[
                              "Past 3 months",
                              "Past 6 months",
                              "Past 12 months",
                              "Custom",
                            ]}
                            textFieldProps={{
                              placeholder: "Select time range",
                            }}
                            handleChange={(e, v) => {
                              if (v == "Custom") setDatePickerOpen(true);
                              else {
                                setTimeRange(v);
                                setFromMonth(null);
                                setToMonth(null);
                              }
                            }}
                            size="small"
                            width={200}
                          />
                        </Grid>

                        {timeRange == "Custom" && fromMonth && toMonth && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "white",
                              height: "37px",
                              borderRadius: "8px",
                              paddingX: "15px",
                              border: `1px solid ${theme.palette.text.gray}`,
                            }}
                          >
                            {`${customDateFormatter(
                              fromMonth
                            )} - ${customDateFormatter(toMonth)}`}
                            <Tooltip
                              title="Edit Calendar"
                              arrow
                              placement="bottom"
                            >
                              <EditCalendarTwoToneIcon
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setDatePickerOpen(true)}
                              />
                            </Tooltip>
                          </Box>
                        )}

                        <Grid>
                          <AutoCompleteComponent
                            disableClearable={true}
                            value={retailers}
                            list={["all", "music", "youtube"]}
                            getOptionLabel={(option) =>
                              keyBeautification(option)
                            }
                            textFieldProps={{
                              placeholder: "Select Retailers",
                            }}
                            handleChange={(e, v) => {
                              setRetailers(v);
                            }}
                            size="small"
                            width={200}
                          />
                        </Grid>
                        <Grid
                          sx={{
                            transition: "transform 0.3s ease",
                            "&:hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          <MuiButton
                            width="100%"
                            loading={clearLoader}
                            height={"38px"}
                            noTextOnLoading
                            cancel
                            name={"Clear"}
                            onClick={() => {
                              setClearLoader(true);
                              clearFunction();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {reportViewType === "grid" ? (
                    <Grid
                      display="flex"
                      justifyContent="center"
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                    >
                      <Grid
                        sx={{
                          fontSize: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        gap={1}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {formatName("Monthwise Revenue")}
                        </span>
                      </Grid>

                      <Grid item xs={12} mx={3}>
                        <LoadingIndicator isActive={isRequesting}>
                          {!isRequesting &&
                          props.netRevenueByMonth["Net Revenue By Month"] ? (
                            <DataGridFunction
                              curser="pointer"
                              columns={columns}
                              rows={rows}
                              rowHeight={60}
                              height={"74vh"}
                              onRowClick={(e) => {
                                props
                                  .dispatch(
                                    NetRevenueByMonthAction.requestNetRevenueByMonthFilterClear()
                                  )
                                  .then(() => {
                                    setMonthSelected(e?.row?.key);
                                  });
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "70vh",
                              }}
                            >
                              <LoadingBgImage />
                            </Box>
                          )}
                        </LoadingIndicator>
                      </Grid>
                    </Grid>
                  ) : (
                    <NetRevenueByMonthChart
                      retailers={retailers}
                      timeRange={timeRange}
                      setMonthSelected={setMonthSelected}
                      monthSelected={monthSelected}
                    />
                  )}
                </Box>
              )}

              {monthSelected && (
                <Box>
                  <MonthwiseRevenueTabView
                    setMonthSelected={setMonthSelected}
                    monthSelected={monthSelected}
                    retailers={retailers}
                    setRetailers={setRetailers}
                    clearFunction={clearFunction}
                  />
                </Box>
              )}
              <Dialog
                open={isDatePickerOpen}
                onClose={() => {
                  setDatePickerOpen(false);
                }}
                TransitionComponent={Zoom}
                transitionDuration={400}
                fullWidth
              >
                <DialogTitle sx={{ paddingBottom: "25px" }}>
                  Select a Month Range{" "}
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 10,
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      setDatePickerOpen(false);
                    }}
                  >
                    <HighlightOffIcon
                      sx={{
                        "&:hover": {
                          transition: "transform 0.5s, color 0.2s",
                          transform: "scale(1.2)",
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <div style={{ width: "48%", float: "left" }}>
                    <MuiMonthPicker
                      value={fromMonth}
                      onChange={fromMonthHandleChange}
                      disableFuture
                    />
                  </div>
                  <div style={{ width: "48%", float: "right" }}>
                    <MuiMonthPicker
                      value={toMonth}
                      onChange={toMonthHandleChange}
                      disableFuture
                    />
                  </div>
                </DialogContent>
                <DialogActions style={{ padding: "16px", marginTop: "15px" }}>
                  <MuiButton
                    width="40%"
                    name={"Apply"}
                    onClick={() => {
                      setCustomApplyClicked(true);
                      setDatePickerOpen(false);
                    }}
                  />
                  <MuiButton
                    cancel
                    width="40%"
                    name={"Clear"}
                    onClick={() => {
                      clearFunction();
                    }}
                  />
                </DialogActions>
              </Dialog>
            </>
          )}
        </>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Date Range should be proper."}
      />
    </Box>
  );
}

export default connect(mapStateToProps)(NetRevenueByMonth);
