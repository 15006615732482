import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  createFilterOptions,
  Box,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  Form,
  Formik,
  Field,
  getIn,
  FastField,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
} from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import { theme } from "../App";
import DeleteIcon from "@mui/icons-material/Delete";
import TrackAction from "../../stores/track/TrackAction";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import {
  HighlightOff,
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import environment from "environment";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import ArtistAction from "../../stores/artist/ArtistAction";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import AutocompleteDialoge from "../../components/autoCompleteComponent/AutocompleteDialoge";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import WarningIcon from "@mui/icons-material/Warning";
import { Close } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const useStyles = makeStyles(() => ({
  scrollbar: {
    overflow: "auto",
    overflowX: "hidden",
    borderRadius: "6px",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b0d2da",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#85bac6",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));

const TrackPost = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [actorInput, setActorInput] = useState("");

  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }
  };

  let { releasedata } = props;

  let editProps = props.projectEditData && props.projectEditData;

  // Project post and Put method
  let initial = {};

  let yesNoArray = ["Yes", "No", "Cleaned"];
  let InstrumentalArray = ["Yes", "No"];
  let AskToGenerateIsrc = ["Yes", "No"];

  let contentTypeArray = ["Audio", "Video", "Apple Video", "Dolby Audio"];
  // Based on the type of release in the release index, the Content type array is assigned
  props?.releasedata?.type_of_release === "audio"
    ? (contentTypeArray = ["Audio", "Dolby Audio"])
    : (contentTypeArray = ["Video", "Apple Video"]);
  let primaryTrackTypeeArray = ["Audio", "Video"];
  let secondaryTrackTypes = ["Original", "Karaoke", "Cover", "Medley"];
  let IPRSandPPL = ["IPRS", "PPL"];

  // Inside your component
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const startYear = 0;
  const yearArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  let featuring = [];

  editProps?.featuring?.map((item) => {
    featuring.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let featuring_initial = [];

  releasedata?.featuring_artists?.map((item) => {
    featuring_initial.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let releaseInfoPrimaryArtist = [];

  props?.primary_artist?.map((item) => {
    releaseInfoPrimaryArtist.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let releaseInfoFeaturingArtist = [];

  props?.featuring_artists?.map((item) => {
    releaseInfoFeaturingArtist.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
    });
  });

  let remixer = [];

  editProps?.remixer?.map((item) => {
    remixer.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
    });
  });

  let primary_actor = [];
  editProps?.primary_actor?.map((item) => {
    primary_actor.push({
      actor_id: item?.actor_id?.id,
      actor_name: item?.actor_name,
    });
  });

  let primary_actress = [];
  editProps?.primary_actress?.map((item) => {
    primary_actress.push({
      actress_id: item?.actress_id?.id,
      actress_name: item?.actress_name,
    });
  });

  let director = [];
  editProps?.director?.map((item) => {
    director.push({
      director_id: item?.director_id?.id,
      director_name: item?.director_name,
    });
  });
  let primary_artists = [];

  editProps?.primary_artists?.map((item) => {
    primary_artists.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let primary_artists_initial = [];

  releasedata?.artists?.map((item) => {
    primary_artists_initial.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let composer = [];
  props?.artistArray?.map((item) => {
    composer.push(item?.artist_name);
  });

  let languages_list = [];
  props?.nameslist?.map((item) => {
    languages_list.push(item);
  });

  function capitalizeFirstLetter(str) {
    // Split the string into words
    var words = str.split("_");

    // Capitalize the first letter of each word
    for (var i = 0; i < words.length; i++) {
      words[i] =
        words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
    }

    // Join the words back into a single string
    var capitalizedString = words.join(" ");

    return capitalizedString;
  }

  const updateTrackErrorInState = (newValue) => {
    // Check if the History API is available
    if (window.history && window.history.replaceState) {
      // Get the current state from the history object
      const currentState = window.history.state || {};

      // Create a new state object with the updated 'shouldSubmit' value
      const newState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          trackError: newValue,
        },
      };

      // Replace the current state with the updated state
      window.history.replaceState(newState, "");

      // Optional: You can also update the 'location.state' directly if needed
      location.state = newState;
    }
  };

  let genre_initial = [];

  releasedata?.genre_type &&
    genre_initial.push({
      id: releasedata?.genre_type.id,
      name: releasedata?.genre_type.genre_name,
    });

  let sub_genre_initial = [];

  releasedata?.sub_genre_type &&
    sub_genre_initial.push({
      id: releasedata?.sub_genre_type.id,
      name: releasedata?.sub_genre_type.genre_name,
    });

  if (props.edit) {
    if (props.isTrackError) {
      handleSubmit();
    }
    let publishers = [];
    if (
      Array.isArray(editProps?.publisher) &&
      editProps?.publisher?.length > 0
    ) {
      editProps.publisher.map((item) => {
        let array = item.split("-");
        const lastElement = array[array.length - 1];
        const combinedElements = array.slice(0, -1).join("-");

        publishers.push({
          type: lastElement,
          name: combinedElements,
        });
      });
    } else {
      publishers.push({
        type: "IPRS",
        name: "",
      });
    }

    initial = {
      title: editProps.title,
      arranger:
        editProps.arranger && editProps.arranger.length > 0
          ? editProps.arranger
          : [""],
      ask_to_generate_an_isrc: editProps.ask_to_generate_an_isrc ? "Yes" : "No",
      author:
        editProps.author && editProps.author.length > 0
          ? editProps.author
          : [""],
      client_id: editProps.client_id,
      composer:
        editProps.composer && editProps.composer.length > 0
          ? editProps.composer
          : [""],

      content_type: editProps.content_type
        ? capitalizeFirstLetter(editProps.content_type)
        : "",
      featuring: featuring?.length !== 0 ? featuring : [""],
      genre: editProps.genre?.id ?? "",
      instrumental: editProps.instrumental ? "Yes" : "No",
      isrc: editProps.isrc,
      audio_isrc: editProps.audio_isrc,
      lyrics: editProps.lyrics,
      lyrics_language: editProps.lyrics_language
        ? {
            id: editProps.lyrics_language?.lyrics_language,
            name: editProps?.lyrics_language?.lyrics_language_name,
          }
        : null,
      parental_advisory: editProps.parental_advisory
        ? capitalizeFirstLetter(editProps.parental_advisory)
        : "",
      plan_id: editProps.plan_id?.id ?? "",
      preview_start: editProps.preview_start ?? "",
      primary_artists: primary_artists.length !== 0 ? primary_artists : [""],
      primary_track_type: editProps.primary_track_type
        ? capitalizeFirstLetter(editProps.primary_track_type)
        : releasedata?.type_of_release
        ? capitalizeFirstLetter(releasedata.type_of_release)
        : "",
      producer:
        editProps.producer && editProps.producer.length > 0
          ? editProps.producer
          : [""],
      producer_catalouge_number: editProps.producer_catalouge_number,
      production_year: editProps.production_year ?? "",
      published_by: editProps.published_by,
      // publisher: !editProps.publisher
      //   ? ["", "IPRS"]
      //   : editProps.publisher?.split("-"),
      publishers: publishers,
      remixer: remixer,
      primary_actor: primary_actor,
      primary_actress: primary_actress,
      director: director,
      secondary_genre: editProps.secondary_genre,
      secondary_track_type: editProps.secondary_track_type
        ? capitalizeFirstLetter(editProps.secondary_track_type)
        : "",
      seconday_sub_genre: editProps.seconday_sub_genre,
      release_id: props.release_id,
      stream_id: editProps.stream_id,
      sub_genre_name: editProps.sub_genre_name?.id ?? "",
      track_title_language: editProps.track_title_language
        ? {
            id: editProps.track_title_language?.track_title_language,
            name: editProps?.track_title_language?.track_title_language_name,
          }
        : null,
      version_subtitle: editProps.version_subtitle ?? [""],
      views: editProps.views ?? "",
      phonographic_copyright_year: editProps.phonographic_copyright_year ?? "",
      copyright_year: editProps.copyright_year ?? "",
      copyrighted_to: editProps.copyrighted_to,
      track_number: editProps.track_number,
    };
  } else {
    initial = {
      title: "",
      arranger: [""],
      ask_to_generate_an_isrc: "Yes",
      author: [""],
      client_id: "",
      composer: [""],
      content_type: "",
      featuring:
        releasedata && releasedata.featuring_artists.length !== 0
          ? featuring_initial && featuring_initial.length !== 0
            ? featuring_initial
            : [""]
          : [""],
      genre: releasedata && releasedata.genre_type ? genre_initial[0].id : "",
      instrumental: "No",
      isrc: "",
      audio_isrc: "",
      lyrics: "",
      lyrics_language:
        releasedata && releasedata.meta_language
          ? {
              id: releasedata.meta_language.meta_language,
              name: releasedata.meta_language.meta_language_name,
            }
          : null,
      parental_advisory: "No",
      plan_id:
        releasedata && releasedata.plan_id
          ? releasedata.plan_id.id
            ? releasedata.plan_id.id
            : ""
          : "",
      preview_start: "",
      primary_artists:
        releasedata && releasedata.artists.length !== 0
          ? primary_artists_initial && primary_artists_initial.length !== 0
            ? primary_artists_initial
            : [""]
          : [""],
      primary_track_type: releasedata?.type_of_release
        ? capitalizeFirstLetter(releasedata.type_of_release)
        : "",
      producer: [""],
      producer_catalouge_number:
        releasedata && releasedata.producer_catalogue_number
          ? releasedata.producer_catalogue_number
          : "",
      production_year:
        releasedata && releasedata.production_year
          ? releasedata.production_year
          : "",
      published_by:
        releasedata && releasedata.published_by ? releasedata.published_by : "",

      // publisher: ["", "IPRS"],
      publishers: [
        {
          type: "IPRS",
          name: "",
        },
      ],

      remixer: [],
      primary_actor: [],
      primary_actress: [],
      director: [],
      release_id: props.release_id,
      secondary_genre: "",
      secondary_track_type: "",
      seconday_sub_genre: "",
      stream_id: "",
      sub_genre_name:
        releasedata && releasedata.sub_genre_type
          ? sub_genre_initial[0].id
          : "",
      track_title_language:
        releasedata && releasedata.meta_language
          ? {
              id: releasedata.meta_language.meta_language,
              name: releasedata.meta_language.meta_language_name,
            }
          : null,
      version_subtitle:
        releasedata && releasedata["music/album"]
          ? releasedata["music/album"]
          : [""],
      views: "",
      phonographic_copyright_year:
        releasedata && releasedata.phonographic_copyright_year
          ? releasedata.phonographic_copyright_year
          : "",
      copyright_year:
        releasedata && releasedata.copyright_year
          ? releasedata.copyright_year
          : "",
      copyrighted_to:
        releasedata && releasedata.copyrighted_to
          ? releasedata.copyrighted_to
          : "",
    };
  }

  // Formik

  function Required() {
    return (
      <span style={{ color: "red", fontSize: "14px" }}>
        <span>Required</span> <span>*</span>
      </span>
    );
  }

  function RequiredText() {
    return (
      <p
        style={{
          color: "red",
          fontSize: "14px",
          marginTop: "-8px",
          marginLeft: "-10px",
        }}
      >
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </p>
    );
  }
  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(key)) {
      event.preventDefault();
    }
  };

  function getAssetId(trackId) {
    let AssetID = "";

    AssetID = props?.AssetAndTrackList.filter(
      (item) => item.track_id === trackId
    ).map((value) => {
      return value.assets_id;
    });

    return AssetID[0]?.id ?? "";
  }

  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function convertToSnakeCase(str) {
    // Convert the string to lowercase
    var lowercaseStr = str.toLowerCase();

    // Split the lowercase string into words
    var words = lowercaseStr.split(" ");

    // If the string has more than one word
    if (words.length > 1) {
      // Join the words with underscores
      return words.join("_");
    }
    // Otherwise, return the lowercase string
    return lowercaseStr;
  }

  const [selectedArtists, setSelectedArtists] = useState([]);
  const [inputvalueforactor, setinputvalueforactor] = useState("");
  const [inputvalueforactress, setinputvalueforactress] = useState("");
  const [inputvalueforDirector, setinputvalueforDirector] = useState("");

  const inputRef = useRef("");
  const postRef = useRef("");
  const actorFieldRef = useRef("");
  const artistFieldRef = useRef("");

  const primaryArtistFieldRef = useRef(null);
  const featuringArtistFieldRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeData, setYoutubeData] = useState(null);
  const [appleMusicData, setAppleMusicData] = useState(null);
  const [spotifyData, setSpotifyData] = useState(null);

  const ArtistSelect = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
      },
      ref
    ) => {
      return (
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            textAlign="right"
            px={1}
            style={{ direction: "ltr", color: theme.palette.info.main }}
          >
            <Typography>
              {label} {required && "*"}
            </Typography>
          </Grid>

          <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
            <Grid lg={12} md={12} sm={12} className="d-flex">
              <Field
                name={name}
                size="small"
                // Calling AutoCompleteMultiSelect Component
                component={AutoCompleteMultiSelect}
                options={options ? options : []}
                textFieldProps={{
                  variant: "outlined",
                  placeholder: placeholder,
                }}
                openProp={openProp}
                multiple
                filterOptions={(options, { inputValue }) => {
                  let minCharsToShowDropdown = 2;
                  if (inputValue.length < minCharsToShowDropdown) {
                    return []; // If less than minCharsToShowDropdown, hide the dropdown
                  }

                  const filteredOptions = options.filter(
                    (option) =>
                      option.artist_name &&
                      option.artist_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  );

                  return filteredOptions.sort((a, b) =>
                    a.artist_name.localeCompare(b.artist_name)
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.artist_name === value.artist_name
                }
                ref={ref}
                freeSolo={true}
                getOptionLabel={(option) => option.artist_name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.artist_id}>
                      <CustomOption option={option} />
                    </li>
                  );
                }}
                onInputChange={(e, value) => {
                  inputRef.current = value;
                  artistFieldRef.current = name;
                }}
                sx={{
                  width: "-webkit-fill-available",
                }}
                value={values[name]}
                error={Boolean(errors[name]) && Boolean(touched[name])}
                helperText={Boolean(touched[name]) && errors[name]}
              />

              <IconButton
                disabled={values[name] && values[name].length === 3}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (inputRef.current) {
                    const trimmedInput = inputRef.current.trim();

                    if (trimmedInput !== "") {
                      setIsLoading(["Youtube", "Apple Music", "Spotify"]);

                      setYoutubeData(null);
                      setAppleMusicData(null);
                      setSpotifyData(null);
                      setSelectedArtists([]);

                      fetchPlatformArtists("YouTube", inputRef.current);
                      fetchPlatformArtists("Apple Music", inputRef.current);
                      fetchPlatformArtists("Spotify", inputRef.current);
                    }
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <span className="px-1 ">
                <span>
                  <ReportGmailerrorredIcon
                    style={{ marginRight: 2, fontSize: 16 }}
                  />
                </span>
                <span>Max 3 members can be added</span>
              </span>
              {name === artistFieldRef.current && (
                <>
                  <RenderArtistsSearchList
                    platformData={youtubeData}
                    platformName={"Youtube"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  <RenderArtistsSearchList
                    platformData={appleMusicData}
                    platformName={"Apple Music"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  <RenderArtistsSearchList
                    platformData={spotifyData}
                    platformName={"Spotify"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  {(youtubeData || spotifyData || appleMusicData) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingY: "3px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setYoutubeData(null);
                          setAppleMusicData(null);
                          setSpotifyData(null);
                          setIsLoading(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                          ml: 1,
                        }}
                        onClick={() => {
                          let post_stream_platforms = [];

                          [
                            { platform_id: "1", platform_name: "spotify" },
                            { platform_id: "2", platform_name: "apple_music" },
                            {
                              platform_id: "3",
                              platform_name: "youtube_music",
                            },
                          ].map((platform) => {
                            if (selectedArtists.length > 0) {
                              if (
                                !selectedArtists.some(
                                  (artist) =>
                                    artist.stream_platform_id ===
                                    platform.platform_id
                                )
                              ) {
                                post_stream_platforms.push({
                                  stream_platform_artist_id: "",
                                  stream_platform_artist_name: "",
                                  stream_platform_artist_profile_url: "",
                                  stream_platform_id: platform.platform_id,
                                  stream_platform_name: platform.platform_name,
                                  generate: true,
                                });
                              } else {
                                post_stream_platforms.push(
                                  selectedArtists.filter(
                                    (artist) =>
                                      artist.stream_platform_id ===
                                      platform.platform_id
                                  )[0]
                                );
                              }
                            } else {
                              post_stream_platforms.push({
                                stream_platform_artist_id: "",
                                stream_platform_artist_name: "",
                                stream_platform_artist_profile_url: "",
                                stream_platform_id: platform.platform_id,
                                stream_platform_name: platform.platform_name,
                                generate: true,
                              });
                            }
                          });

                          let postData = {
                            artist_name: inputRef.current,
                            stream_platforms: post_stream_platforms,
                          };

                          let access_token = localStorage.getItem("Auth")
                            ? JSON.parse(localStorage.getItem("Auth"))
                                .access_token
                            : "";

                          fetch(
                            `${environment.api.artist}/add?current_page=tracks`,
                            {
                              method: "POST",
                              headers: {
                                Authorization: "Bearer " + access_token,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify(postData),
                            }
                          )
                            .then((res) => res.json())
                            .then((response) => {
                              setFieldValue(name, [
                                ...values[name],
                                {
                                  artist_id: response.id,
                                  artist_name: inputRef.current,
                                },
                              ]);

                              setYoutubeData(null);
                              setAppleMusicData(null);
                              setSpotifyData(null);
                              setSelectedArtists([]);
                            });
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  );

  const ArtistSelectDailoge = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
        disabled,
        title,
      },
      ref
    ) => {
      return (
        <FieldArray name={name}>
          {({ insert, remove, push }) => (
            <Box sx={{ width: "100%" }}>
              {Array.isArray(values[name]) &&
                values[name].map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      mt={1.5}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                    >
                      {index === 0 && (
                        <Typography sx={{ color: theme.palette.info.main }}>
                          {label} {required && "*"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={8}
                      md={8}
                      sm={8}
                      my={0.5}
                      justifyContent="flex-start"
                    >
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        item
                        container
                        justifyContent="flex-start"
                      >
                        <Grid
                          item
                          xs={10}
                          sx={{
                            marginLeft: {
                              lg: 1,
                              md: 1,
                              sm: 1,
                            },
                          }}
                        >
                          <Tooltip title={title} arrow placement="right-end">
                            <Field
                              width="100%"
                              style={{
                                sx: "100%",
                              }}
                              disabled={disabled}
                              // name={name}
                              name={`${name}[${index}]`}
                              size="small"
                              component={AutocompleteDialoge}
                              remove={() => {
                                values[name].length !== 1 && remove(index);
                              }}
                              fetchartistFilter={fetchArtistFilter}
                              innerRef={ref}
                              values={values}
                              options={options ? options : []}
                              textFieldProps={{
                                variant: "outlined",
                                placeholder: placeholder,
                              }}
                              renderOption={(props, option) => {
                                if (option && option.artist_name) {
                                  return (
                                    <li {...props} key={option.artist_id}>
                                      <CustomOption option={option} />
                                    </li>
                                  );
                                }
                              }}
                              filterOptions={(options, { inputValue }) => {
                                ref.current = inputValue;
                                let minCharsToShowDropdown = 2;
                                let filteredOptions;

                                if (
                                  inputValue.length < minCharsToShowDropdown
                                ) {
                                  return []; // If less than minCharsToShowDropdown, hide the dropdown
                                } else {
                                  filteredOptions = options.filter(
                                    (option) =>
                                      option.artist_name &&
                                      option.artist_name
                                        .toLowerCase()
                                        .includes(
                                          inputValue && inputValue.toLowerCase()
                                        )
                                  );

                                  filteredOptions = filteredOptions.sort(
                                    (a, b) =>
                                      a.artist_name.localeCompare(b.artist_name)
                                  );

                                  filteredOptions = [
                                    ...filteredOptions,
                                    {
                                      artist_name: `Add "${inputValue}"`,
                                    },
                                  ];
                                }

                                return filteredOptions;
                              }}
                              sx={{
                                width: "-webkit-fill-available",
                              }}
                              // groupBy={(group) => group.artist_name}
                              getOptionLabel={(option) => {
                                return option && option.artist_name;
                              }}
                              value={values[name][index]}
                              isOptionEqualToValue={(option, value) => {
                                return option.artist_name === value.artist_name;
                              }}
                              error={
                                Boolean(errors[name]) && Boolean(touched[name])
                              }
                              helperText={
                                Boolean(touched[name]) && errors[name]
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1}>
                          {(values[name][index] || values[name][index - 1]) && (
                            <IconButton
                              onClick={() => {
                                remove(index);
                                values[name].length === 1 && push("");
                              }}
                            >
                              <DeleteIcon color="primary" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {values[name] &&
                values[name][values[name].length - 1] &&
                values[name][values[name] && values[name].length - 1].length !==
                  0 && (
                  <Grid
                    container
                    lg={11}
                    md={11}
                    sm={11}
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    px={1}
                  >
                    <IconButton onClick={() => push("")}>
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                )}
            </Box>
          )}
        </FieldArray>
      );
    }
  );

  function UpdateSelectedArtists(previousArray, updatedObject, checked) {
    let idToUpdate = updatedObject.stream_platform_id;
    let updatedArray;

    if (checked) {
      if (
        previousArray.some((item) => item.stream_platform_id === idToUpdate)
      ) {
        updatedArray = previousArray.map((item) =>
          item.stream_platform_id === idToUpdate ? updatedObject : item
        );
      } else {
        updatedArray = [...previousArray, updatedObject];
      }
    } else {
      updatedArray = previousArray.filter(
        (item) => item.stream_platform_id !== idToUpdate
      );
    }

    return updatedArray;
  }

  function fetchPlatformArtists(platform, query) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let API = "";
    if (platform === "YouTube")
      API = `${environment.api.baseApi}/search_musiclabels_artist_youtube?q=${query}&current_page=tracks`;
    if (platform === "Apple Music")
      API = `${environment.api.baseApi}/search_musiclabels_artist_applemusic?q=${query}&current_page=tracks`;
    if (platform === "Spotify")
      API = `${environment.api.baseApi}/search_musiclabels_artist?q=${query}&current_page=tracks`;

    fetch(API, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (platform === "Apple Music") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setAppleMusicData([]);
            } else {
              let artist_data = response.apple_music_artist_details;
              setAppleMusicData(artist_data);
            }

            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Apple Music");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
        if (platform === "YouTube") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setYoutubeData([]);
            } else {
              let artist_data = response.youtube_music_artist_details;
              setYoutubeData(artist_data);
            }

            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Youtube");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
        if (platform === "Spotify") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setSpotifyData([]);
            } else {
              let artist_data = response.spotify_artist_details;
              setSpotifyData(artist_data);
            }
            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Spotify");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
      })
      .catch((err) => {});
  }

  const CustomOption = ({ option }) => (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Typography component={"p"}>{option.artist_name}</Typography>
      <Typography component={"div"} className="d-flex">
        {option.stream_platforms?.map((item) => (
          <>
            <Typography
              sx={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                marginX: 1,
              }}
            >
              {item.stream_platform_artist_id &&
              item.stream_platform_id === "1" ? (
                <FontAwesomeIcon icon={faSpotify}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "2" ? (
                <FontAwesomeIcon icon={faApple}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "3" ? (
                <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
              ) : null}
            </Typography>
          </>
        ))}
      </Typography>
    </Box>
  );

  const RenderArtistsSearchList = ({
    platformData,
    platformName,
    isLoading,
    selectedArtistsArray,
  }) => {
    return (
      <Box>
        {(platformData || (isLoading && Boolean(isLoading.length))) && (
          <Typography
            sx={{
              backgroundColor: `${theme.palette.background.light}`,
              height: "30px",
              borderBottom: `1px solid #bebec0`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingX: "10px",
            }}
          >
            <span
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            >
              {platformName}{" "}
            </span>
            {!platformData && isLoading.includes(platformName) ? (
              <CircularProgress size={20}></CircularProgress>
            ) : null}
          </Typography>
        )}

        {platformData &&
          (platformData.length > 0 ? (
            <Box
              className="scrollable"
              style={{
                height: "200px",
                border: "1px solid grey",
                backgroundColor: "white",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <List>
                {platformData?.map((item, index) => (
                  <ListItem key={index} button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedArtists.some(
                            (artist) =>
                              artist.stream_platform_artist_id ===
                              item.artist_id
                          )}
                        />
                      }
                      label=""
                      name="selected_artist"
                      onChange={(e, checked) => {
                        let post_obj = {};

                        if (platformName === "Youtube") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_id: "3",
                            stream_platform_name: platformName,
                            generate: false,
                          });

                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Spotify") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_images_url[0],
                            stream_platform_id: "1",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Apple Music") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_id: "2",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                      }}
                    />
                    <ListItemAvatar>
                      <Avatar
                        alt={item.artist_name}
                        src={
                          platformName === "Spotify"
                            ? item.artist_images_url[0]
                            : platformName === "Youtube"
                            ? item.artist_image_url
                            : platformName === "Apple Music"
                            ? item.artist_image_url
                            : ""
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText primary={item.artist_name} />
                    <Typography
                      component={"a"}
                      href={
                        platformName === "Spotify"
                          ? item.profile_url
                          : platformName === "Youtube"
                          ? item.profile_url
                          : platformName === "Apple Music"
                          ? item.profile_url
                          : null
                      }
                      target="_blank"
                    >
                      <OpenInNewIcon />
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              style={{
                border: "1px solid grey",
                backgroundColor: "white",
                overflowY: "auto",
                padding: 2,
              }}
            >
              <Typography component={"p"} textAlign={"center"}>
                No Artists found!
              </Typography>
            </Box>
          ))}
      </Box>
    );
  };

  const PostFunction = (value, setFieldValue, values, name) => {
    if (name === "primary_actor") {
      let obj = {
        actor_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = async (res) => {
        await props.onGet(name);

        setFieldValue(name, [
          ...values[name],
          {
            actor_id: res?.id,
            actor_name: res?.data?.actor_name,
          },
        ]);

        postRef.current = "";
      };

      props
        .dispatch(ActorAction.requestPostActor(obj, params, callBack))
        .then(() => {});
    } else if (name === "primary_actress") {
      let obj = {
        actress_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = (res) => {
        setFieldValue(name, [
          ...values[name],
          {
            actress_id: res.id,
            actress_name: res?.data?.actress_name,
          },
        ]);
        postRef.current = "";
      };

      props
        .dispatch(ActressAction.requestPostActress(obj, params, callBack))
        .then(() => {
          props.onGet(name);
        });
    } else if (name === "director") {
      let obj = {
        director_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = (res) => {
        setFieldValue(name, [
          ...values[name],
          {
            director_id: res?.id,
            director_name: res?.data?.director_name,
          },
        ]);
        postRef.current = "";
      };

      props
        .dispatch(DirectorAction.requestPostDirector(obj, params, callBack))
        .then(() => {
          props.onGet(name);
        });
    }
  };

  const returnAddIconForActorField = (options, input) => {
    let boolean = false;

    let filteredActorArray = [];

    if (options && options.length && options.length !== 0) {
      filteredActorArray = options.filter((item) => {
        return (
          item.actor_name &&
          item.actor_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredActorArray && filteredActorArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  const returnAddIconDirectorForField = (options, input) => {
    let boolean = false;

    let filteredDirectorArray = [];

    if (options && options.length && options.length !== 0) {
      filteredDirectorArray = options.filter((item) => {
        return (
          item.director_name &&
          item.director_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredDirectorArray && filteredDirectorArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  const returnAddIconForActressField = (options, input) => {
    let boolean = false;

    let filteredActressArray = [];

    if (options && options.length && options.length !== 0) {
      filteredActressArray = options.filter((item) => {
        return (
          item.actress_name &&
          item.actress_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredActressArray && filteredActressArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  // Only for producer, arranger, composer & author
  function spaceRemover(inputArray) {
    return inputArray.some((item) => item.trim() !== "")
      ? {
          updatedArray: inputArray
            .filter((item) => item.trim() !== "")
            .map((item) => item.trim()),
        }
      : { updatedArray: [] };
  }

  return (
    <Dialog
      open={props.open}
      // onClose={props.onClose}
      fullWidth
      maxWidth="sm"
      className="scrollable"
      onClose={null}
    >
      <Box
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          px={3}
          py={0.5}
          sx={{
            backgroundColor: theme.palette.background.tab,
            boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item>
            <Typography fontSize={16}>Tracks metadata</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                props.onClose();
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: `${theme.palette.text.primary}`,
                  fontSize: "18px",
                }}
              ></FontAwesomeIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.scrollbar}>
        <Formik
          enableReinitialize
          initialValues={initial}
          onSubmit={(values, formikHelpers) => {
            let Obj = {};

            {
              props.subgenrelist?.length > 0 &&
              props.subgenrelist.some(
                (item) => item?.id == values.sub_genre_name
              )
                ? (values.sub_genre_name = values.sub_genre_name)
                : (values.sub_genre_name = null);
            }

            let publisherArray = [];

            if (values.publishers.length) {
              values.publishers.map((item) => {
                item.name && publisherArray.push(`${item.name}-${item.type}`);
              });
            }

            Object.assign(
              Obj,
              values.title ? { title: values.title.trim() } : { title: null },
              values.ask_to_generate_an_isrc && {
                ask_to_generate_an_isrc:
                  values.ask_to_generate_an_isrc === "No" ? false : true,
              },
              {
                isrc:
                  props?.releasedata?.type_of_release === "video"
                    ? values.isrc
                    : values.ask_to_generate_an_isrc === "Yes"
                    ? null
                    : values.isrc,
              },
              props?.releasedata?.type_of_release === "video" && {
                audio_isrc: values.audio_isrc,
              },

              values.content_type && {
                content_type: convertToSnakeCase(values.content_type),
              },

              values.featuring.some((item) => item !== "")
                ? { featuring: values.featuring }
                : { featuring: [] },
              values.instrumental && {
                instrumental: values.instrumental === "No" ? false : true,
              },
              values.lyrics ? { lyrics: values.lyrics } : { lyrics: null },
              values.lyrics_language && {
                lyrics_language:
                  values.instrumental === "Yes"
                    ? "zxx"
                    : values.lyrics_language.id,
              },
              // : {
              //     lyrics_language: null,
              //   },
              values.parental_advisory && {
                parental_advisory: values.parental_advisory.toLowerCase(),
              },
              values.preview_start
                ? {
                    preview_start: values.preview_start,
                  }
                : {
                    preview_start: null,
                  },

              values.plan_id
                ? {
                    plan_id: values.plan_id,
                  }
                : {
                    plan_id: values.plan_id,
                  },
              values.primary_artists.some((item) => item !== "")
                ? {
                    primary_artists: values.primary_artists?.filter(
                      (artist) => artist !== ""
                    ),
                  }
                : {
                    primary_artists: [],
                  },

              values.director
                ? {
                    director: values.director,
                  }
                : {
                    director: [],
                  },
              values.primary_actor
                ? {
                    primary_actor: values.primary_actor,
                  }
                : {
                    primary_actor: [],
                  },
              values.primary_actress
                ? {
                    primary_actress: values.primary_actress,
                  }
                : {
                    primary_actress: [],
                  },
              values.primary_track_type && {
                primary_track_type: values.primary_track_type.toLowerCase(),
              },
              { author: spaceRemover(values.author).updatedArray },
              { composer: spaceRemover(values.composer).updatedArray },
              { arranger: spaceRemover(values.arranger).updatedArray },
              { producer: spaceRemover(values.producer).updatedArray },
              values.producer_catalouge_number
                ? {
                    producer_catalouge_number: values.producer_catalouge_number,
                  }
                : {
                    producer_catalouge_number: null,
                  },
              values.production_year
                ? {
                    production_year: values.production_year,
                  }
                : {
                    production_year: null,
                  },
              values.published_by
                ? {
                    published_by: values.published_by,
                  }
                : {
                    published_by: null,
                  },
              // values.publisher && {
              //   publisher: values.publisher.join("-"),
              // },
              {
                publisher: publisherArray,
              },

              values.remixer
                ? {
                    remixer: values.remixer,
                  }
                : {
                    remixer: [],
                  },
              values.release_id && {
                release_id: values.release_id,
              },
              // values.secondary_genre && {
              //   secondary_genre: values.secondary_genre,
              // },
              values.secondary_track_type && {
                secondary_track_type: values.secondary_track_type.toLowerCase(),
              },
              // values.seconday_sub_genre && {
              //   seconday_sub_genre: values.seconday_sub_genre,
              // },
              values.stream_id && {
                stream_id: values.stream_id,
              },
              values.genre
                ? {
                    genre: values.genre,
                  }
                : {
                    genre: null,
                  },
              values.sub_genre_name
                ? {
                    sub_genre_name: values.sub_genre_name,
                  }
                : {
                    sub_genre_name: null,
                  },
              values.track_title_language && {
                track_title_language:
                  values.instrumental === "Yes"
                    ? "zxx"
                    : values.track_title_language.id,
              },
              // : {
              //     track_title_language: null,
              //   },
              values.version_subtitle &&
                Array.isArray(values.version_subtitle) &&
                values.version_subtitle.length === 0
                ? {
                    ["music/album"]: null,
                  }
                : {
                    ["music/album"]: values.version_subtitle,
                  },

              values.views
                ? {
                    views: Number(values.views),
                  }
                : {
                    views: null,
                  },
              values.phonographic_copyright_year && {
                phonographic_copyright_year:
                  values.phonographic_copyright_year.toString(),
              },
              values.copyright_year && {
                copyright_year: `${values.copyright_year}`,
              },
              values.copyrighted_to
                ? {
                    copyrighted_to: values.copyrighted_to,
                  }
                : {
                    copyrighted_to: null,
                  },
              !values.track_number
                ? { track_number: props?.currentTracksLength + 1 }
                : { track_number: values.track_number }
            );
            setLoading(true);
            // parameter for showing list based on the modified_by
            const params = {
              order_by: `[["track_number","asc"]]`,
              page_limit: 15,
              filters: `[["release_id.keyword","must","","match","${props.release_id}"]]`,
              current_page: "tracks",
            };
            // const param = {
            //   order_by: `[["created_on","desc"]]`,
            //   page_limit: "none",
            //   current_page: "tracks",
            // };
            if (props.edit) {
              props.setIsTrackError(false);
              let page_params = {
                current_page: "tracks",
              };
              props
                .dispatch(
                  TrackAction.requestPutTrack(
                    JSON.stringify(Obj),
                    props.projectEditData.id,
                    page_params
                  )
                )
                .then(() => {
                  // props.dispatch(TrackAction.requestTrack(params));
                  // props.dispatch(TrackAction.requestTrackFilter(param));
                  props.onSubmit();
                  if (props.isTrackError || props.submissionRedirect)
                    navigate("/submission");
                  props.onClose();
                  setLoading(false);
                  updateTrackErrorInState(false);
                })
                .catch((e) => {
                  setLoading(false);
                });
            } else {
              const callBack = (res) => {
                const tracks = [];
                res?.hits?.hits?.length > 0 &&
                  res.hits.hits.map((track) => {
                    tracks.push({
                      assets_id: getAssetId(track._id),
                      track_id: track._id,
                    });
                  });

                let put_obj = {
                  tracks: tracks,
                };

                if (res?.hits?.hits?.length > 0) {
                  let page_params = {
                    current_page: "tracks",
                  };
                  props
                    .dispatch(
                      ReleasesAction.requestPutRelease(
                        JSON.stringify(put_obj),
                        props.release_id,
                        page_params
                      )
                    )
                    .then(() => {
                      let page_params = {
                        current_page: "tracks",
                      };
                      props.dispatch(
                        ReleasesAction.requestReleaseById(
                          props.release_id,
                          page_params
                        )
                      );
                    });
                }
              };

              let page_params = {
                current_page: "tracks",
              };

              props
                .dispatch(TrackAction.requestPostTrack(Obj, page_params))
                .then(() => {
                  props.dispatch(TrackAction.requestTrack(params, callBack));
                  // props.onSubmit();
                  props.onClose();
                  //navigate("/releaseDate");

                  setLoading(false);
                })
                .catch((e) => {
                  setLoading(false);
                });
            }

            // formikHelpers.resetForm();
          }}
          validate={(values) => {
            var errors = {};

            function isAlphanumericWithBoth(values) {
              const containsLetters = /[a-zA-Z]/.test(values);
              const containsNumbers = /[0-9]/.test(values);
              const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

              if (containsLetters && containsNumbers && !containsSpecialChars) {
                // The string contains both letters and numbers (alphanumeric) and no special characters.
                return true;
              } else {
                // The string does not meet the criteria.
                return false;
              }
            }
            if (!values.content_type) {
              errors.content_type = <Required />;
            }
            if (!values.primary_track_type) {
              errors.primary_track_type = <Required />;
            }
            if (!values.secondary_track_type) {
              errors.secondary_track_type = <Required />;
            }

            if (!values.title) {
              errors.title = <RequiredText />;
            }

            if (values.primary_artists) {
              let trueValue = values.primary_artists.some(
                (item) => item !== ""
              );
              !trueValue && (errors.primary_artists = []);
              !trueValue && (errors.primary_artists[0] = <RequiredText />);
            }

            if (values.author && values.instrumental === "No") {
              let trueValue = values.author.some((item) => item !== "");
              !trueValue && (errors.author = []);
              !trueValue && (errors.author[0] = <Required />);
            }
            if (values.composer) {
              let trueValue = values.composer.some((item) => item !== "");
              !trueValue && (errors.composer = []);
              !trueValue && (errors.composer[0] = <Required />);
            }

            if (!values.genre) {
              errors.genre = <Required />;
            }
            if (!values.sub_genre_name) {
              errors.sub_genre_name = <Required />;
            }
            if (!values.plan_id) {
              errors.plan_id = <Required />;
            }
            if (values.instrumental === "No") {
              if (!values.track_title_language) {
                errors.track_title_language = <Required />;
              }
            }
            if (values.instrumental === "No") {
              if (!values.lyrics_language) {
                errors.lyrics_language = <Required />;
              }
            }
            if (!values.copyright_year) {
              errors.copyright_year = <Required />;
            }

            if (!values.production_year) {
              errors.production_year = <Required />;
            }
            if (!values.preview_start) {
              errors.preview_start = <RequiredText />;
            }

            // if (values.publisher.length > 0) {
            //   (!values.publisher[0] || !values.publisher[1]) &&
            //     (errors.publisher = []);

            //   if (values.publisher.length === 1 && !values.publisher[1]) {
            //     errors.publisher[1] = <Required />;
            //   }
            //   if (values.publisher.length === 2) {
            //     !values.publisher[0] && (errors.publisher[0] = <Required />);
            //     !values.publisher[1] && (errors.publisher[1] = <Required />);
            //   }
            // } else {
            //   (!values.publisher[0] || !values.publisher[1]) &&
            //     (errors.publisher = []);
            //   !values.publisher[0] && (errors.publisher[0] = <Required />);
            //   !values.publisher[1] && (errors.publisher[1] = <Required />);
            // }
            if (values.publishers?.length > 0) {
              values.publishers.map((item, index) => {
                if (item && !item.name) {
                  errors.publishers = [];
                  errors.publishers[index] = <RequiredText />;
                }
              });
            }
            if (values.ask_to_generate_an_isrc === "No") {
              if (!values.isrc) {
                errors.isrc = <Required />;
              }
            }

            if (props?.releasedata?.type_of_release === "video") {
              if (!isAlphanumericWithBoth(values.audio_isrc)) {
                errors.audio_isrc = <Required />;
              }
            }
            if (!values.copyrighted_to) {
              errors.copyrighted_to = <RequiredText />;
            }

            if (!values.published_by) {
              errors.published_by = <RequiredText />;
            }

            if (!values.phonographic_copyright_year) {
              errors.phonographic_copyright_year = <Required />;
            }

            if (!values.producer_catalouge_number) {
              errors.producer_catalouge_number = <RequiredText />;
            }

            if (values.producer_catalouge_number) {
              if (!isAlphanumericWithBoth(values.producer_catalouge_number)) {
                errors.producer_catalouge_number =
                  "should only contain alphanumeric characters";
              }
            }

            return props.isTrackError && errors;
          }}
          innerRef={formRef}
        >
          {({
            errors,
            isValid,
            helperText,
            touched,
            dirty,
            handleChange,
            setFieldValue,
            values,
          }) => (
            <Box>
              <Form>
                {console.log("TRACK_VALUEs", values, props)}
                <Box>
                  <LoadingIndicator isActive={props.isRequesting}>
                    {!props.isRequesting && (
                      <div>
                        <Box>
                          <Grid
                            item
                            container
                            alignItems={"center"}
                            justifyContent="center"
                            pb={3}
                          >
                            <Grid
                              item
                              container
                              xl={11}
                              lg={10}
                              md={10}
                              sm={8}
                              xs={8}
                              sx={{
                                display: "flex",
                                flexDirection: "flex-column",
                              }}
                              gap={4}
                            >
                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  md={3.5}
                                  lg={3.5}
                                  sm={3.5}
                                  textAlign="right"
                                  height={10}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Content type * :
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  md={7}
                                  lg={7}
                                  sm={7}
                                  textAlign="left"
                                  height={20}
                                >
                                  <Field name="content_type">
                                    {({ field }) => (
                                      <RadioGroup
                                        className="p-0 m-0"
                                        {...field}
                                        row
                                        onChange={(event) =>
                                          setFieldValue(
                                            "content_type",
                                            event.target.value
                                          )
                                        }
                                      >
                                        {contentTypeArray.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={
                                              <Radio
                                                className="p-1 m-0"
                                                size="small"
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                  padding: 0,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12, padding: 0 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                  <Grid item md={7} lg={7} sm={7} ml={1}>
                                    <ErrorMessage name="content_type" />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  md={3.5}
                                  lg={3.5}
                                  sm={3.5}
                                  textAlign="right"
                                  height={10}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Primary Track Type * :
                                  </Typography>
                                </Grid>
                                <Grid
                                  height={20}
                                  item
                                  md={7}
                                  lg={7}
                                  sm={7}
                                  textAlign="left"
                                >
                                  <Field name="primary_track_type">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        onChange={(event) =>
                                          setFieldValue(
                                            "primary_track_type",
                                            event.target.value
                                          )
                                        }
                                      >
                                        {primaryTrackTypeeArray.map(
                                          (option) => (
                                            <FormControlLabel
                                              key={option}
                                              className="p-0 m-0"
                                              value={option}
                                              control={
                                                <Radio
                                                  className="p-1 m-0"
                                                  size="small"
                                                  disabled={
                                                    (props?.releasedata
                                                      ?.type_of_release ===
                                                      "audio" &&
                                                      option.includes(
                                                        "Video"
                                                      )) ||
                                                    (props?.releasedata
                                                      ?.type_of_release ===
                                                      "video" &&
                                                      option.includes("Audio"))
                                                  }
                                                />
                                              }
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  {option}
                                                </span>
                                              }
                                              sx={{ fontSize: 12 }}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                  <Grid item md={7} lg={7} sm={7} ml={1}>
                                    <ErrorMessage name="primary_track_type" />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  md={3.5}
                                  lg={3.5}
                                  sm={3.5}
                                  textAlign="right"
                                  height={10}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Secondary Track Type * :
                                  </Typography>
                                </Grid>
                                <Grid
                                  height={20}
                                  item
                                  md={7}
                                  lg={7}
                                  sm={7}
                                  textAlign="left"
                                >
                                  <Field name="secondary_track_type">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        onChange={(event) =>
                                          setFieldValue(
                                            "secondary_track_type",
                                            event.target.value
                                          )
                                        }
                                      >
                                        {secondaryTrackTypes.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={
                                              <Radio
                                                className="p-1 m-0"
                                                size="small"
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                  <Grid item md={7} lg={7} sm={7} ml={1}>
                                    <ErrorMessage name="secondary_track_type" />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  md={3.5}
                                  lg={3.5}
                                  sm={3.5}
                                  textAlign="right"
                                  height={10}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Instrumental * :
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  md={7}
                                  lg={7}
                                  sm={7}
                                  textAlign="left"
                                  height={20}
                                >
                                  <Field name="instrumental">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        onChange={(event) => {
                                          setFieldValue(
                                            "instrumental",
                                            event.target.value
                                          );
                                          if (event.target.value === "Yes") {
                                            setFieldValue(
                                              "track_title_language",
                                              {
                                                id: "zxx",
                                                name: "No linguistic content",
                                              }
                                            );
                                            setFieldValue("lyrics_language", {
                                              id: "zxx",
                                              name: "No linguistic content",
                                            });
                                          } else if (
                                            event.target.value === "No"
                                          ) {
                                            setFieldValue(
                                              "track_title_language",
                                              releasedata &&
                                                releasedata.meta_language
                                                ? {
                                                    id: releasedata
                                                      .meta_language
                                                      .meta_language,
                                                    name: releasedata
                                                      .meta_language
                                                      .meta_language_name,
                                                  }
                                                : null
                                            );
                                            setFieldValue(
                                              "lyrics_language",
                                              releasedata &&
                                                releasedata.meta_language
                                                ? {
                                                    id: releasedata
                                                      .meta_language
                                                      .meta_language,
                                                    name: releasedata
                                                      .meta_language
                                                      .meta_language_name,
                                                  }
                                                : null
                                            );
                                          }
                                        }}
                                      >
                                        {InstrumentalArray.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={
                                              <Radio
                                                className="p-1 m-0"
                                                size="small"
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                  <ErrorMessage name="instrumental" />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        <hr />
                        <Box>
                          <Grid
                            //lg={12}
                            item
                            container
                            alignItems={"center"}
                            justifyContent="center"
                          >
                            <Grid
                              item
                              container
                              xl={11}
                              lg={10}
                              md={10}
                              sm={8}
                              xs={8}
                              sx={{
                                display: "flex",
                                flexDirection: "flex-column",
                              }}
                              // className="bg-warning"
                              gap={3}
                            >
                              <FormField
                                label="Song Title"
                                placeholder="Song Title"
                                id="title"
                                name="title"
                                errors={errors}
                                required={true}
                                touched={touched}
                                title="Enter the Track name/Song Title"
                              />
                              {/* <AutoCompleteForm
                        name="primary_artists"
                        label="Primary Artists"
                        placeholder="Primary Artists"
                        options={props.artistArray}
                        errors={errors}
                        touched={touched}
                        required={true}
                        openProp={
                          values && values.primary_artists
                            ? values.primary_artists
                            : []
                        }
                      /> */}
                              {/* <ArtistSelect
                        ref={primaryArtistFieldRef}
                        name="primary_artists"
                        label="Primary Artist"
                        placeholder="Primary Artist"
                        options={props.artistArray ? props.artistArray : []}
                        values={values}
                        touched={touched}
                        errors={errors}
                        required={true}
                        openProp={
                          values && values.primary_artists
                            ? values.primary_artists
                            : []
                        }
                        setFieldValue={setFieldValue}
                      /> */}
                              {/* <ArtistSelect
                        ref={featuringArtistFieldRef}
                        name="featuring"
                        label="Featuring"
                        placeholder="Featuring"
                        options={props.artistArray ? props.artistArray : []}
                        values={values}
                        touched={touched}
                        errors={errors}
                        required={false}
                        openProp={
                          values && values.featuring ? values.featuring : []
                        }
                        setFieldValue={setFieldValue}
                      /> */}
                              {/* <AutoCompleteForm
                        name="featuring"
                        label="Featuring"
                        placeholder="Featuring"
                        options={props.artistArray}
                        errors={errors}
                        touched={touched}
                        openProp={
                          values && values.featuring ? values.featuring : []
                        }
                      /> */}

                              <ArtistSelectDailoge
                                ref={primaryArtistFieldRef}
                                name="primary_artists"
                                artistFieldRef={artistFieldRef}
                                label="Primary Artist"
                                placeholder="Primary Artist"
                                options={
                                  props.artistArray ? props.artistArray : []
                                }
                                values={values}
                                touched={touched}
                                errors={errors}
                                required={true}
                                openProp={
                                  values && values.primary_artists
                                    ? values.primary_artists
                                    : []
                                }
                                setFieldValue={setFieldValue}
                                title="Enter a new artist or select an existing catalog artist as an additional main artist or other artist role.Each field should contain only one artist name unless the artists are generally listed together as a group.Spelling should be consistent across all artist's content"
                              />
                              <ArtistSelectDailoge
                                ref={featuringArtistFieldRef}
                                name="featuring"
                                label="Featuring"
                                placeholder="Featuring Artist"
                                options={
                                  props.artistArray ? props.artistArray : []
                                }
                                values={values}
                                touched={touched}
                                errors={errors}
                                openProp={
                                  values && values.featuring
                                    ? values.featuring
                                    : []
                                }
                                setFieldValue={setFieldValue}
                                title="Enter the Featuring Artist Field"
                              />
                              <AutoCompleteForm
                                name="remixer"
                                label="Remixer"
                                placeholder="Remixer"
                                options={props.artistArray}
                                errors={errors}
                                touched={touched}
                                title="Enter the Remixer Name"
                              />
                              <FieldArrayComponent
                                name="author"
                                label={"Author"}
                                values={values}
                                required={
                                  values.instrumental === "Yes" ? false : true
                                }
                                disabled={
                                  values.instrumental === "Yes" ? true : false
                                }
                                errors={errors}
                                touched={touched}
                                title="Enter the author name"
                              />

                              <FieldArrayComponent
                                name="composer"
                                label="Composer"
                                values={values}
                                required={true}
                                errors={errors}
                                touched={touched}
                                title="Enter the composer name"
                              />
                              <FieldArrayComponent
                                name="arranger"
                                label="Arranger"
                                values={values}
                                title="Enter the arranger name"
                              />
                              <FieldArrayComponent
                                name="producer"
                                label="Producer"
                                values={values}
                                title="Enter the producer name"
                              />
                              <SelectField
                                label="CopyRighted Year"
                                name="copyright_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the Copyrighted year"
                              />
                              <SelectField
                                label="Phonographic Copyright Year"
                                name="phonographic_copyright_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter phonographic copyright text.Enter the year date on which the sound recording was produced and the name of the copyright owner of the sound recording"
                              />
                              <SelectField
                                label="Production Year"
                                name="production_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the production Year"
                              />
                              <FormField
                                label="CopyRighted To"
                                placeholder="CopyRighted To"
                                id="copyrighted_to"
                                name="copyrighted_to"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter copyrighted notice text.Enter the year date of first publication of the entire musical work and the name of the copyright owner.For derivative works or compilations,enter the date of publication of the work as a hole."
                              />
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                justifyContent="center"
                                //alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  mt={0.5}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    {"Publisher"} *
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Grid item lg={12} md={12} sm={12}>
                                    <Tooltip
                                      title="Enter the performing rights organization or mechanical collection society with which this publisher is affiliated.Eg:ASCAP,BMI,GEMA"
                                      arrow
                                      placement="right-end"
                                    >
                                      <FieldArray name={"publishers"}>
                                        {({ insert, remove, push }) => (
                                          <Box sx={{ width: "100%" }}>
                                            {Array.isArray(values.publishers) &&
                                              values.publishers.map(
                                                (item, index) => (
                                                  <Grid
                                                    display={"flex"}
                                                    key={`${item}-${index}`}
                                                  >
                                                    <Grid width={"100%"}>
                                                      <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        width={"100%"}
                                                      >
                                                        <Tooltip
                                                          title="Enter the performing rights organization or mechanical collection society with which this publisher is affiliated.Eg:ASCAP,BMI,GEMA"
                                                          arrow
                                                          placement="right-end"
                                                        >
                                                          <div>
                                                            <FastField
                                                              borderRadius={
                                                                "3px"
                                                              }
                                                              height={"30px"}
                                                              width={"100%"}
                                                              fullWidth={true}
                                                              as={MuiTextField}
                                                              variant="outlined"
                                                              placeholder={
                                                                "Name"
                                                              }
                                                              fieldheight="30px"
                                                              size="small"
                                                              name={`publishers[${index}].name`}
                                                              error={
                                                                Boolean(
                                                                  errors?.publishers &&
                                                                    errors
                                                                      ?.publishers?.[
                                                                      index
                                                                    ]
                                                                ) &&
                                                                Boolean(
                                                                  touched
                                                                    .publishers?.[
                                                                    index
                                                                  ]
                                                                )
                                                              }
                                                              helperText={
                                                                errors
                                                                  ?.publisher?.[
                                                                  index
                                                                ]
                                                              }
                                                              className="col-12"
                                                            />
                                                          </div>
                                                        </Tooltip>
                                                      </Grid>
                                                      {errors?.publisher &&
                                                      errors?.publisher[
                                                        index
                                                      ] ? (
                                                        <Grid
                                                          item
                                                          lg={4}
                                                          md={4}
                                                          sm={4}
                                                        >
                                                          &nbsp;
                                                          <ErrorMessage
                                                            name={`publishers[${index}]`}
                                                          />
                                                        </Grid>
                                                      ) : null}
                                                    </Grid>
                                                    <Grid
                                                      xs={4}
                                                      width={"100%"}
                                                      display={"flex"}
                                                      paddingLeft={"6px"}
                                                      paddingRight={"6px"}
                                                    >
                                                      <SelectFieldLabel
                                                        height={"30px"}
                                                        width={"100%"}
                                                        fullWidth={true}
                                                        name={`publishers[${index}].type`}
                                                        options={IPRSandPPL}
                                                        size="small"
                                                        placeholder="Select"
                                                        title="Enter IPRS and PPL field"
                                                      />
                                                    </Grid>
                                                    <Grid>
                                                      {values.publishers
                                                        ?.length !== 1 && (
                                                        <IconButton
                                                          onClick={() => {
                                                            remove(index);
                                                          }}
                                                          sx={{
                                                            // backgroundColor:
                                                            //   theme.palette
                                                            //     .closeIconBg
                                                            //     .backgroundColor,
                                                            borderRadius: "3px",
                                                            // color:
                                                            //   theme.palette
                                                            //     .secondarycolor,
                                                          }}
                                                        >
                                                          <DeleteIcon color="primary" />
                                                        </IconButton>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                )
                                              )}

                                            <Grid
                                              container
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  push({
                                                    type: "IPRS",
                                                    value: null,
                                                  })
                                                }
                                                sx={{ padding: 1 }}
                                              >
                                                <AddCircleIcon color="primary" />
                                              </IconButton>
                                            </Grid>
                                          </Box>
                                        )}
                                      </FieldArray>
                                    </Tooltip>
                                  </Grid>
                                  {errors.publisher && errors.publisher[0] ? (
                                    <Grid item lg={4} md={4} sm={4}>
                                      &nbsp;
                                      <ErrorMessage name={"publisher[0]"} />
                                    </Grid>
                                  ) : null}
                                  {/* <Grid item lg={4} md={4} sm={4}>
                                    &nbsp;
                                    <ErrorMessage name={"publisher[0]"} />
                                  </Grid> */}
                                </Grid>
                                <br />
                                <br />
                                {/* <SelectField
                                  label=""
                                  name="publisher[1]"
                                  options={IPRSandPPL}
                                  size="small"
                                  placeholder="Performing Rights"
                                  // errors={
                                  //   Boolean(
                                  //     errors.publisher && errors.publisher[1]
                                  //   ) && Boolean(touched.publisher)
                                  // }
                                  // helperText={
                                  //   errors.publisher && errors.publisher[1]
                                  // }
                                  title="Enter IPRS and PPL field"
                                /> */}
                                {/* <Grid item lg={4} md={4} sm={4} ml={3}>
                                  <ErrorMessage
                                    name={"publisher[0]" || "publisher[1]"}
                                  />
                                </Grid> */}
                              </Grid>

                              <FormField
                                label="Published By"
                                placeholder="Published By"
                                id="published_by"
                                name="published_by"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the published by field"
                              />
                              {values.ask_to_generate_an_isrc === "Yes" ? (
                                <Grid
                                  container
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    textAlign="right"
                                    px={1}
                                    mt={1}
                                  >
                                    <Typography color={theme.palette.text.form}>
                                      ISRC
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={7}
                                    md={7}
                                    sm={7}
                                    textAlign="left"
                                    px={1}
                                  >
                                    <Tooltip
                                      title="ISRC field present"
                                      arrow
                                      placement="right-end"
                                    >
                                      <FastField
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="ISRC"
                                        id={"isrc"}
                                        fieldheight="30px"
                                        // handleKeyPress={handleKeyPress}
                                        size="small"
                                        name={"isrc"}
                                        disabled={true}
                                        className="col-10"
                                      />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  container
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    textAlign="right"
                                    px={1}
                                    mt={1}
                                  >
                                    <Typography color={theme.palette.text.form}>
                                      ISRC *
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={7}
                                    md={7}
                                    sm={7}
                                    textAlign="left"
                                    px={1}
                                  >
                                    <Tooltip
                                      title="Enter valid ISRC(International Standard Recording Code)"
                                      arrow
                                      placement="right-end"
                                    >
                                      <FastField
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="ISRC"
                                        id={"isrc"}
                                        disabled={false}
                                        onPaste={(e) => e.preventDefault()}
                                        maxLength={12}
                                        fieldheight="30px"
                                        // handleKeyPress={handleKeyPress}
                                        size="small"
                                        name={"isrc"}
                                        error={
                                          Boolean(errors.isrc) &&
                                          Boolean(touched.isrc)
                                        }
                                        className="col-10"
                                        handleKeyPress={handleKeyPress}
                                      />
                                    </Tooltip>
                                    &nbsp;
                                    <ErrorMessage name={"isrc"} />
                                  </Grid>
                                </Grid>
                              )}
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                alignItems={"center"}
                                justifyContent="center"
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  px={1}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Ask To Generate An ISRC
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Field name="ask_to_generate_an_isrc">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        // value={askToGenerateISRC}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "ask_to_generate_an_isrc",
                                            event.target.value
                                          );

                                          if (event.target.value === "Yes") {
                                            setFieldValue("isrc", "");
                                          }
                                          // handleRadioChange(event);
                                          // field.onChange(event);
                                        }}
                                      >
                                        {AskToGenerateIsrc.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={<Radio />}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>

                              {props?.releasedata?.type_of_release ===
                                "video" && (
                                <Grid
                                  container
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    textAlign="right"
                                    px={1}
                                    mt={1}
                                  >
                                    <Typography color={theme.palette.text.form}>
                                      Audio ISRC *
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={7}
                                    md={7}
                                    sm={7}
                                    textAlign="left"
                                    px={1}
                                  >
                                    <Tooltip
                                      title="ISRC field present"
                                      arrow
                                      placement="right-end"
                                    >
                                      <FastField
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="ISRC"
                                        id={"audio_isrc"}
                                        maxLength={12}
                                        fieldheight="30px"
                                        handleKeyPress={handleKeyPress}
                                        onPaste={(e) => e.preventDefault()}
                                        size="small"
                                        name={"audio_isrc"}
                                        className="col-10"
                                        error={
                                          Boolean(errors.audio_isrc) &&
                                          Boolean(touched.audio_isrc)
                                        }
                                      />
                                    </Tooltip>
                                    <ErrorMessage name={"audio_isrc"} />
                                  </Grid>
                                </Grid>
                              )}

                              <SelectFieldObject
                                label="Genre"
                                name="genre"
                                options={props.genreList ?? []}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Choose the product's genre.Storefront genre categorization often occurs at the discretion of the music service and may differ slightly from the chosen genre."
                              />
                              {props.setSelectedGenre(values.genre)}

                              <SelectFieldObject
                                label="Subgenre"
                                name="sub_genre_name"
                                options={props.subgenrelist ?? []}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the Subgenre Type from Genre Type"
                              />
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  px={1}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Actor
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Grid
                                    item
                                    sm={
                                      returnAddIconForActorField(
                                        props.actorarray,
                                        inputvalueforactor
                                      )
                                        ? 11
                                        : 12
                                    }
                                  >
                                    <Tooltip
                                      title="Enter the Actor Name"
                                      arrow
                                      placement="right-end"
                                    >
                                      <Field
                                        name="primary_actor"
                                        size="small"
                                        multiple
                                        fieldheight="30px"
                                        component={AutoCompleteMultiSelect}
                                        options={
                                          props.actorarray
                                            ? props.actorarray
                                            : []
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option.actor_name === value.actor_name
                                        }
                                        onInputChange={(e, value) => {
                                          postRef.current = value;
                                          setinputvalueforactor(value);
                                        }}
                                        freeSolo={true}
                                        sx={{
                                          width: "-webkit-fill-available",
                                        }}
                                        inputValue={inputvalueforactor}
                                        margin={0}
                                        value={values?.primary_actor}
                                        getOptionLabel={(option) =>
                                          option.actor_name
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item.actor_id}>
                                            {item.actor_name}
                                          </li>
                                        )}
                                        textFieldProps={{
                                          margin: "normal",
                                          variant: "outlined",
                                          placeholder:
                                            values.primary_actor &&
                                            values.primary_actor.length !== 0
                                              ? null
                                              : "Actor",
                                        }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                  {returnAddIconForActorField(
                                    props.actorarray,
                                    inputvalueforactor
                                  ) && (
                                    <Grid item lg={1} md={1} sm={1}>
                                      {" "}
                                      <IconButton
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const trimmedInput =
                                            postRef.current.trim();

                                          setActorInput("");
                                          setinputvalueforactor("");

                                          if (trimmedInput)
                                            PostFunction(
                                              trimmedInput,
                                              setFieldValue,
                                              values,
                                              "primary_actor"
                                            );
                                        }}
                                      >
                                        <AddCircleIcon color="primary" />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  px={1}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Actress
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Grid
                                    item
                                    sm={
                                      returnAddIconForActressField(
                                        props.actressarray,
                                        inputvalueforactress
                                      )
                                        ? 11
                                        : 12
                                    }
                                  >
                                    <Tooltip
                                      title="Enter the Actress Name"
                                      arrow
                                      placement="right-end"
                                    >
                                      <Field
                                        name="primary_actress"
                                        size="small"
                                        multiple
                                        fieldheight="30px"
                                        component={AutoCompleteMultiSelect}
                                        inputValue={inputvalueforactress}
                                        options={
                                          props.actressarray
                                            ? props.actressarray
                                            : []
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option.actress_name ===
                                          value.actress_name
                                        }
                                        onInputChange={(e, value) => {
                                          postRef.current = value;
                                          setinputvalueforactress(value);
                                        }}
                                        ref={postRef}
                                        freeSolo={true}
                                        sx={{
                                          width: "-webkit-fill-available",
                                        }}
                                        margin={0}
                                        getOptionLabel={(option) =>
                                          option.actress_name
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item.actress_id}>
                                            {item.actress_name}
                                          </li>
                                        )}
                                        textFieldProps={{
                                          margin: "normal",
                                          variant: "outlined",
                                          placeholder:
                                            values.primary_actress &&
                                            values.primary_actress.length !== 0
                                              ? null
                                              : "Actress",
                                        }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                  {returnAddIconForActressField(
                                    props.actressarray,
                                    inputvalueforactress
                                  ) && (
                                    <Grid item lg={1} md={1} sm={1}>
                                      <IconButton
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const trimmedInput =
                                            postRef.current.trim();

                                          setinputvalueforactress("");

                                          if (trimmedInput)
                                            PostFunction(
                                              trimmedInput,
                                              setFieldValue,
                                              values,
                                              "primary_actress"
                                            );
                                        }}
                                      >
                                        <AddCircleIcon color="primary" />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  px={1}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Director
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Grid
                                    item
                                    sm={
                                      returnAddIconDirectorForField(
                                        props.directorarray,
                                        inputvalueforDirector
                                      )
                                        ? 11
                                        : 12
                                    }
                                  >
                                    <Tooltip
                                      title="Enter the Director Name"
                                      arrow
                                      placement="right-end"
                                    >
                                      <Field
                                        name="director"
                                        size="small"
                                        multiple
                                        fieldheight="30px"
                                        component={AutoCompleteMultiSelect}
                                        options={
                                          props.directorarray
                                            ? props.directorarray
                                            : []
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option.director_name ===
                                          value.director_name
                                        }
                                        ref={postRef}
                                        onInputChange={(e, value) => {
                                          postRef.current = value;
                                          setinputvalueforDirector(value);
                                        }}
                                        inputValue={inputvalueforDirector}
                                        freeSolo={true}
                                        sx={{
                                          width: "-webkit-fill-available",
                                        }}
                                        margin={0}
                                        getOptionLabel={(option) =>
                                          option.director_name
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item.director_id}>
                                            {item.director_name}
                                          </li>
                                        )}
                                        textFieldProps={{
                                          margin: "normal",
                                          variant: "outlined",
                                          placeholder:
                                            values.director &&
                                            values.director.length !== 0
                                              ? null
                                              : "Director",
                                        }}
                                      />
                                    </Tooltip>
                                  </Grid>

                                  {returnAddIconDirectorForField(
                                    props.directorarray,
                                    inputvalueforDirector
                                  ) && (
                                    <Grid item lg={1} md={1} sm={1}>
                                      <IconButton
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const trimmedInput =
                                            postRef.current.trim();
                                          setinputvalueforDirector("");
                                          if (trimmedInput)
                                            PostFunction(
                                              trimmedInput,
                                              setFieldValue,
                                              values,
                                              "director"
                                            );
                                        }}
                                      >
                                        <AddCircleIcon color="primary" />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              {/* <SelectField
                        label="Actress"
                        name="featuring_actress[0]"
                        options={props.actressarray ?? []}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                      />

                      <SelectField
                        label="Director"
                        name="director[0]"
                        options={props.directorarray ?? []}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                      /> */}

                              {/* <SelectFieldObject
                        label="Track Title Language"
                        name="track_title_language"
                        options={props.nameslist ?? []}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                        required={true}
                      />

                   

                      
                    
                      <SelectFieldObject
                        label="Lyrics Language"
                        name="lyrics_language"
                        options={props.nameslist ?? []}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                        required={true}
                      />
                      */}

                              {/* <FormField
                        label="Lyrics"
                        placeholder="Lyrics"
                        id="lyrics"
                        name="lyrics"
                        errors={errors}
                        touched={touched}
                      /> */}

                              <TextareaField
                                label="Lyrics"
                                placeholder="Lyrics"
                                id="lyrics"
                                name="lyrics"
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                title="You have indicated an audio language but have not entered any lyrics.Music services strongly recommend including complete lyrics on all tracks with vocal content to assist in discovery via lyric and voice searches.Some music services may also display lyrics on their platform"
                              />

                              <AutoCompleteFormLyrics
                                name="lyrics_language"
                                label="Lyrics Language"
                                placeholder="Lyrics Language"
                                options={props.nameslist}
                                errors={errors}
                                size="small"
                                touched={touched}
                                required={true}
                                values={values}
                                disabled={values.instrumental === "Yes"}
                                title="Choose the language in which the vocals on the track are sung.If there are no vocals or the vocal content does not contain words in an intelligable language,choose 'No Linguistic Content'"
                              />

                              <AutoCompleteFormLyrics
                                name="track_title_language"
                                label="Track Title Language"
                                placeholder="Track Title Language"
                                options={props.nameslist}
                                errors={errors}
                                size="small"
                                touched={touched}
                                required={true}
                                values={values}
                                disabled={values.instrumental === "Yes"}
                                title="Choose the language in which the vocals on the track are sung.If there are no vocals or the vocal content does not contain words in an intelligable language,choose 'No Linguistic Content'"
                              />
                              <FormField
                                label="Producer Catalogue Number"
                                placeholder="Producer Catalogue Number"
                                id="producer_catalouge_number"
                                name="producer_catalouge_number"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter producer catalogue number"
                                handleKeyPress={handleKeyPress}
                              />

                              <SelectFieldObject
                                label="Plan"
                                name="plan_id"
                                options={props.planList ?? []}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the plan name"
                              />
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                display="flex"
                                alignItems={"center"}
                                justifyContent="center"
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  px={1}
                                >
                                  <Typography color={theme.palette.text.form}>
                                    Parental Advisory
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  textAlign="left"
                                  px={1}
                                >
                                  <Field name="parental_advisory">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        onChange={(event) => {
                                          setFieldValue(
                                            "parental_advisory",
                                            event.target.value
                                          );
                                        }}
                                      >
                                        {yesNoArray.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={<Radio />}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                              {/* 
                <FormField
                  label="Title"
                  placeholder="Title"
                  id="title"
                  name="title"
                  errors={errors}
                  touched={touched}
                /> */}
                              {/* <br />
                      <CheckboxField
                        label="Ask to generate an isrc"
                        name="ask_to_generate_an_isrc"
                        value={values.ask_to_generate_an_isrc}
                        onChange={handleChange}
                      /> */}
                              {/* <br />
                <CheckboxField
                  label="Instrumental"
                  name="instrumental"
                  value={values.instrumental}
                  onChange={handleChange}
                />
                <br /> */}
                              {/* <FormField
                label="Secondary Sub Genre"
                placeholder="Secondary Sub Genre"
                id="seconday_sub_genre"
                name="seconday_sub_genre"
                errors={errors}
                touched={touched}
              /> */}
                              {/* <FormField
                                label="Version SubTitle"
                                placeholder="Version SubTitle"
                                id="version_subtitle"
                                name="version_subtitle"
                                errors={errors}
                                touched={touched}
                                title="Enter the version subtitle"
                              /> */}

                              <FieldArrayComponent2
                                name="version_subtitle"
                                label={"Version/Subtitle"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                title="Enter Version/Subtitle"
                              />
                              {/* <SelectField
                        label="Secondary Track Type"
                        placeholder="Select an option"
                        options={secondaryTrackTypes}
                        size="small"
                        name="secondary_track_type"
                        errors={errors}
                        touched={touched}
                      /> */}
                              <FormField
                                label="Preview Start Time"
                                placeholder="Enter time value (30, 60...)"
                                id="preview_start"
                                name="preview_start"
                                errors={errors}
                                handleKeyPress={handlePhoneKeyPress}
                                numeric={true}
                                touched={touched}
                                required={true}
                                title="Enter the preview start time"
                                onPaste={(e) => e.preventDefault()}
                              />
                              {/* <SelectField
                label="Preview Start"
                name="preview_start"
                options={yesNoArray}
                size="small"
                placeholder="Enter time value"
                errors={errors}
                touched={touched}
              /> */}
                              {/* <SelectField
                  label="Content Type"
                  name="content_type"
                  options={contentTypeArray}
                  size="small"
                  placeholder="Select an option"
                  errors={errors}
                  touched={touched}
                /> */}
                              {/* <SelectField
                        label="Track Title Languages"
                        name="track_title_language"
                        options={props.nameslist}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                      /> */}
                              {/* <SelectField
                        label="Lyrics Language"
                        name="lyrics_language"
                        options={languages_list}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                      /> */}
                              {/* <Grid item xl={4} textAlign="right">
                        <Typography color={theme.palette.text.form}>
                          {"Track Title Language"} *
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={7}
                        textAlign="left"
                        px={1}
                        // my={1}
                      >
                        <FastField
                          style={{ width: "100%" }}
                          name={"track_title_language"}
                        >
                          {({ field, meta }) => (
                            <Select
                              input={
                                <OutlinedInput
                                  {...inputProps}
                                  // sx={{
                                  //   height: "30px",
                                  // }}
                                />
                              }
                              MenuProps={menuProps}
                              {...field}
                              displayEmpty
                              size={"small"}
                              style={{ width: "100%", height: "30px" }}
                              error={meta.touched && meta.error ? true : false}
                            >
                              <MenuItem value="" disabled>
                                <span style={{ color: "#b7c4c2" }}>
                                  {"Select an option"}
                                </span>
                              </MenuItem>

                              {languages_list.map((item) => (
                                <MenuItem key={item} value={item.key}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FastField>

                        <ErrorMessage name={"lyrics_language"} />
                      </Grid> */}
                              {/* <Grid item xl={4} textAlign="right">
                        <Typography color={theme.palette.text.form}>
                          {"Lyrics Language"} *
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={7}
                        textAlign="left"
                        px={1}
                        // my={1}
                      >
                        <FastField
                          style={{ width: "100%" }}
                          name={"lyrics_language"}
                        >
                          {({ field, meta }) => (
                            <Select
                              input={
                                <OutlinedInput
                                  {...inputProps}
                                  // sx={{
                                  //   height: "30px",
                                  // }}
                                />
                              }
                              MenuProps={menuProps}
                              {...field}
                              displayEmpty
                              size={"small"}
                              style={{ width: "100%", height: "30px" }}
                              error={meta.touched && meta.error ? true : false}
                            >
                              <MenuItem value="" disabled>
                                <span style={{ color: "#b7c4c2" }}>
                                  {"Select an option"}
                                </span>
                              </MenuItem>

                              {languages_list.map((item) => (
                                <MenuItem key={item} value={item.key}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FastField>

                        <ErrorMessage name={"lyrics_language"} />
                      </Grid> */}
                              {/* <FormField
                        label="Views"
                        placeholder="Views"
                        id="views"
                        name="views"
                        errors={errors}
                        touched={touched}
                        handleKeyPress={handlePhoneKeyPress}
                      /> */}
                              {/* <SelectField
                        label="Primary Track Type"
                        name="primary_track_type"
                        options={contentTypeArray}
                        size="small"
                        placeholder="Select an option"
                        errors={errors}
                        touched={touched}
                        width="100%"
                      /> */}
                              {/* <FormField
                label="Secondary Genre"
                placeholder="Secondary Genre"
                id="secondary_genre"
                name="secondary_genre"
                errors={errors}
                touched={touched}
              /> */}
                            </Grid>
                          </Grid>
                        </Box>
                        <DialogActions className="m-1 my-4">
                          <Grid
                            container
                            item
                            lg={6}
                            md={8}
                            sm={8}
                            justifyContent="flex-end"
                            alignItems={"center"}
                          >
                            <Grid item lg={5} md={5} sm={5} mr={2}>
                              {props.edit ? (
                                <MuiButton
                                  loading={loading}
                                  type="submit"
                                  name="Submit"
                                  save
                                  width="100%"
                                />
                              ) : (
                                <MuiButton
                                  loading={loading}
                                  type="submit"
                                  save
                                  name="Add Tracks"
                                  width="100%"
                                />
                              )}
                            </Grid>

                            <Grid item lg={5} md={5} sm={5}>
                              {!props.edit ? (
                                <MuiButton
                                  name={"Cancel"}
                                  width="100%"
                                  onClick={() => {
                                    props.onClose();
                                  }}
                                  cancel
                                />
                              ) : (
                                <MuiButton
                                  type="reset"
                                  name="Reset"
                                  onClick={Formik.resetForm}
                                  width="100%"
                                  cancel
                                />
                              )}
                            </Grid>
                          </Grid>
                        </DialogActions>
                      </div>
                    )}
                  </LoadingIndicator>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      </Paper>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(TrackPost);

const FormField = ({
  label,
  placeholder,
  id,
  name,
  errors,
  touched,
  handleKeyPress,
  disabled,
  required,
  title,
  onPaste,
}) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right" px={1} mt={0.5}>
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        <Tooltip title={title} arrow placement="right-end">
          <FastField
            as={MuiTextField}
            variant="outlined"
            placeholder={placeholder}
            id={id}
            fieldheight="30px"
            handleKeyPress={handleKeyPress}
            size="small"
            name={name}
            error={Boolean(errors[name]) && Boolean(touched[name])}
            helperText={Boolean(touched[name]) && errors[name]}
            disabled={disabled}
            className="col-10"
            onPaste={onPaste}
          />
        </Tooltip>
        {/* <ErrorMessage name={name} /> */}
      </Grid>
    </Grid>
  );
};

const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container>
      <Grid item lg={3} md={3} sm={3} ml={10} mt={1}></Grid>
      <Grid item lg={6} md={6} sm={6}>
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={label}
          name={name}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

const SelectField = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
}) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right" mt={0.5}>
        {" "}
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        <FastField style={{ width: "100%" }} name={name}>
          {({ field, meta }) => (
            <Tooltip title={title} arrow placement="right-end">
              <Select
                input={
                  <OutlinedInput
                    {...inputProps}
                    // sx={{
                    //   height: "30px",
                    // }}
                  />
                }
                MenuProps={menuProps}
                {...field}
                displayEmpty
                size={size}
                style={{ width: "100%", height: "30px" }}
                error={meta.touched && meta.error ? true : false}
              >
                {placeholder && (
                  <MenuItem value="" disabled>
                    <span
                      style={{
                        color: "#b7c4c2",
                        opacity: 1,
                        textShadow: "0.3px 0.3px 0.3px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {placeholder}
                    </span>
                  </MenuItem>
                )}
                {options.map((item) => (
                  <MenuItem
                    style={{
                      maxWidth: 250,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
        </FastField>
        &nbsp;
        <ErrorMessage name={name} />
      </Grid>
    </Grid>
  );
};
const SelectFieldLabel = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
}) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid item xs={12} textAlign="left">
        <FastField style={{ width: "100%" }} name={name}>
          {({ field, meta }) => (
            <Tooltip title={title} arrow placement="right-end">
              <Select
                input={
                  <OutlinedInput
                    {...inputProps}
                    // sx={{
                    //   height: "30px",
                    // }}
                  />
                }
                MenuProps={menuProps}
                {...field}
                displayEmpty
                size={size}
                style={{ width: "100%", height: "30px" }}
                error={meta.touched && meta.error ? true : false}
              >
                {placeholder && (
                  <MenuItem value="" disabled>
                    <span
                      style={{
                        color: "#b7c4c2",
                        opacity: 1,
                        textShadow: "0.3px 0.3px 0.3px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {placeholder}
                    </span>
                  </MenuItem>
                )}
                {options.map((item) => (
                  <MenuItem
                    style={{
                      maxWidth: 250,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
        </FastField>
        &nbsp;
        <ErrorMessage name={name} />
      </Grid>
    </Grid>
  );
};
// Custom filter function
const customFilterOptionsartist = (options, { inputValue }) => {
  let minCharsToShowDropdown = 2;
  if (inputValue.length < minCharsToShowDropdown) {
    return []; // If less than minCharsToShowDropdown, hide the dropdown
  }

  // Filter options based on the entered value after 2-3 letters
  const filteredOptions = options.filter((option) =>
    option.artist_name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Sort the filtered options in ascending order
  return filteredOptions.sort((a, b) =>
    a.artist_name.localeCompare(b.artist_name)
  );
};

const customFilterOptionslyrics = (options, { inputValue }) => {
  let minCharsToShowDropdown = 1;
  if (inputValue.length < minCharsToShowDropdown) {
    return options; // Show all options if input is empty or too short
  }

  // Filter options based on the entered value
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Sort the filtered options in ascending order
  return filteredOptions.sort((a, b) => a.name.localeCompare(b.name));
};

const AutoCompleteForm = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    required,
    openProp,
    title,
  } = props;

  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right">
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        <Tooltip title={title} arrow placement="right-end">
          <FastField
            name={name}
            size="small"
            multiple
            component={AutoCompleteMultiSelect}
            openProp={openProp}
            fieldheight="30px"
            options={options ? options : []}
            filterOptions={customFilterOptionsartist}
            isOptionEqualToValue={(option, value) =>
              option.artist_name === value.artist_name
            }
            margin={0}
            getOptionLabel={(option) => option.artist_name}
            renderOption={(props, item) => (
              <li {...props} key={item.artist_id}>
                {item.artist_name}
              </li>
            )}
            textFieldProps={{
              margin: "normal",
              variant: "outlined",
              placeholder: placeholder,
            }}
            // error={Boolean(errors[name]) && Boolean(touched[name])}
            // helperText={Boolean(touched[name]) && errors[name]}
          />
        </Tooltip>
        {openProp && (
          <span className="px-1 ">
            <span>
              <ReportGmailerrorredIcon
                style={{ marginRight: 2, fontSize: 16 }}
              />
            </span>
            <span>Max 3 members can be added</span>
          </span>
        )}
      </Grid>
    </Grid>
  );
};

const TextareaField = ({
  label,
  placeholder,
  id,
  name,
  errors,
  touched,
  handleKeyPress,
  disabled,
  required,
  handleChange,
  values,
  title,
}) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right" px={1}>
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        {/* <FastField
          as={MuiTextField}
          variant="outlined"
          placeholder={placeholder}
          id={id}
          fieldheight="30px"
          handleKeyPress={handleKeyPress}
          size="small"
          name={name}
          error={Boolean(errors[name]) && Boolean(touched[name])}
          helperText={Boolean(touched[name]) && errors[name]}
          disabled={disabled}
          // className="col-10"
        /> */}
        <Tooltip title={title} arrow placement="right-end">
          <FastField
            className={
              touched.name && errors.name
                ? "ticket_desc_test_area_error scrollable"
                : "ticket_desc_test_area scrollable"
            }
            name={name}
            component="textarea"
            // value={values.name}
            onChange={handleChange}
            defaultValue={values.name}
            type="text"
            maxRows={5}
            minRows={5}
            style={{
              height: 150,
              padding: "10px",
              boxSizing: "border-box",
              color: `${theme.palette.text.primary}`,
            }}
            aria-label="maximum height"
            placeholder={placeholder}
          />
        </Tooltip>
        <Box px={1}>
          <ErrorMessage name={name} />
        </Box>

        {/* <ErrorMessage name={name} /> */}
      </Grid>
    </Grid>
  );
};

const AutoCompleteFormLyrics = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    required,
    values,
    disabled,
    title,
  } = props;

  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right">
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        <Tooltip title={title} arrow placement="right-end">
          <Field
            name={name}
            size="small"
            component={AutoCompleteMultiSelect}
            disableClearable
            fieldheight="30px"
            options={options ? options : []}
            filterOptions={customFilterOptionslyrics}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            margin={0}
            getOptionLabel={(option) => option.name}
            textFieldProps={{
              margin: "normal",
              variant: "outlined",
              placeholder: placeholder,
            }}
            disabled={disabled}
            // error={Boolean(errors[name]) && Boolean(touched[name])}
            // helperText={Boolean(touched[name]) && errors[name]}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const SelectFieldObject = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
}) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid item lg={3} md={3} sm={3} textAlign="right" mt={1}>
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
        <Field name={name}>
          {({ field, meta }) => (
            <Tooltip title={title} arrow placement="right-end">
              <Select
                input={<OutlinedInput {...inputProps} />}
                MenuProps={menuProps}
                value={field.value ? field.value.id : ""}
                onChange={(event) => {
                  const selectedOption = options.find(
                    (option) => option.id === event.target.value
                  );
                  field.onChange(selectedOption); // Update the hidden field with the selected object
                }}
                {...field}
                displayEmpty
                size={size}
                style={{ width: "100%" }}
                error={meta.touched && meta.error ? true : false}
              >
                {placeholder && (
                  <MenuItem value="" disabled style={{ overflow: "none" }}>
                    <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                  </MenuItem>
                )}
                {options &&
                  options.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      style={{
                        maxWidth: 250,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </Tooltip>
          )}
        </Field>
        &nbsp;
        <ErrorMessage name={name} />
      </Grid>
    </Grid>
  );
};

export const FieldArrayComponent = ({
  name,
  label,
  required,
  values,
  errors,
  touched,
  title,
  disabled,
}) => {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <Box sx={{ width: "100%" }}>
          {Array.isArray(values[name]) &&
            values[name].map((item, index) => (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                mt={1}
                container
                key={index}
                className="d-flex justify-content-center  align-items-center "
              >
                <Grid item lg={3} md={3} sm={3} mt={-5} textAlign="right">
                  {index === 0 && (
                    <Typography color={theme.palette.text.form}>
                      {label} {required && "*"}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
                  <Grid item className="d-flex">
                    <Tooltip title={title} arrow placement="right-end">
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder={
                          name == "author" && values.instrumental == "Yes"
                            ? "Disabled"
                            : "Enter here"
                        }
                        id={`${name}[${index}]`}
                        size="small"
                        name={`${name}[${index}]`}
                        disabled={disabled}
                        error={errors && Boolean(errors.hasOwnProperty(name))}
                      />
                    </Tooltip>
                    {values[name].length !== 1 && (
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon color="primary" />
                      </IconButton>
                    )}
                  </Grid>

                  <Grid container>
                    &nbsp;
                    <ErrorMessage name={name} />
                  </Grid>
                  {name == "author" &&
                    values.instrumental != "Yes" &&
                    !/^.+ .+$/.test(values[name][index]) && (
                      <Grid container>
                        <WarningIcon color="warning" />
                        First Name and Last Name Required
                      </Grid>
                    )}
                  {name != "author" && !/^.+ .+$/.test(values[name][index]) && (
                    <Grid container>
                      <WarningIcon color="warning" />
                      First Name and Last Name Required
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}

          {values[name] &&
            values[name][values[name].length - 1] &&
            values[name][values[name] && values[name].length - 1].length !==
              0 && (
              <Grid
                container
                lg={11}
                md={11}
                sm={11}
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                px={1}
              >
                <IconButton onClick={() => push("")}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              </Grid>
            )}
        </Box>
      )}
    </FieldArray>
  );
};

export const FieldArrayComponent2 = ({
  name,
  label,
  required,
  values,
  errors,
  touched,
  title,
  disabled,
}) => {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <Box sx={{ width: "100%" }}>
          {Array.isArray(values[name]) &&
            values[name].map((item, index) => (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                mt={1}
                container
                key={index}
                alignItems={"center"}
                justifyContent={"center"}
                // className="d-flex justify-content-center  align-items-center "
              >
                <Grid item lg={3} md={3} sm={3} textAlign="right">
                  {index === 0 && (
                    <Typography color={theme.palette.text.form}>
                      {label} {required && "*"}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
                  <Grid item className="d-flex">
                    <Tooltip title={title} arrow placement="right-end">
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder={"Enter Version/Subtitle"}
                        id={`${name}[${index}]`}
                        size="small"
                        name={`${name}[${index}]`}
                        disabled={disabled}
                        error={errors && Boolean(errors.hasOwnProperty(name))}
                      />
                    </Tooltip>
                    {values[name].length !== 1 && (
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon color="primary" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}

          {values[name] &&
            values[name][values[name].length - 1] &&
            values[name][values[name] && values[name].length - 1].length !==
              0 && (
              <Grid
                container
                lg={11}
                md={11}
                sm={11}
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                px={1}
              >
                <IconButton onClick={() => push("")}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              </Grid>
            )}
        </Box>
      )}
    </FieldArray>
  );
};
