import {
  Tooltip,
  Box,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, FastField, Field, Form, Formik, getIn } from "formik";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../components/muiButton/MuiButton";
import CloseIcon from "@mui/icons-material/Close";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useParams } from "react-router";
// import TerritoriesAction from "../../stores/territories/TerritoriesAction";
import TimeZoneAction from "../../stores/timezone/TimeZoneAction";
import environment from "environment";
import { theme } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import UpdateUserProfile from "./UpdateUserProfile";
import ResetProfilePassword from "../../components/resetProfilePassword/ResetProfilePassword";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import MailSubscription from "./MailSubscription";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import NotificationSubscription from "./NotificationSubscription";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ClientAction from "../../stores/client/ClientAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserProfileAction.REQUEST_USERPROFILE,
    UserProfileAction.REQUEST_MAILSUBSCIPTION,
    UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION,
    // TerritoriesAction.REQUEST_TERRITORIES,
    // TimeZoneAction.REQUEST_TIMEZONE,
  ]),
  userprofile: state.userprofile.userprofile || [],
  mail_subscription: state.mail_subsription.mail_subsription || [],
  notificationSubscription:
    state.notificationSubscription.notificationSubscription || [],

  // territories: state.territories.territories || {},
  // timezone: state.timezone.timezone || {},
});

const Profile = (props) => {
  const { isRequesting } = props;
  const { id } = useParams();

  const [url, setUrl] = useState(null);
  const [openPassword, setOpenPassword] = useState(false);
  const [openSubsription, setOpenSubsription] = useState(false);
  const [OpenNotificationSubsription, setOpenNotificationSubsription] =
    useState(false);

  const [miniImage, setMiniImage] = useState("");
  const [maxImage, setMaxImage] = useState(null);
  const [miniImageLoader, setMiniImageLoader] = useState(false);
  const [maxImageLoader, setMaxImageLoader] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);

  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  // const [loading, setLoading] = useState(false);
  // const [userprofileSubmit, setUserProfileSubmit] = useState(false);

  // let countryList = ["India", "America", "China"];
  // let timezoneList = ["Asia", "Kolkata"];

  function fetchUserprofile() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
      user_id: Id,
    };
    props.dispatch(UserProfileAction.requestUserprofile(params));
  }

  function fetchSubsriptionData() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
    };
    props.dispatch(UserProfileAction.requestMailSubsciption(params));
  }

  function fetchNotificationSubsriptionData() {
    let params = {
      current_page: "profile",
    };
    props.dispatch(UserProfileAction.requestNotificationSubsciption(params));
  }

  // function fetchTerritoriesData() {
  //   let params = {
  //     page_limit: "none",
  //   };
  //   props.dispatch(TerritoriesAction.requestTerritories(params));
  // }

  function fetchtimezone() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(TimeZoneAction.requestTimeZone(params));
  }
  useEffect(() => {
    fetchUserprofile();
    fetchSubsriptionData();
    fetchNotificationSubsriptionData();
  }, []);

  useEffect(() => {
    if (!props.isRequesting) {
      setMiniImage(() => {
        !props.isRequesting &&
          props?.userprofile?._source?.user_profile_url &&
          RetrieveMiniImageUrl(props?.userprofile?._source?.user_profile_url);
      });
    }
  }, [!props.isRequesting]);

  let countryList = [];

  const [openCoverPicUploader, setOpenCoverPicUploader] = useState(false);

  const handlFileUpload = (value, setFieldValue, event) => {
    setMaxImageLoader(true);
    let formData = new FormData();
    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(
      `${environment.api.baseApi}/profile_picture_upload?user_id=${props.userprofile._id}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + access_tocken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.status === "success") {
          setFieldValue("thumbnail_url", response.url);
          RetrieveMaxImageUrl(response.url);
        } else {
          setMaxImageLoader(false);
        }
      });
  };

  props.territories &&
    props.territories.territory_data &&
    Object.values(props.territories.territory_data).length > 0 &&
    Object.values(props.territories.territory_data).map(
      (continentList) =>
        continentList.length > 0 &&
        continentList.map((countryData) => {
          countryList.push(countryData.country_name);
        })
    );

  let timezonelist = [];
  props.timezone &&
    props.timezone.timezonedata &&
    Object.values(props.timezone.timezonedata).length > 0 &&
    Object.values(props.timezone.timezonedata).map((valuedata) => {
      timezonelist.push(valuedata.abbr);
    });

  function RetrieveMaxImageUrl(thumbnail_url) {
    setMaxImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    let userId = localStorage.getItem("userId");
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=${thumbnail_url}&user_id=${userId}&current_page=profile`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);
        setMaxImage(imageUrl);
        setMaxImageLoader(false);
      });
  }

  function RetrieveMiniImageUrl(thumbnail_url) {
    setMiniImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    let userId = localStorage.getItem("userId");
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=thumbnail/${thumbnail_url}&user_id=${userId}&current_page=profile`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);
        setMiniImage(imageUrl);
        setMiniImageLoader(false);
      });
  }

  useEffect(() => {
    if (openCoverPicUploader) {
      props.userprofile &&
        props.userprofile._source &&
        props.userprofile._source.user_profile_url &&
        RetrieveMaxImageUrl(props.userprofile._source.user_profile_url);
    }
  }, [openCoverPicUploader]);

  function SaveThumbnailUrl(values) {
    if (initial) {
      let obj = {
        user_profile_url: values.thumbnail_url,
      };
      props
        .dispatch(UserProfileAction.requestPutUserprofilePic(obj))
        .then(() => {
          props.dispatch(UserProfileAction.requestUserprofile());
          setOpenCoverPicUploader(false);
        });
    }
  }

  let initial = {};
  let formProps = {};
  const { _id, _source } = props?.userprofile;

  if (props?.userprofile) {
    Object.assign(
      initial,
      { id: _id },
      { account_manager: _source?.account_manager },
      { all_content_delivered: _source?.all_content_delivered },
      { business_unit: _source?.business_unit },
      { channel_manager: _source?.channel_manager },
      { city: _source?.city },
      { client_classification: _source?.client_classification },
      { client_name: _source?.client_name },
      { contact: _source?.contact },
      { contract_end_date: _source?.contract_end_date },
      { contract_signature_date: _source?.contract_signature_date },
      { contracting_a3_entity: _source?.contracting_a3_entity },
      { cost_centre: _source?.cost_centre },
      { country: _source?.country },
      { default_language: _source?.default_language },
      { default_time_zone: _source?.default_time_zone },
      { digital_marketing_manager: _source?.digital_marketing_manager },
      { enabled: _source?.enabled },
      { first_account_signatory: _source?.first_account_signatory },
      { first_name: _source?.first_name },
      { last_name: _source?.last_name },
      { main_email_address: _source?.main_email_address },
      { main_music_genre: _source?.main_music_genre },
      { phone_number: _source?.phone_number },
      { postal_address: _source?.postal_address },
      { postal_code: _source?.postal_code },
      { royalties_email_address: _source?.royalties_email_address },
      { type_of_client: _source?.type_of_client },
      {
        user_profile_url: _source?.user_profile_url,
      },
      { account_holder_name: _source?.bank_details?.account_holder_name },
      { account_number: _source?.bank_details?.account_number },
      { bank_name: _source?.bank_details?.bank_name },
      { bank_address: _source?.bank_details?.bank_address },
      { branch_name: _source?.bank_details?.branch_name },
      { gst_no: _source?.bank_details?.gst_no },
      { bank_gst_no_checkbox: _source?.bank_details?.bank_gst_no_checkbox },
      { pan_no: _source?.bank_details?.pan_no },
      { ifsc: _source?.bank_details?.ifsc }
    );
  }

  if (initial) {
    formProps = {
      type_of_client: initial.type_of_client,
      main_email_address: initial.main_email_address,
      royalties_email_address: initial.royalties_email_address,
      first_name: initial.first_name,
      last_name: initial.last_name,
      phone_number: initial.phone_number,
      postal_address: initial.postal_address,
      postal_code: initial.postal_code,
      city: initial.city,
      country: initial.country,
      default_time_zone: initial.default_time_zone,
      thumbnail_url: initial.user_profile_url,
      client_name: initial.client_name,
      account_holder_name: initial.account_holder_name,
      account_number: initial.account_number,
      bank_name: initial.bank_name,
      bank_address: initial.bank_address,
      branch_name: initial.branch_name,
      gst_no: initial.gst_no,
      bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
      pan_no: initial.pan_no,
      ifsc: initial.ifsc,
    };
  } else {
    formProps = {
      type_of_client: "",
      main_email_address: "",
      royalties_email_address: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      postal_address: "",
      postal_code: "",
      city: "",
      country: "",
      default_time_zone: "",
      account_holder_name: "",
      account_number: "",
      bank_name: "",
      bank_address: "",
      branch_name: "",
      gst_no: "",
      bank_gst_no_checkbox: true,
      pan_no: "",
      ifsc: "",
    };
  }

  initial = {
    id: initial.id,
    type_of_client: initial.type_of_client,
    main_email_address: initial.main_email_address,
    royalties_email_address: initial.royalties_email_address,
    first_name: initial.first_name,
    last_name: initial.last_name,
    phone_number: initial.phone_number,
    postal_address: initial.postal_address,
    postal_code: initial.postal_code,
    city: initial.city,
    country: initial.country,
    default_time_zone: initial.default_time_zone,
    thumbnail_url: initial.user_profile_url,
    client_name: initial.client_name,
    account_holder_name: initial.account_holder_name,
    account_number: initial.account_number,
    bank_name: initial.bank_name,
    bank_address: initial.bank_address,
    branch_name: initial.branch_name,
    gst_no: initial.gst_no,
    bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
    pan_no: initial.pan_no,

    ifsc: initial.ifsc,
  };

  const formRef = useRef(null);

  // useEffect(() => {
  //   if (userprofileSubmit) {
  //     formRef.current.submitForm();
  //   }
  // }, [userprofileSubmit]);

  // const updateSubscription = (boolean, method) => {
  //   let page_params = {
  //     current_page: "profile",
  //   };

  //   // setLoading(true);

  //   let post_obj = {};

  //   Object.assign(
  //     post_obj,
  //     method === "label" && {
  //       events: [{ event_name: "label", subscribed: !boolean }],
  //     },
  //     method === "release" && {
  //       events: [{ event_name: "release", subscribed: !boolean }],
  //     }
  //   );

  //   props.dispatch(
  //     UserProfileAction.requestPutMailSubsciption(post_obj, page_params)
  //   );
  // };

  function capitalizeWords(input) {
    return input.toUpperCase();
  }

  return (
    <Box sx={{ background: "#fff", paddingTop: 2 }}>
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <>
            <Formik
              initialValues={initial}
              enableReinitialize
              onSubmit={(values) => {
                setBankLoading(true);
                let Obj = {};
                let bank_details_object = {};

                Object.assign(
                  bank_details_object,
                  values.account_holder_name
                    ? {
                        account_holder_name: values.account_holder_name,
                      }
                    : { account_holder_name: null },

                  values.account_number
                    ? {
                        account_number: values.account_number,
                      }
                    : { account_number: null },

                  values.bank_name
                    ? {
                        bank_name: values.bank_name,
                      }
                    : { bank_name: null },

                  values.bank_address
                    ? {
                        bank_address: values.bank_address,
                      }
                    : { bank_address: null },

                  values.branch_name
                    ? {
                        branch_name: values.branch_name,
                      }
                    : { branch_name: null },

                  values.bank_gst_no_checkbox
                    ? {
                        gst_no: values.gst_no,
                      }
                    : { gst_no: null },

                  {
                    bank_gst_no_checkbox: values.bank_gst_no_checkbox,
                  },

                  !values.bank_gst_no_checkbox
                    ? {
                        pan_no: values.pan_no,
                      }
                    : { pan_no: null },

                  values.ifsc
                    ? {
                        ifsc: values.ifsc,
                      }
                    : { ifsc: null }
                );
                Object.assign(Obj, { bank_details: bank_details_object });

                props
                  .dispatch(
                    UserProfileAction.updateBankDetails(JSON.stringify(Obj))
                  )
                  .then(() => {
                    setBankLoading(false);
                  });
              }}
              innerRef={formRef}
            >
              {({
                errors,
                isValid,
                helperText,
                touched,
                dirty,
                handleChange,
                setFieldValue,
                values,
                name,
                options,
                placeholder,
              }) => (
                <Form style={{ width: "100%" }}>
                  <Grid container lg={12} sm={12} md={12} xl={12} pl={3}>
                    <Grid item lg={8} xl={8} md={8} sm={8}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Typography
                          component={"span"}
                          sx={{ fontSize: "24px" }}
                        >
                          {" "}
                          {values.client_name}{" "}
                          <span style={{ fontSize: "18px" }}>
                            ({values.first_name} {values.last_name})
                          </span>
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Client Type</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.type_of_client
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Main Email Address</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.main_email_address
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Royalties Email Address</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {" "}
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source
                                  .royalties_email_address
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Phone Number</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.phone_number
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Postal Address</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.postal_address
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Postal Code</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.postal_code
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>City</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.city
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Country / Region</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.country
                              : ""}
                          </Typography>
                          {/* <Field
                      name="country"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      value={values.country ? values.country : ""}
                      options={countryList ? countryList : []}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Please select...",
                      }}
                      error={
                        Boolean(errors.country) && Boolean(touched.country)
                      }
                      helperText={Boolean(touched.country) && errors.country}
                    /> */}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Default Time Zone</Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.default_time_zone
                              : ""}
                          </Typography>
                          {/* <Field
                      name="default_time_zone"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      value={
                        values.default_time_zone ? values.default_time_zone : ""
                      }
                      options={timezonelist ? timezonelist : []}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Please select...",
                      }}
                      error={
                        Boolean(errors.default_time_zone) &&
                        Boolean(touched.default_time_zone)
                      }
                      helperText={
                        Boolean(touched.default_time_zone) &&
                        errors.default_time_zone
                      }
                    /> */}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                        // pb={5}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Default Language</Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6}>
                          <Typography>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.default_language
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      {props.mail_subscription &&
                        props.mail_subscription._source && (
                          <>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={1}
                              pt={2}
                              alignItems="center"
                            >
                              <Grid item lg={5} md={5} sm={5}>
                                <Typography>Label Subscription</Typography>
                              </Grid>

                              <Grid item lg={6} md={6} sm={6}>
                                {props.mail_subscription &&
                                props.mail_subscription._source &&
                                props.mail_subscription._source.events &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                ) &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                )[0] &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                )[0].subscribed ? (
                                  <MuiButton
                                    name="Subscribed"
                                    burronBackground="green"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "label";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "label"
                                    //   );
                                    // }}
                                  />
                                ) : (
                                  <MuiButton
                                    name="Unsubscribed"
                                    burronBackground="red"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "label";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "label"
                                    //   );
                                    // }}
                                  />
                                )}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={1}
                              pt={2}
                              // pb={1}
                              alignItems="center"
                            >
                              <Grid item lg={5} md={5} sm={5}>
                                <Typography>Release Subscription</Typography>
                              </Grid>

                              <Grid item lg={6} md={6} sm={6}>
                                {props.mail_subscription &&
                                props.mail_subscription._source &&
                                props.mail_subscription._source.events &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                ) &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                )[0] &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                )[0].subscribed ? (
                                  <MuiButton
                                    name="Subscribed"
                                    burronBackground="green"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "release";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "release"
                                    //   );
                                    // }}
                                  />
                                ) : (
                                  <MuiButton
                                    name="Unsubscribed"
                                    burronBackground="red"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "release";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "release"
                                    //   );
                                    // }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}

                      {props.notificationSubscription &&
                        props.notificationSubscription &&
                        props.notificationSubscription.length !== 0 && (
                          <>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={1}
                              pt={2}
                              pb={5}
                              alignItems="center"
                            >
                              <Grid item lg={5} md={5} sm={5}>
                                <Typography>
                                  Notification Subscription
                                </Typography>
                              </Grid>

                              <Grid item lg={6} md={6} sm={6}>
                                {props.notificationSubscription &&
                                props.notificationSubscription
                                  .notification_subscribed ? (
                                  <MuiButton
                                    name="Subscribed"
                                    burronBackground="green"
                                  />
                                ) : (
                                  <MuiButton
                                    name="Unsubscribed"
                                    burronBackground="red"
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                    </Grid>

                    <Grid item lg={3} xl={3} md={3} sm={3}>
                      <Typography
                        component={"div"}
                        onClick={() => {
                          setOpenCoverPicUploader(true);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: { xl: "240px", lg: "240px" },
                          height: { xl: "192px", lg: "192px" },
                          cursor: "pointer",
                          backgroundColor: theme.palette.background.paper,
                          border: `1px dashed ${theme.palette.text.dark}`,
                        }}
                      >
                        <Field name="thumbnail_url">
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <>
                              {value &&
                              props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source.user_profile_url ? (
                                <>
                                  {miniImage ? (
                                    <img
                                      src={miniImage}
                                      alt="Preview"
                                      onError={() => {
                                        // Error handling code goes here
                                      }}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : miniImageLoader ? (
                                    <CircleLoader />
                                  ) : null}
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faFileArrowUp}
                                    style={{
                                      color: theme.palette.common.grey,
                                      width: "37px",
                                      height: "50px",
                                    }}
                                  ></FontAwesomeIcon>
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      color: theme.palette.common.grey,
                                      fontSize: "16px",
                                      mt: "15px",
                                    }}
                                  >
                                    Upload Profile
                                  </Typography>
                                </Box>
                              )}

                              <input
                                id="thumbnail-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  setMaxImageLoader(true);
                                  handlFileUpload(
                                    e.target.files[0],
                                    setFieldValue,
                                    e
                                  );
                                }}
                              />
                            </>
                          )}
                        </Field>
                      </Typography>
                      <UpdateUserProfile
                        open={openCoverPicUploader}
                        imgUrl={maxImage}
                        maxImageLoader={maxImageLoader}
                        onClose={() => {
                          setOpenCoverPicUploader(false);
                        }}
                        onSave={() => {
                          SaveThumbnailUrl(values);
                        }}
                        artists={values.artists}
                        title={values.title}
                      />
                      <ResetProfilePassword
                        open={openPassword}
                        onClose={() => {
                          setOpenPassword(false);
                        }}
                      />
                      <MailSubscription
                        edit={props.mail_subscription._source ? true : false}
                        open={openSubsription}
                        onClose={() => {
                          setOpenSubsription(false);
                        }}
                        mail_subscription={props.mail_subscription}
                      />

                      <NotificationSubscription
                        edit={
                          props.notificationSubscription &&
                          props.notificationSubscription.length !== 0
                            ? true
                            : false
                        }
                        open={OpenNotificationSubsription}
                        onClose={() => {
                          setOpenNotificationSubsription(false);
                        }}
                        notificationSubscription={
                          props.notificationSubscription
                        }
                      />
                    </Grid>
                  </Grid>
                  {page_permission &&
                    page_permission.length !== 0 &&
                    page_permission.length === 1 &&
                    page_permission[0] === "*" && (
                      <Box
                        className="mx-4 my-4"
                        width="50%"
                        sx={{
                          "&:hover": {
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        <Accordion
                          className="m-0 p-1"
                          style={{
                            border: `1px solid ${theme.palette.borderColor.textField}`,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="m-1 p-1"
                            style={{
                              backgroundColor: ` ${theme.palette.background.tab}`,
                            }}
                          >
                            <AccountBalanceIcon style={{ marginLeft: "3px" }} />
                            <Typography
                              style={{
                                fontWeight: "bold",
                                paddingTop: "3px",
                                paddingLeft: "10px",
                              }}
                            >
                              Bank Details
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="m-0 px-4 pt-4">
                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  Account Holder Name :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="Account Holder Name"
                                    arrow
                                    placement="right-end"
                                  >
                                    <Field
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="Account Holder Name"
                                      // disabled={props.edit ? true : false}
                                      id="account_holder_name"
                                      size="small"
                                      name="account_holder_name"
                                      error={
                                        Boolean(errors.account_holder_name) &&
                                        Boolean(touched.account_holder_name)
                                      }
                                      helperText={
                                        Boolean(touched.account_holder_name) &&
                                        errors.account_holder_name
                                      }
                                      className="col-10"
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const cursorPosition =
                                          e.target.selectionStart;
                                        const words = inputValue.split(" ");
                                        const formattedWords = words.map(
                                          (word, index) => {
                                            if (index === words.length - 1) {
                                              // The last word
                                              return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                              );
                                            } else {
                                              return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                              );
                                            }
                                          }
                                        );
                                        const beautifiedValue =
                                          formattedWords.join(" ");
                                        setFieldValue(
                                          "account_holder_name",
                                          beautifiedValue
                                        );
                                        // Restore cursor position
                                        e.target.setSelectionRange(
                                          cursorPosition,
                                          cursorPosition
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={`${"space-between"}`}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  Account Number :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    as={MuiTextField}
                                    className="col-10"
                                    variant="outlined"
                                    placeholder={"Account Number"}
                                    id={"account_number"}
                                    size="small"
                                    name={"account_number"}
                                    inputMode={"numeric"}
                                    // maxLength={13}
                                    handleKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    // className="mt-1"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  Bank Name :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="Bank Name"
                                    arrow
                                    placement="right-end"
                                  >
                                    <Field
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="Bank Name"
                                      // disabled={props.edit ? true : false}
                                      id="bank_name"
                                      size="small"
                                      name="bank_name"
                                      error={
                                        Boolean(errors.bank_name) &&
                                        Boolean(touched.bank_name)
                                      }
                                      helperText={
                                        Boolean(touched.bank_name) &&
                                        errors.bank_name
                                      }
                                      className="col-10"
                                      onChange={(e) => {
                                        const newValue = capitalizeWords(
                                          e.target.value
                                        );
                                        setFieldValue("bank_name", newValue);
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  Bank Address :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="Bank Address"
                                    arrow
                                    placement="right-end"
                                  >
                                    <Field
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="Bank Address"
                                      // disabled={props.edit ? true : false}
                                      id="bank_address"
                                      size="small"
                                      name="bank_address"
                                      error={
                                        Boolean(errors.bank_address) &&
                                        Boolean(touched.bank_address)
                                      }
                                      helperText={
                                        Boolean(touched.bank_address) &&
                                        errors.bank_address
                                      }
                                      className="col-10"
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        setFieldValue("bank_address", newValue);
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  Branch Name :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="Branch Name"
                                    arrow
                                    placement="right-end"
                                  >
                                    <Field
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="Branch Name"
                                      // disabled={props.edit ? true : false}
                                      id="branch_name"
                                      size="small"
                                      name="branch_name"
                                      error={
                                        Boolean(errors.branch_name) &&
                                        Boolean(touched.bank_name)
                                      }
                                      helperText={
                                        Boolean(touched.branch_name) &&
                                        errors.branch_name
                                      }
                                      className="col-10"
                                      onChange={(e) => {
                                        const newValue = capitalizeWords(
                                          e.target.value
                                        );
                                        setFieldValue("branch_name", newValue);
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  IFSC :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="IFSC"
                                    arrow
                                    placement="right-end"
                                  >
                                    <Field
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="IFSC"
                                      id="ifsc"
                                      size="small"
                                      name="ifsc"
                                      error={
                                        Boolean(errors.ifsc) &&
                                        Boolean(touched.ifsc)
                                      }
                                      helperText={
                                        Boolean(touched.ifsc) && errors.ifsc
                                      }
                                      className="col-10"
                                      onChange={(e) => {
                                        const newValue = capitalizeWords(
                                          e.target.value
                                        );
                                        const isAllowed =
                                          /^[A-Z0-9]{0,11}$/.test(newValue);
                                        if (isAllowed) {
                                          setFieldValue("ifsc", newValue);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  GST / PAN
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Tooltip
                                    title="GST / PAN"
                                    arrow
                                    placement="right-end"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values.bank_gst_no_checkbox}
                                        />
                                      }
                                      name="bank_gst_no_checkbox"
                                      value={values.bank_gst_no_checkbox}
                                      onChange={(event, value) => {
                                        setFieldValue("gst_no", "");
                                        setFieldValue("pan_no", "");
                                        setFieldValue(
                                          "bank_gst_no_checkbox",
                                          value
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>

                            {values.bank_gst_no_checkbox ? (
                              <Grid container mt={1}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid item lg={4} md={4} sm={4} mt={1}>
                                    GST :
                                  </Grid>
                                  <Grid item lg={8} md={8} sm={8}>
                                    <Tooltip
                                      title="GST"
                                      arrow
                                      placement="right-end"
                                    >
                                      <Field
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="GST"
                                        id="gst_no"
                                        size="small"
                                        name="gst_no"
                                        error={
                                          Boolean(errors.gst_no) &&
                                          Boolean(touched.gst_no)
                                        }
                                        helperText={
                                          Boolean(touched.gst_no) &&
                                          errors.gst_no
                                        }
                                        className="col-10"
                                        onChange={(e) => {
                                          const newValue = capitalizeWords(
                                            e.target.value
                                          );
                                          const isAllowed =
                                            /^[A-Z0-9]{0,15}$/.test(newValue);
                                          if (isAllowed) {
                                            setFieldValue("gst_no", newValue);
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container mt={1}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid item lg={4} md={4} sm={4} mt={1}>
                                    PAN :
                                  </Grid>
                                  <Grid item lg={8} md={8} sm={8}>
                                    <Tooltip
                                      title="PAN"
                                      arrow
                                      placement="right-end"
                                    >
                                      <Field
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="PAN"
                                        id="pan_no"
                                        size="small"
                                        name="pan_no"
                                        error={
                                          Boolean(errors.pan_no) &&
                                          Boolean(touched.pan_no)
                                        }
                                        helperText={
                                          Boolean(touched.pan_no) &&
                                          errors.pan_no
                                        }
                                        className="col-10"
                                        onChange={(e) => {
                                          const newValue = capitalizeWords(
                                            e.target.value
                                          );
                                          const isAllowed =
                                            /^[A-Z0-9]{0,10}$/.test(newValue);
                                          if (isAllowed) {
                                            setFieldValue("pan_no", newValue);
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              display="flex"
                              justifyContent="flex-end"
                              gap={2}
                              mt={4}
                            >
                              <Grid item sm={3} xs={12}>
                                <MuiButton
                                  type="submit"
                                  name="Submit"
                                  loading={bankLoading}
                                  width="100%"
                                />
                              </Grid>
                              <Grid item sm={3} xs={12}>
                                <MuiButton
                                  name={"Reset"}
                                  cancel
                                  width="100%"
                                  onClick={() => {
                                    setFieldValue("account_holder_name", "");
                                    setFieldValue("account_number", "");
                                    setFieldValue("bank_name", "");
                                    setFieldValue("ifsc", "");
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                </Form>
              )}
            </Formik>
            <Grid
              item
              textAlign={"center"}
              container
              gap={1}
              alignItems={"center"}
              justifyContent="center"
            >
              <MuiButton
                title={
                  props.mail_subscription._source
                    ? "Edit Mail Subscription"
                    : "Subscribe to mails"
                }
                onClick={(event) => {
                  setOpenSubsription(true);
                }}
                name={
                  props.mail_subscription._source
                    ? "Edit mail Subscription"
                    : "Subscribe to mails"
                }
              ></MuiButton>

              <MuiButton
                title={
                  props.notificationSubscription &&
                  props.notificationSubscription.length !== 0
                    ? "Edit Notification Subscription"
                    : "Subscribe to Notifications"
                }
                onClick={(event) => {
                  setOpenNotificationSubsription(true);
                }}
                name={
                  props.notificationSubscription &&
                  props.notificationSubscription.length !== 0
                    ? "Edit Notification Subscription"
                    : "Subscribe to Notifications"
                }
              ></MuiButton>

              <MuiButton
                title="Reset Password"
                onClick={(event) => {
                  setOpenPassword(true);
                }}
                name="Reset Password"
              ></MuiButton>
            </Grid>
          </>
        }
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(Profile);
