import {
  Box,
  Tab,
  Grid,
  Typography,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import environment from "environment";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DeliveredReleasesTracks from "./DeliveredReleasesTracks";
import ReleasesDeliveryTab from "./ReleasesDeliveryTab";
import DeliveredReleaseHistory from "./DeliveredReleaseHistory";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import UpdatePlayLinks from "./TracksPlayLinks";
import TrackAction from "../../stores/track/TrackAction";
import LaunchIcon from "@mui/icons-material/Launch";
import MainPlayLink from "./MainPlayLink";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import CloseIcon from "@mui/icons-material/Close";
import MuiButton from "../../components/muiButton/MuiButton";
import MusicLabelsChangeRequestReleaseAction from "../../stores/musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID,
    MusicLabelsChangeRequestReleaseAction.REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE,
  ]),
  finalReleaseById: state.finalReleaseById.finalReleaseById || {},
  musiclabelRequestChangeRelease:
    state.musiclabelRequestChangeRelease.musiclabelRequestChangeRelease || [],
  musiclabelFilterRequestChangeRelease:
    state.musiclabelFilterRequestChangeRelease
      .musiclabelFilterRequestChangeRelease || [],
});

const DeliveredReleaseDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [imgUrl, setImgUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [value, setValue] = React.useState("1");
  const [trackId, settrackId] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [requestChangeDialog, setRequestChangeDialog] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const [tabvalue, settabvalue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    newValue !== "4" && settabvalue(false);
    newValue !== "4" && props.dispatch(TrackAction.requestTrackByIdClear());
  };

  // {
  //   value === "1" && props.dispatch(TrackAction.requestTrackByIdClear());
  // }

  function fetchDeliveredReleaseById(releaseId, params) {
    const callBack = (res) => {
      if (res?.found === true) {
        setImgUrl(() => {
          res?._source?.thumbnail_url &&
            RetrieveImageUrl(res?._source?.thumbnail_url);
        });
      }
    };
    props.dispatch(
      ReleasesAction.requestFinalReleaseById(releaseId, params, callBack)
    );
  }

  function fetchMusicLabelsChangeRequestRelease(params) {
    const MusicLabelsChangeRequestCallBack = (res) => {
      setLoading(true);
      if (res?.status === "success") {
        setLoading(false);
        navigate("/changeRequestForm", {
          state: {
            requestId: res?.id,
            releaseId: res?.data?.release_id,
          },
        });
        localStorage.setItem("change_request_id", res?.id);
      } else {
        setLoading(false);
      }
    };
    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestPostMusicLabelsChangeRequestRelease(
        params,
        MusicLabelsChangeRequestCallBack
      )
    );
  }

  function fetchChangeRequestReleases(params) {
    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestMusicLabelsFilterChangeRequestRelease(
        params
      )
    );
  }

  useEffect(() => {
    const param = {
      current_page: "delivered_release_details",
    };

    fetchDeliveredReleaseById(location?.state?.releaseId, param);

    const cr_filter = [];

    cr_filter.push(
      `["change_request_status.keyword","must","","terms",["under_review" , "draft"]]`
    );

    const filterParams = {
      page_limit: `none`,
      order_by: '[["modified_on", "desc"]]',
      current_page: "delivered",
      filters: `[${cr_filter}]`,
    };

    fetchChangeRequestReleases(filterParams);
  }, []);

  const releaseIdsWithChangeRequests = [];

  if (props?.musiclabelFilterRequestChangeRelease?.hits?.hits) {
    props?.musiclabelFilterRequestChangeRelease?.hits?.hits?.map((change) => {
      releaseIdsWithChangeRequests?.push({
        release_id: change?._source?.release_id,
        request_id: change?._id,
      });
    });
  }

  const returnOptions = () => {
    let options = [
      { id: "Request Change", name: "Request Change" },
      { id: "Change Pending", name: "Change Pending" },
    ];

    if (
      releaseIdsWithChangeRequests.some(
        (item) => item.release_id === location?.state?.releaseId
      )
    ) {
      options = options?.filter((option) => {
        return option.name !== "Request Change";
      });
    } else {
      options = options?.filter((option) => {
        return option.name !== "Change Pending";
      });
    }
    return options;
  };

  function RetrieveImageUrl(thumbnail_url) {
    setImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=delivered_release_details&path=thumbnail/${thumbnail_url}&release_id=${location?.state?.releaseId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
      });
  }

  function convertHyphenatedString(string) {
    let hypenatedString =
      string?.replace(/\s+/g, (match, offset, string) => {
        // If the previous character is a hyphen or the next character is a hyphen, don't replace the space
        if (
          string[offset - 1] === "-" ||
          string[offset + match.length] === "-"
        ) {
          return ""; // Remove the space if it's around a hyphen
        }
        return "-"; // Otherwise, replace the space with a hyphen
      }) || "";
    return hypenatedString;
  }
  const handleSelectChange = (e, v) => {
    console.log("v", e, v, e.target);
    setType(e.target.value);
    if (e.target.value === "Request Change") {
      setRequestChangeDialog(true);
    } else if (e.target.value === "Change Pending") {
      const found = releaseIdsWithChangeRequests.find(
        (item) => item.release_id === location?.state?.releaseId
      );

      navigate("/changeRequestForm", {
        state: {
          requestId: found?.request_id,
          releaseId: found?.release_id,
        },
      });
      localStorage.setItem("change_request_id", found?.request_id);
    }
  };
  const handleCloseDialog = () => {
    setRequestChangeDialog(false);
  };

  const handleOkRequestChangeDialog = () => {
    let postObj = {
      release_id: props?.finalReleaseById?._id,
      // title: props?.finalReleaseById?._source?.title,
    };
    postObj && fetchMusicLabelsChangeRequestRelease(postObj);
  };
  return (
    <Box p={1}>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        item
        lg={2}
        md={2}
        sm={2}
        xs={2}
      >
        <IconButton>
          <ArrowCircleLeftIcon
            style={{
              height: "40px",
              width: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/delivered");
            }}
          />
        </IconButton>
        {/* <SelectField
          value={type}
          name="Select Type"
          label="Select Type"
          options={returnOptions()}
          size="small"
          handleSelectChange={handleSelectChange}
        /> */}
      </Grid>
      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
        // className="bg-warning"
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <Grid item>
            <h2>{formatName(`Delivered`)}</h2>
          </Grid>
        </Grid>
      </Grid>
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <div style={{ marginBottom: "20px" }}>
            <Box className="d-flex">
              <Grid
                item
                sx={{
                  width: {
                    lg: "273px",
                    md: "200px",
                    sm: "150px",
                  },
                  height: {
                    lg: "250px",
                    md: "180px",
                    sm: "130px",
                  },
                  // border: `1px solid ${theme.palette.borderColor.imageBox}`,
                  // backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 0,
                  p: 0,
                }}
              >
                {imgUrl ? (
                  <Box
                    sx={{
                      width: {
                        lg: "273px",
                        md: "200px",
                        sm: "150px",
                      },
                      height: {
                        lg: "250px",
                        md: "180px",
                        sm: "130px",
                      },
                      backgroundColor: theme.palette.background.imageBox,
                    }}
                  >
                    <img
                      src={imgUrl}
                      alt="Cover Picture"
                      onError={() => {
                        // Error handling code goes here
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        // objectFit: "cover",
                      }}
                    />
                  </Box>
                ) : imageLoader ? (
                  <CircleLoader />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowUp}
                      style={{
                        color: theme.palette.common.grey,
                        width: "37px",
                        height: "50px",
                      }}
                    ></FontAwesomeIcon>
                    <Typography
                      component={"span"}
                      sx={{
                        color: theme.palette.common.grey,
                        fontSize: "16px",
                        mt: "15px",
                      }}
                    ></Typography>
                  </Box>
                )}
              </Grid>

              <Grid container item lg={12} md={12} sm={12} ml={3} gap={2}>
                <Grid container item lg={12} md={12} sm={12}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <h2>{props.finalReleaseById?._source?.title}</h2>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box>
                      <Typography component={"span"}>A3 Tunes</Typography>
                      <a
                        href={`${
                          environment.api.a3TunesApi
                        }/album/${convertHyphenatedString(
                          props.finalReleaseById?._source?.title
                        )}`}
                        target="_blank"
                        rel={"noreferrer"}
                        style={{ textDecoration: "none", marginLeft: 5 }}
                      >
                        <LaunchIcon
                          style={{
                            color: "#2a5e6c",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </a>
                    </Box>

                    <Box sx={{ marginLeft: 5 }}>
                      <Typography component={"span"}>Stores</Typography>
                      <a
                        href={`${
                          environment.api.a3TunesApi
                        }/albumlink/${convertHyphenatedString(
                          props.finalReleaseById?._source?.title
                        )}`}
                        target="_blank"
                        rel={"noreferrer"}
                        style={{ textDecoration: "none", marginLeft: 5 }}
                      >
                        <LaunchIcon
                          style={{
                            color: "#2a5e6c",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </a>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item lg={5.5} md={8} sm={12}>
                  {[
                    {
                      label: "Title",
                      value: props.finalReleaseById?._source?.title,
                    },
                    {
                      label: "Version",
                      value:
                        props.finalReleaseById?._source &&
                        props.finalReleaseById?._source["music/album"] &&
                        Array.isArray(
                          props.finalReleaseById?._source["music/album"]
                        ) &&
                        props.finalReleaseById?._source["music/album"]
                          ?.length !== 0
                          ? props.finalReleaseById?._source["music/album"].join(
                              ","
                            )
                          : "",
                    },
                    {
                      label: "Label",
                      value: props.finalReleaseById?._source?.label.label_name,
                    },
                    { label: "Parent Label", value: null },
                    {
                      label: "UPC",
                      value: props.finalReleaseById?._source?.UPC_EAN,
                    },
                    {
                      label: "Catalog no.",
                      value:
                        props.finalReleaseById?._source
                          ?.producer_catalogue_number,
                    },
                    {
                      label: "Genre",
                      value: `${props.finalReleaseById?._source?.genre_type.genre_name} > ${props.finalReleaseById?._source?.sub_genre_type.subgenre_name}`,
                    },
                    { label: "Compilation", value: null },
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      component={"p"}
                      width="100%"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold" }}
                        width="50%"
                      >
                        {item.label} :
                      </Typography>
                      <Typography component={"span"} width="50%">
                        {item.value}
                      </Typography>
                    </Typography>
                  ))}
                </Grid>
                <Grid item lg={5.5} md={8} sm={12}>
                  {[
                    {
                      label: "Release Date",
                      value: props.finalReleaseById?._source?.main_release_date,
                    },
                    {
                      label: "Original Release Date",
                      value:
                        props.finalReleaseById?._source?.physical_release_date?.slice(
                          0,
                          10
                        ),
                    },
                    {
                      label: "Pre-Order Date",
                      value:
                        props.finalReleaseById?._source?.pre_order_release_date,
                    },
                    {
                      label: "Price Tier",
                      value: props.finalReleaseById?._source?.plan_id.plan_name,
                    },
                    {
                      label: "C Line",
                      value: `${
                        props.finalReleaseById?._source?.copyrighted_to
                          ? "©"
                          : ""
                      } ${props.finalReleaseById?._source?.copyright_year} ${
                        props.finalReleaseById?._source?.copyrighted_to
                      }`,
                    },
                    {
                      label: "P Line",
                      value: `${
                        props.finalReleaseById?._source?.published_by ? "Ⓟ" : ""
                      } ${
                        props.finalReleaseById?._source
                          ?.phonographic_copyright_year
                      } ${props.finalReleaseById?._source?.published_by}`,
                    },
                    { label: "PDF Booklet", value: null },
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      component={"p"}
                      width="100%"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold" }}
                        width="50%"
                      >
                        {item.label} :
                      </Typography>
                      <Typography component={"span"} width="50%">
                        {item.value}
                      </Typography>
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <br />

            <TabContext value={value}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.tab,
                  boxShadow: "0 3px 6px 0px #c6bcbc33",
                }}
              >
                <TabList
                  onChange={handleChange}
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },
                    "& .MuiTab-root": {
                      position: "relative",
                      zIndex: 100,
                      border: 0,
                      height: "54px",
                      borderRadius: 0,
                      backgroundColor: theme.palette.background.tab,
                      textTransform: "none",
                      color: "#2a5e6c",
                      "&.Mui-selected": {
                        borderRadius: "0 0 15px 0",
                        border: `1px solid #6c64640a`,
                        boxShadow: theme.shadows,
                        zIndex: 2,
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                      },
                    },
                  }}
                >
                  <Tab label="Tracks" value="1" />
                  <Tab label="Stores" value="2" />
                  <Tab label="History" value="3" />
                  {tabvalue && <Tab label="Play Links" value={`4`} />}
                </TabList>
              </Box>

              <Box
                className="scrollable"
                sx={{ height: `auto`, width: "100%" }}
              >
                <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
                  <DeliveredReleasesTracks
                    releaseId={location?.state?.releaseId}
                    setValue={() => {
                      setValue("4");
                    }}
                    settrackId={settrackId}
                    settabvalue={settabvalue}
                  />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
                  <ReleasesDeliveryTab
                    releaseId={location?.state?.releaseId}
                    releaseData={props.finalReleaseById}
                  />
                </TabPanel>
                <TabPanel value="3" sx={{ padding: 0, margin: 0 }}>
                  <DeliveredReleaseHistory
                    releaseId={location?.state?.releaseId}
                  />
                </TabPanel>

                <TabPanel value="4" sx={{ padding: 0, margin: 0 }}>
                  <MainPlayLink
                    releaseId={location?.state?.releaseId}
                    releaseData={props.finalReleaseById}
                    trackId={trackId}
                  />
                </TabPanel>
              </Box>
            </TabContext>
          </div>
        }
      </LoadingIndicator>
      {/* <Dialog
        open={requestChangeDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            paddingTop: 2,

            paddingRightt: 2,
            paddingLeft: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={handleCloseDialog}
            sx={{
              justifyContent: "flex-end",
              "&:hover": {
                backgroundColor: "transparent", // Prevents shade on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: "20px" }}>Request Change?</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingLeft: 5,
            paddingRightt: 2,
            height: 250,
          }}
        >
          <Typography
            sx={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              paddingY: "35px",
            }}
          >
            Click OK to create a change request and begin making changes. Once
            your Change Request is submitted, it will be approved by our quality
            control team and applied directly to the original product. Once the
            changes have been applied, update messages will be sent to all
            applicable music services.
          </Typography>
          <Grid
            sx={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <MuiButton name={"Cancel"} onClick={handleCloseDialog} />
            <MuiButton
              loading={loading}
              name={"OK"}
              onClick={handleOkRequestChangeDialog}
            />
          </Grid>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default connect(mapStateToProps)(DeliveredReleaseDetails);
// const SelectField = ({
//   label,
//   name,
//   options,
//   value,
//   size,
//   placeholder,
//   handleSelectChange,
// }) => {
//   return (
//     <Select
//       input={<OutlinedInput {...inputProps} />}
//       MenuProps={menuProps}
//       value={value}
//       displayEmpty
//       size={size}
//       style={{ width: "150px" }}
//       onChange={handleSelectChange}
//     >
//       {/* {placeholder && (
//         <MenuItem value="" disabled>
//           <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
//         </MenuItem>
//       )} */}
//       {options.map((item) => (
//         <MenuItem key={item} value={item}>
//           {item}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// };
