import BaseReducer from "../../utilities/BaseReducer";
import MusicLabelsChangeRequestTrackAction from "./MusicLabelsChangeRequestTrackAction";

export default class musicLabelsChangeRequestTrackReducer extends BaseReducer {
  initialState = {
    musiclabelRequestChangeTrack: [],
  };
  [MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      musiclabelRequestChangeTrack: action.payload,
    };
  }
}
