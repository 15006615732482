import ActionUtility from "../../utilities/ActionUtility";
import MusicLabelsChangeRequestReleaseEffect from "./MusicLabelsChangeRequestReleaseEffect";

export default class MusicLabelsChangeRequestReleaseAction {
  // MusicLabelsChangeRequestReleaseAction action with an function declaration
  static REQUEST_MUSICLABELSCHANGEREQUESTRELEASE =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE";
  static REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED";

  static REQUEST_PUT_MUSICLABELSCHANGEREQUESTRELEASE =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTRELEASE";
  static REQUEST_PUT_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED";

  static REQUEST_POST_MUSICLABELSCHANGEREQUESTRELEASE =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTRELEASE";
  static REQUEST_POST_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED";

  static REQUEST_DELETE_MUSICLABELSCHANGEREQUESTRELEASE =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTRELEASE";
  static REQUEST_DELETE_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED";

  static REQUEST_CHANGE_REQUEST_RELEASE_BY_ID =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID";
  static REQUEST_CHANGE_REQUEST_RELEASE_BY_ID_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID_FINISHED";

  // MusicLabelsChangeRequestReleaseAction action with an function declaration
  static REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE";
  static REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED =
    "MusicLabelsChangeRequestReleaseAction.REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED";

  // METHODS
  static requestMusicLabelsChangeRequestRelease(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE,
        MusicLabelsChangeRequestReleaseEffect.requestMusicLabelsChangeRequestRelease,
        params,
        callBack
      );
    };
  }

  static requestMusicLabelsFilterChangeRequestRelease(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_FILTER_MUSICLABELSCHANGEREQUESTRELEASE,
        MusicLabelsChangeRequestReleaseEffect.requestMusicLabelsFilterChangeRequestRelease,
        params,
        callBack
      );
    };
  }

  static requestPutMusicLabelsChangeRequestRelease(data, id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTRELEASE,
        MusicLabelsChangeRequestReleaseEffect.requestPutMusicLabelsChangeRequestRelease,
        data,
        id,
        params,
        callBack
      );
    };
  }

  static requestPostMusicLabelsChangeRequestRelease(data, callBack, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTRELEASE,
        MusicLabelsChangeRequestReleaseEffect.requestPostMusicLabelsChangeRequestRelease,
        data,
        callBack,
        params
      );
    };
  }

  static requestMusicLabelsChangeRequestReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID,
        MusicLabelsChangeRequestReleaseEffect.requestMusicLabelsChangeRequestReleaseById,
        id,
        params,
        callBack
      );
    };
  }

  static requestDeleteMusicLabelsChangeRequestRelease(id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTRELEASE,
        MusicLabelsChangeRequestReleaseEffect.requestDeleteMusicLabelsChangeRequestRelease,
        id,
        callback
      );
    };
  }
}
