import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import RequestingReducer from "./requesting/RequestingReducer";
import ErrorReducer from "./error/ErrorReducer";
import ToastsReducer from "./toasts/ToastsReducer";
import AuthReducer from "./Auth/AuthReducer";
import UserReducer from "./user/UserReducer";
import UserByIdReducer from "./user/UserByIdReducer";
import UserFilterReducer from "./user/UserFilterReducer";
import StatusReduser from "./status/StatusReduser";
import StatusFilterReduser from "./status/StatusFilterReduser";
import LabelReuser from "./label/LabelReuser";
import LabelFilterReduser from "./label/LabelFilterReduser";
import AssetsReduser from "./assets/AssetsReduser";
import AssetsFilterReduser from "./assets/AssetsFilterReduser";
import ArtistReduser from "./artist/ArtistReduser";
import ArtistFilterReduser from "./artist/ArtistFilterReduser";
import TrackReduser from "./track/TrackReduser";
import TrackFilterReduser from "./track/TrackFilterReduser";
import ClientReduser from "./client/ClientReduser";
import ClientFilterReduser from "./client/ClientFilterReduser";
import GenreReduser from "./genre/GenreReduser";
import GenreFilterReduser from "./genre/GenreFilterReduser";
import StreamPlatformReduser from "./streamPlatform/StreamPlatformReduser";
import StreamPlatformFilterReduser from "./streamPlatform/StreamPlatformFilterReduser";
import SubGenreReducer from "./subgenre/SubGenreReducer";
import SubGenreFilterReducer from "./subgenre/SubGenreFilterReducer";
import ReleasesReducer from "./releases/ReleasesReducer";
import ReleasesFilterReducer from "./releases/ReleasesFilterReducer";
import ReleaseByIdReducer from "./releases/ReleaseByIdReducer";
import PlanReduser from "./plan/PlanReduser";
import PlanFilterReduser from "./plan/PlanFilterReduser";
import TerritoriesReduser from "./territories/TerritoriesReduser";
import UserProfileReducer from "./userprofile/UserProfileReducer";
import TimeZoneReduser from "./timezone/TimeZoneReducer";
import LanguageReduser from "./language/LanguageReduser";
import UserResourceReducer from "./userResource/UserResourceReducer";
import UserResourceFilterReducer from "./userResource/UserResourceFilterReducer";
import ResourceReduser from "./resource/ResourceReduser";
import NotificationReducer from "./notifications/NotificationReducer";
import NotificationsByIdReducer from "./notifications/NotificationsByIdReducer";
import TicketsReduser from "./tickets/TicketsReducer";
import FinalReleasesReducer from "./releases/FinalReleasesReducer";
import TopTrackReduser from "./topTrackReport/TopTrackReduser";
import TopReleaseReduser from "./topReleaseReport/TopReleaseReduser";
import TopStoreReduser from "./topStoresReport/TopStoreReduser";
import ReleaseStatusCountReduser from "./releaseStatusCountReport/ReleaseStatusCountReduser";
import PagePermissionReduser from "./pagePermission/PagePermissionReduser.js";
import ReleasesShadowTableReduser from "./releasesShadowTable/ReleasesShadowTableReduser";
import ReleasesShadowTableIdReduser from "./releasesShadowTable/ReleasesShadowTableIdReduser";
import ActorReducer from "./actor/ActorReducer";
import ActorFilterReducer from "./actor/ActorFilterReducer";
import ActressReducer from "./actress/ActressReducer";
import ActressFilterReducer from "./actress/ActressFilterReducer";
import DirectorReducer from "./director/DirectorReducer";
import DirectorFilterReducer from "./director/DirectorFilterReducer";
import TopArtistReducer from "./topArtistReport/TopArtistReducer";
import TopArtistFilterReducer from "./topArtistReport/TopArtistFilterReducer";
import FinalReleaseByIdReducer from "./releases/FinalReleaseByIdReducer";
import TopLabelReducer from "./topLabelReport/TopLabelReducer";
import TopLabelFilterReducer from "./topLabelReport/TopLabelFilterReducer";
import BlogsReducer from "./blogs/BlogsReducer";
import BlogsFilterReducer from "./blogs/BlogsFilterReducer";
import TopTerritoriesFilterReducer from "./TopTerritories/TopTerritoriesFilterReducer";
import TopTerritoriesReducer from "./TopTerritories/TopTerritoriesReducer";
import TopLanguagesReduser from "./topLanguages/TopLanguagesReduser";
import TopLanguagesFilterReduser from "./topLanguages/TopLanguagesFilterReduser";
import DraftReleaseReduser from "./releases/DraftReleaseReduser";
import TopComposerReducer from "./topComposer/TopComposerReducer";
import TopComposerFilterReducer from "./topComposer/TopComposerFilterReducer";
import TopAlbumReducer from "./topAlbum/TopAlbumReducer";
import TopAlbumFilterReducer from "./topAlbum/TopAlbumFilterReducer";
import TopLyricistReducer from "./topLyricist/TopLyricistReducer";
import TopLyricistFilterReducer from "./topLyricist/TopLyricistFilterReducer";
import TrackByIdReduser from "./track/TrackByIdReduser";
import MailSubscriptionReduser from "./userprofile/MailSubscriptionReduser";
import TopFiveDeliveredReleasesReduser from "./releases/TopFiveDeliveredReleasesReduser";
import NotificationSubscriptionReduser from "./userprofile/NotificationSubscriptionReduser";
import NotificationFilterReducer from "./notifications/NotificationFilterReducer";
import MigrateTracksReducer from "./migrateTracks/MigrateTracksReducer";
import ClientRevenueSharePolicyReduser from "./clientRevenueSharingPolicy/ClientRevenueSharePolicyReducer";
import RevenueReportReducer from "./revenueReport/RevenueReportReducer";
import TrackRevenueReducer from "./trackRevenue/TrackRevenueReducer";
import TopRetailerReduser from "./topStoresReport/TopRetailerReduser";
import TopTerritoryReduser from "./TopTerritories/TopTerritoryReduser";
import AudioStreamsReduser from "./topStoresReport/AudioStreamsReduser";
import ExistingTracksReducer from "./releases/ExistingTracksReducer.js";
import ExistingTrackReduser from "./track/ExistingTrackReduser.js";
import RevenueReportFilterReducer from "./revenueReport/RevenueReportFilterReducer.js";
import NetRevenueByMonthReducer from "./netRevenueReportByMonth/NetRevenueByMonthReducer.js";
import A3ReportsArtistReducer from "./reportsA3/A3ReportsArtistReducer.js";
import A3ReportsStorewiseReducer from "./reportsA3/A3ReportsStorewiseReducer.js";
import A3ReportsReleaseReducer from "./reportsA3/A3ReportsReleaseReducer.js";
import A3ReportsMonthwiseReducer from "./reportsA3/A3ReportsMonthwiseReducer.js";
import A3ReportsLabelReducer from "./reportsA3/A3ReportsLabelReducer.js";
import A3ReportsCountrywiseReducer from "./reportsA3/A3ReportsCountrywiseReducer.js";
import A3LabelRevenueReportReducer from "./reportsA3/A3LabelRevenueReportReducer.js";
import TrackwiseRevenueReduser from "./netRevenueReportByMonth/TrackwiseRevenueReduser";
import ArtistwiseRevenueReduser from "./netRevenueReportByMonth/ArtistwiseRevenueReduser";
import ReleasewiseRevenueReduser from "./netRevenueReportByMonth/ReleasewiseRevenueReduser";
import RevenueReportExportReducer from "./revenueReport/RevenueReportExportReducer";
import NetRevenueByMonthFilterReducer from "./netRevenueReportByMonth/NetRevenueByMonthFilterReducer.js";
import YTRevenueReportReducer from "./ytRevenueReport/YTRevenueReportReducer.js";
import YTRevenueReportExportReducer from "./ytRevenueReport/YTRevenueReportExportReducer.js";
import YTNetRevenueByMonthReducer from "./monthwiseRevenueYT/YTNetRevenueByMonthReducer.js";
import YTNetRevenueByMonthFilterReducer from "./monthwiseRevenueYT/YTNetRevenueByMonthFilterReducer.js";
import RevenueClaimReducer from "./revenueClaim/RevenueClaimReducer.js";
import ClientRevenueBalanceReducer from "./revenueClaim/ClientRevenueBalanceReducer.js";
import TrackwiseRevenueSummaryReducer from "./trackRevenue/TrackwiseRevenueSummaryReducer.js";
import RevenueClaimFilterReducer from "./revenueClaim/RevenueClaimFilterReducer.js";
import TrackStatusCountReduser from "./track/TrackStatusCountReduser.js";
import FilterRevenueReportReducer from "./revenueReport/FilterRevenueReportReducer";
import TopDevicesReduser from "./topDevicesRport/TopDevicesReduser.js";
import TopTrackWithDateFilterReducer from "./topTrackReport/TopTrackWithDateFilterReducer.js";
import musicLabelsChangeRequestReleaseReducer from "./musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseReducer.js";
import musicLabelsChangeRequestTrackReducer from "./musicLabelsChangeRequestTrack/MusicLabelsChangeRequestTrackReducer.js";
import musicLabelsChangeRequestTrackShadowReducer from "./musicLabelsChangeRequestTrackShadow/MusicLabelsChangeRequestTrackShadowReducer.js";
import musicLabelsChangeRequestReleaseShadowReducer from "./musicLabelsChangeRequestReleaseShadow/MusicLabelsChangeRequestReleaseShadowReducer.js";
import RequestReleaseByIdReducer from "./musicLabelsChangeRequestRelease/RequestReleaseByIdReducer.js";
import musicLabelsFilterChangeRequestReleaseReducer from "./musicLabelsChangeRequestRelease/musicLabelsFilterChangeRequestReleaseReducer.js";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => {
  const reducerMap = {
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    router: connectRouter(history),
    toasts: new ToastsReducer().reducer,
    auth: new AuthReducer().reducer,
    user: new UserReducer().reducer,
    userById: new UserByIdReducer().reducer,
    filterUser: new UserFilterReducer().reducer,

    //status
    status: new StatusReduser().reducer,
    statusFilter: new StatusFilterReduser().reducer,

    //label
    label: new LabelReuser().reducer,
    labelFilter: new LabelFilterReduser().reducer,

    // assets
    assets: new AssetsReduser().reducer,
    assetsFilter: new AssetsFilterReduser().reducer,

    // artist

    artist: new ArtistReduser().reducer,
    artistFilter: new ArtistFilterReduser().reducer,

    // track

    track: new TrackReduser().reducer,
    trackFilter: new TrackFilterReduser().reducer,
    trackById: new TrackByIdReduser().reducer,
    existingTracks: new ExistingTrackReduser().reducer,

    //client
    client: new ClientReduser().reducer,
    clientFilter: new ClientFilterReduser().reducer,

    //genre
    genre: new GenreReduser().reducer,
    genreFilter: new GenreFilterReduser().reducer,

    //stream_platform
    stream_platform: new StreamPlatformReduser().reducer,
    stream_platform_filter: new StreamPlatformFilterReduser().reducer,

    //subgenre
    subgenre: new SubGenreReducer().reducer,
    subgenreFilter: new SubGenreFilterReducer().reducer,

    // Releases
    release: new ReleasesReducer().reducer,
    draftRelease: new DraftReleaseReduser().reducer,
    releaseFilter: new ReleasesFilterReducer().reducer,
    releaseById: new ReleaseByIdReducer().reducer,
    releaseById2: new ExistingTracksReducer().reducer,
    finalReleases: new FinalReleasesReducer().reducer,
    finalReleaseById: new FinalReleaseByIdReducer().reducer,

    //musiclabelRequestChangeRelease
    musiclabelRequestChangeRelease: new musicLabelsChangeRequestReleaseReducer()
      .reducer,
    musiclabelFilterRequestChangeRelease:
      new musicLabelsFilterChangeRequestReleaseReducer().reducer,

    musiclabelRequestChangeTrack: new musicLabelsChangeRequestTrackReducer()
      .reducer,
    musiclabelRequestChangeTrackShadow:
      new musicLabelsChangeRequestTrackShadowReducer().reducer,
    musiclabelRequestChangeReleaseShadow:
      new musicLabelsChangeRequestReleaseShadowReducer().reducer,
    changeRequestReleaseById: new RequestReleaseByIdReducer().reducer,

    //userprofile
    userprofile: new UserProfileReducer().reducer,
    mail_subsription: new MailSubscriptionReduser().reducer,
    notificationSubscription: new NotificationSubscriptionReduser().reducer,

    //plan
    plan: new PlanReduser().reducer,
    planFilter: new PlanFilterReduser().reducer,

    //territories
    territories: new TerritoriesReduser().reducer,

    //timezone
    timezone: new TimeZoneReduser().reducer,

    //language
    language: new LanguageReduser().reducer,

    userResource: new UserResourceReducer().reducer,
    userResourceFilter: new UserResourceFilterReducer().reducer,

    resource: new ResourceReduser().reducer,

    //ORM notifications
    Notifications: new NotificationReducer().reducer,
    NotificationsById: new NotificationsByIdReducer().reducer,
    NotificationsFilter: new NotificationFilterReducer().reducer,

    //tICKETS
    tickets: new TicketsReduser().reducer,

    //Top Track reports
    topTrack: new TopTrackReduser().reducer,
    topTrack_with_date_filter: new TopTrackWithDateFilterReducer().reducer,

    //Top Releases reports
    topRelease: new TopReleaseReduser().reducer,

    //Top Stores reports
    topStore: new TopStoreReduser().reducer,

    //Top topRetailer reports
    topRetailer: new TopRetailerReduser().reducer,

    //Release Status Count
    releaseStatusCount: new ReleaseStatusCountReduser().reducer,
    // Track Status Count
    trackStatusCount: new TrackStatusCountReduser().reducer,

    //Top Releases reports
    pagePermission: new PagePermissionReduser().reducer,
    top5Delivered: new TopFiveDeliveredReleasesReduser().reducer,

    //releasesShadowTable
    releasesShadowTable: new ReleasesShadowTableReduser().reducer,
    releasesShadowTableById: new ReleasesShadowTableIdReduser().reducer,

    //actor
    actor: new ActorReducer().reducer,
    actorFilter: new ActorFilterReducer().reducer,

    //actress
    actress: new ActressReducer().reducer,
    actressFilter: new ActressFilterReducer().reducer,

    //director
    director: new DirectorReducer().reducer,
    directorFilter: new DirectorFilterReducer().reducer,

    //TopArtistReport
    topArtist: new TopArtistReducer().reducer,
    topArtistFilter: new TopArtistFilterReducer().reducer,

    //TopLabelReport
    topLabel: new TopLabelReducer().reducer,
    topLabelFilter: new TopLabelFilterReducer().reducer,

    // blogs
    blogs: new BlogsReducer().reducer,
    blogsFilter: new BlogsFilterReducer().reducer,

    // top twrritories
    top_territories_report: new TopTerritoriesReducer().reducer,
    top_territories_report_filter: new TopTerritoriesFilterReducer().reducer,
    top_territory: new TopTerritoryReduser().reducer,
    audioStreams: new AudioStreamsReduser().reducer,

    // top languges
    top_languages_report: new TopLanguagesReduser().reducer,
    top_territories_report_filter: new TopLanguagesFilterReduser().reducer,

    //top composer
    top_composers_report: new TopComposerReducer().reducer,
    top_composer_report_filter: new TopComposerFilterReducer().reducer,

    // top_device_type_report
    top_device_type_report: new TopDevicesReduser().reducer,

    //top album
    top_albums_report: new TopAlbumReducer().reducer,
    top_albums_report_filter: new TopAlbumFilterReducer().reducer,

    //top lyricist
    top_lyricists_report: new TopLyricistReducer().reducer,
    top_lyricists_report_filter: new TopLyricistFilterReducer().reducer,

    //Migrate Tracks
    spotifyMigrateTrack: new MigrateTracksReducer().reducer,

    //revenue section
    clientRevenueSharePolicy: new ClientRevenueSharePolicyReduser().reducer,
    trackRevenue: new TrackRevenueReducer().reducer,
    revenueReport: new RevenueReportReducer().reducer,
    revenueReportFilter: new RevenueReportFilterReducer().reducer,
    filterRevenueReport: new FilterRevenueReportReducer().reducer,
    revenueReportExport: new RevenueReportExportReducer().reducer,

    netRevenueByMonth: new NetRevenueByMonthReducer().reducer,
    netRevenueByMonthFilter: new NetRevenueByMonthFilterReducer().reducer,

    clientArtistReport: new A3ReportsArtistReducer().reducer,
    storewiseRevenueReport: new A3ReportsStorewiseReducer().reducer,
    clientReleaseReport: new A3ReportsReleaseReducer().reducer,
    monthwiseRevenueReport: new A3ReportsMonthwiseReducer().reducer,
    clientLabelReport: new A3ReportsLabelReducer().reducer,
    countrywiseRevenueReport: new A3ReportsCountrywiseReducer().reducer,
    labelRevenueReport: new A3LabelRevenueReportReducer().reducer,

    trackwiseRevenue: new TrackwiseRevenueReduser().reducer,
    releasewiseRevenue: new ReleasewiseRevenueReduser().reducer,
    artistwiseRevenue: new ArtistwiseRevenueReduser().reducer,
    trackwiseRevenueSummary: new TrackwiseRevenueSummaryReducer().reducer,

    // YT revenue reports
    ytRevenueReport: new YTRevenueReportReducer().reducer,
    ytRevenueReportExport: new YTRevenueReportExportReducer().reducer,

    ytNetRevenueByMonth: new YTNetRevenueByMonthReducer().reducer,
    ytNetRevenueByMonthFilter: new YTNetRevenueByMonthFilterReducer().reducer,

    revenueClaim: new RevenueClaimReducer().reducer,
    revenueClaimFilter: new RevenueClaimFilterReducer().reducer,
    clientRevenueBalance: new ClientRevenueBalanceReducer().reducer,
  };

  return combineReducers(reducerMap);
};
