import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import MusicLabelsChangeRequestReleaseShadowModel from "./musicLabelsChangeRequestReleaseShadowModel/MusicLabelsChangeRequestReleaseShadowGetModel";
import MusicLabelsChangeRequestReleaseShadowByIdModel from "./musicLabelsChangeRequestReleaseShadowModel/MusicLabelsChangeRequestReleaseShadowByIdModel";
export default class MusicLabelsChangeRequestTrackEffect {
  // Get method for the
  static async requestMusicLabelsChangeRequestReleaseShadow(params, callBack) {
    const endpoint = environment.api.musiclabelRequestChangeReleaseShadow;
    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestReleaseShadowModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestMusicLabelsChangeRequestReleaseShadowById(id, params) {
    const endpoint =
      environment.api.musiclabelRequestChangeReleaseShadow + "/" + id;
    return EffectUtility.getToModel(
      MusicLabelsChangeRequestReleaseShadowByIdModel,
      endpoint,
      params
    );
  }
}
