import React, { useEffect, useState } from "react";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import MusicLabelsChangeRequestReleaseAction from "../../stores/musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseAction";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
} from "@mui/material";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import Rettrive from "../releases/Rettrive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import ThreeDotsPopper from "../../components/threeDotsPopper/ThreeDotsPopper";
import BlockIcon from "@mui/icons-material/Block";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_FINAL_RELEASES,
    MusicLabelsChangeRequestReleaseAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE,
  ]),
  finalReleases: state.finalReleases.finalReleases || [],
  musiclabelRequestChangeRelease:
    state.musiclabelRequestChangeRelease.musiclabelRequestChangeRelease || [],
  pagination:
    state.musiclabelRequestChangeRelease.musiclabelRequestChangeRelease || [],
});
function ChangeRequests(props) {
  const [page_limit] = useState(15);
  const { isRequesting } = props;
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [changeRequestId, setChangeRequestId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const filter = [];

    filter.push(
      `["change_request_status.keyword","must_not","","match","approved"]`
    );

    const params = {
      page_limit: page_limit,
      order_by: '[["created_on", "desc"]]',
      filters: `[${filter}]`,
    };

    fetchChangeRequests(params);
  }, []);

  function fetchChangeRequests(params) {
    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestRelease(
        params
      )
    );
  }

  console.log("CHANGES_REQUESTS", props);
  const rows = [];

  if (
    props.musiclabelRequestChangeRelease &&
    props.musiclabelRequestChangeRelease.hits
  ) {
    props.musiclabelRequestChangeRelease.hits.hits &&
      props.musiclabelRequestChangeRelease.hits.hits.length > 0 &&
      props.musiclabelRequestChangeRelease.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          release_id: item._source.release_id,
          type_of_release:
            item._source.type_of_release && item._source.type_of_release
              ? item._source.type_of_release.charAt(0).toUpperCase() +
                item._source.type_of_release.slice(1)
              : "",
          thumbnail_url: item._source.thumbnail_url,
          title: item._source.title,
          artists: item._source.artists,
          label: item._source.label,
          digital_release_date: item._source.digital_release_date,
          no_of_tracks: item._source.tracks?.length,
          UPC_EAN: item._source.UPC_EAN,
          copyrighted_to: item._source.copyrighted_to,
          featuring_artists: item._source.featuring_artists,
          main_release_date: item._source.main_release_date,
          pre_order_release_date: item._source.pre_order_release_date,
          production_year: item._source.production_year,
          published_by: item._source.published_by,
          producer_catalogue_number: item._source.producer_catalogue_number,
          genre_type: item._source.genre_type,
          sub_genre_type: item._source.sub_genre_type,
          format: item._source.format,
          territory: item._source.territory,
          created_on: item._source.created_on,
          status: item._source.change_request_status,
        });
      });
  }

  const returnoptions = (params) => {
    let options = [
      {
        option_name: "Edit Draft",
        page: "changeRequests",
        action: () => {
          localStorage.setItem("change_request_id", params.row.id);
          navigate("/changeRequestForm", {
            state: {
              requestId: params.row.id,
              releaseId: params?.row?.release_id,
            },
          });
        },
      },
      {
        option_name: "Delete Draft",
        page: "changeRequests",
        action: () => {
          if (params?.row?.id) {
            setChangeRequestId(params.row.id);
          }
          setConfirmDialog(true);
        },
      },
    ];

    if (params?.row?.status === "under_review") {
      options = options.filter((item) => item.option_name !== "Edit Draft");
    }

    return options;
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div>
              <Box
                className="image-container"
                style={{
                  borderRadius: 5,

                  display: "grid",
                  placeItems: "center",
                  overflow: "hidden",
                }}
              >
                <Rettrive
                  thumbnail_url={params?.row?.thumbnail_url}
                  params={{ id: params?.row?.release_id }}
                  current_page={"delivered"}
                />
              </Box>
            </div>

            <div
              style={{
                marginLeft: 5,
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      cellClassName: "status",
    },
    {
      width: 80,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
      hide: true,
    },

    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
      hide: true,
    },
    {
      width: 200,
      flex: 1,
      field: "created_on",
      headerName: "Created On",
      renderCell: (params) => {
        return formatDate(params.row.created_on);
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release Date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
      hide: true,
    },
    {
      width: 130,
      flex: 1,
      field: "no_of_tracks",
      headerName: "No of Track",
      hide: true,
    },
    {
      width: 130,
      flex: 1,
      field: "UPC_EAN",
      headerName: "UPC",

      hide: true,
    },
    {
      width: 50,
      field: "more",
      type: "actions",
      headerName: (
        <MoreVertIcon
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        />
      ),
      textAlign: "center",
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => {
        return params?.row?.status === "draft" ||
          params?.row?.status === "under_review" ||
          params?.row?.status === "rejected" ? (
          <div
          // className="bg-info"
          >
            <ThreeDotsPopper
              options={returnoptions(params)}
              releaseId={params.row.id}
            >
              <MoreVertIcon
                style={{
                  color: "#2a5e6c",
                  height: "20px",
                  width: "20px",
                  // backgroundColor: "gray",
                }}
              />
            </ThreeDotsPopper>
          </div>
        ) : (
          <div
          // className="bg-success"
          >
            <BlockIcon
              style={{
                color: "#2a5e6c",
                height: "20px",
                width: "20px",
                // backgroundColor: "gray",
              }}
            />
          </div>
        );
      },
    },
  ];

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  function DeleteChangeRequest(changeRequest_id) {
    props
      .dispatch(
        MusicLabelsChangeRequestReleaseAction.requestDeleteMusicLabelsChangeRequestRelease(
          changeRequest_id
        )
      )
      .then(() => {
        const filter = [];

        filter.push(
          `["change_request_status.keyword","must_not","","match","approved"]`
        );

        const params = {
          page_limit: page_limit,
          order_by: '[["created_on", "desc"]]',
          filters: `[${filter}]`,
        };

        fetchChangeRequests(params);
      });
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];

    filter.push(
      `["change_request_status.keyword","must_not","","match","approved"]`
    );

    let params = {
      page_limit: page_limit,
      order_by: '[["created_on", "desc"]]',
      page_number: `${value}` - 1,
      filters: `[${filter}]`,
    };

    fetchChangeRequests(params);
  };

  return (
    <Box>
      <Grid
        container
        item
        lg={12}
        xl={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          height: "-webkit-fill-available",
        }}
      >
        <LoadingIndicator isActive={isRequesting}>
          {isRequesting ? (
            <Box>
              <LoadingBgImage />
            </Box>
          ) : (
            <DataGridFunction
              columns={columns}
              rows={rows}
              isRequesting={isRequesting}
              rowHeight={80}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          )}
        </LoadingIndicator>
      </Grid>

      <Dialog open={Boolean(confirmDialog)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete this Change Request?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              DeleteChangeRequest(changeRequestId);
              setConfirmDialog(false);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default connect(mapStateToProps)(ChangeRequests);
