import ActionUtility from "../../utilities/ActionUtility";
import MusicLabelsChangeRequestReleaseShadowEffect from "./MusicLabelsChangeRequestReleaseShadowEffect";

export default class MusicLabelsChangeRequestReleaseShadowAction {
  // MusicLabelsChangeRequestTrackAction action with an function declaration
  static REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW =
    "MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW";
  static REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_FINISHED =
    "MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_FINISHED";

  static REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_BY_ID =
    "MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_BY_ID";
  static REQUEST_REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_BY_ID_FINISHED =
    "MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_BY_ID_FINISHED";

  // Organization GET function
  static requestMusicLabelsChangeRequestReleaseShadow(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW,
        MusicLabelsChangeRequestReleaseShadowEffect.requestMusicLabelsChangeRequestReleaseShadow,
        params,
        callBack
      );
    };
  }

  static requestMusicLabelsChangeRequestReleaseShadowById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestReleaseShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_SHADOW_BY_ID,
        MusicLabelsChangeRequestReleaseShadowEffect.requestMusicLabelsChangeRequestReleaseShadowById,
        id,
        params
      );
    };
  }
}
