import ActionUtility from "../../utilities/ActionUtility";
import MusicLabelsChangeRequestTrackShadowEffect from "./MusicLabelsChangeRequestTrackShadowEffect";

export default class MusicLabelsChangeRequestTrackShadowAction {
  // MusicLabelsChangeRequestTrackAction action with an function declaration
  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW";
  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_FINISHED";

  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_BY_ID =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_BY_ID";
  static REQUEST_REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_BY_ID_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_BY_ID_FINISHED";

  // Organization GET function
  static requestMusicLabelsChangeRequestTrackShadow(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW,
        MusicLabelsChangeRequestTrackShadowEffect.requestMusicLabelsChangeRequestTrackShadow,
        params,
        callBack
      );
    };
  }

  static requestMusicLabelsChangeRequestTrackShadowById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_BY_ID,
        MusicLabelsChangeRequestTrackShadowEffect.requestMusicLabelsChangeRequestTrackShadowById,
        id,
        params
      );
    };
  }
}
