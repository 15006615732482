import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import { theme } from "../App";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InfoIcon from "@mui/icons-material/Info";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useNavigate } from "react-router";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";

export default function BreadCrumbs(props) {
  let navigate = useNavigate();
  function handleClick(event, value) {
    event.preventDefault();

    if (event.target.innerText === "Release Information") {
      navigate("/releaseInfo");
    }
    if (event.target.innerText === "Vevo") {
      navigate("/vevo");
    }
    if (event.target.innerText === "Upload") {
      navigate("/upload");
    }
    if (event.target.innerText === "Tracks") {
      navigate("/tracks");
    }
    if (event.target.innerText === "Release Date") {
      navigate("/releaseDate");
    }
    if (event.target.innerText === "Promotion") {
      navigate("/promotion");
    }
    if (event.target.innerText === "Submission") {
      navigate("/submission");
    }

    // if (event.target.innerText === "Shadow Table") {
    //   navigate("/shadowtable");
    // }
  }

  const breadcrumbsData = [
    {
      label: "Release Information",
      icon: <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/releaseInfo",
    },
    ...(props?.releaseType === "video"
      ? [
          {
            label: "Vevo",
            icon: <AssistantPhotoIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
            path: "/vevo",
          },
        ]
      : []),
    {
      label: "Upload",
      icon: <FileUploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/upload",
    },
    {
      label: "Tracks",
      icon: <QueueMusicIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/tracks",
    },
    {
      label: "Release Date",
      icon: <ScheduleIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/releaseDate",
    },
    {
      label: "Promotion",
      icon: <TaskAltIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/promotion",
    },
    {
      label: "Submission",
      icon: <TaskAltIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
      path: "/submission",
    },
    // You can add more objects here if needed
  ];

  let pages = JSON.parse(localStorage.getItem("page_permission"));

  let filteredBreadcrumbsData = [];

  if (pages[0] === "*") {
    filteredBreadcrumbsData = breadcrumbsData;
  } else {
    filteredBreadcrumbsData = breadcrumbsData.filter((item) =>
      pages.includes(item.path.replace("/", ""))
    );
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {filteredBreadcrumbsData.map((breadcrumb, index) => (
          <Link
            key={index}
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color:
                props.pathname === breadcrumb.path
                  ? theme.palette.text.primary
                  : theme.palette.text.gray,
            }}
          >
            {breadcrumb.icon}
            {breadcrumb.label}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
