import React, { useEffect, useRef, useState } from "react";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { theme } from "../App";
import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { ErrorMessage, FastField, Field, Form, Formik } from "formik";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import BreadCrumbs from "./BreadCrumbs";
import MuiButton from "../../components/muiButton/MuiButton";
import environment from "environment";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import MuiTextField from "../../components/muiTextField/MuiTextField";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [ReleasesAction.REQUEST_RELEASE_BY_ID]),
  releaseById: state.releaseById.releaseById || {},
});

function Vevo(props) {
  const [loading, setLoading] = useState(false);
  const [submissionRedirect, setSubmissionRedirect] = useState(false);
  const navigate = useNavigate();
  const boxRef = useRef(null);

  let initial = {
    VevoSettings: {
      VevoChannel: {
        ChannelName: null,
        ArtistCountryOfOrigin: null,
        ChannelType: null,
      },
      CustomVersion: "",
      CtvOnlyDelivery: false,
      VevoDescription: null,
      VevoThumbnailAsset: null,
    },
  };

  function idsWithEditPermission() {
    return ["1", "4", "10", "9"].includes(_source?.status?.id);
  }

  if (props.releaseById && props.releaseById._source) {
    Object.assign(
      initial,
      props.releaseById &&
        props.releaseById._source &&
        props.releaseById._source.VevoSettings
        ? {
            VevoSettings: props.releaseById._source.VevoSettings,
          }
        : {
            VevoSettings: {
              VevoChannel: {
                ChannelName: null,
                ArtistCountryOfOrigin: null,
                ChannelType: null,
              },
              CustomVersion: "",
              CtvOnlyDelivery: false,
              VevoDescription: null,
              VevoThumbnailAsset: null,
            },
          }
    );
  }

  function Required() {
    return (
      <>
        <span style={{ color: theme.palette.secondary.main }}>Required</span>{" "}
        <span style={{ color: theme.palette.secondary.main }}>*</span>
      </>
    );
  }

  const { _id, _source } = props?.releaseById && props?.releaseById;

  const location = useLocation();
  const formRef = useRef(null);

  const [highImage, sethighImage] = useState("");
  const [highImageLoader, sethighImageLoader] = useState(false);

  useEffect(() => {
    if (!props.isRequesting) {
      sethighImage(() => {
        !props.isRequesting &&
          _source?.VevoSettings?.VevoThumbnailAsset &&
          RetrieveHighImageUrl(_source?.VevoSettings?.VevoThumbnailAsset);
      });
    }
  }, [!props.isRequesting]);

  function RetrieveHighImageUrl(thumbnail_url) {
    sethighImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let highQualityImage = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=vevo&path=${thumbnail_url}&release_id=${_id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        sethighImageLoader(false);
        highQualityImage = URL.createObjectURL(blob);
        sethighImage(highQualityImage);
      });
  }

  const handleFileUpload = (value, setFieldValue, event) => {
    const MAX_FILE_SIZE_MB = 5;
    const ACCEPTED_FILE_TYPES = ["image/jpeg", "image/png", "image/tiff"];
    const MIN_WIDTH = 1920;
    const MIN_HEIGHT = 1080;
    const ALLOWED_DIMENSIONS = [
      { width: 1920, height: 1080 },
      { width: 3840, height: 2160 },
      { width: 4096, height: 2160 },
    ];

    let formData = new FormData();

    const file = event.target?.files[0];
    const fileSizeMB = file?.size / (1024 * 1024);

    const image = new Image();
    const reader = new FileReader();

    reader.onloadend = function () {
      image.src = reader.result;

      image.onload = function () {
        const width = this.width;
        const height = this.height;
        const fileType = file.type;

        if (fileSizeMB <= MAX_FILE_SIZE_MB) {
          if (
            width >= MIN_WIDTH &&
            height >= MIN_HEIGHT &&
            ALLOWED_DIMENSIONS.some(
              (dim) => dim.width === width && dim.height === height
            ) &&
            ACCEPTED_FILE_TYPES.includes(fileType)
          ) {
            sethighImageLoader(true);

            fetch(
              `${environment.api.fileUpload}/add?current_page=vevo&release_id=${_id}&thumbnail=true`,
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + access_tocken,
                },
                body: formData,
              }
            )
              .then((res) => res.json())
              .then((response) => {
                if (response?.status === "success") {
                  setFieldValue(
                    "VevoSettings.VevoThumbnailAsset",
                    response.url
                  );
                  RetrieveHighImageUrl(response.url);
                } else {
                  sethighImageLoader(false);
                }
              });
          } else {
            console.error("Invalid file dimensions or type");
            alert(
              "File must be 1920x1080, 3840x2160, or 4096x2160 and in JPG, PNG, or TIFF format."
            );
          }
        } else {
          console.error("File size exceeds the maximum limit");
          alert("File size must be less than 5MB.");
        }
      };
    };

    if (value) {
      reader.readAsDataURL(value);
    }

    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
  };

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};

          return location.state?.shouldSubmit && errors;
        }}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);

          let obj = {};
          Object.assign(obj, { VevoSettings: values.VevoSettings });

          let params = {
            current_page: "vevo",
          };
          if (localStorage.getItem("id")) {
            props
              .dispatch(
                ReleasesAction.requestPutRelease(
                  obj,
                  localStorage.getItem("id"),
                  params
                )
              )
              .then(() => {
                setLoading(false);

                props.dispatch(
                  ReleasesAction.requestReleaseById(
                    localStorage.getItem("id"),
                    params
                  )
                );
                if (submissionRedirect) navigate("/submission");
                else navigate("/upload");
              });
          }
        }}
        innerRef={formRef}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
          name,
          options,
          placeholder,
        }) => (
          <Form style={{ width: "100%" }}>
            <>
              <Grid
                item
                container
                xl={12}
                lg={12}
                sm={12}
                md={12}
                alignItems="center"
                justifyContent={"space-between"}
                style={{
                  position: "sticky",
                  top: `${boxRef?.current?.offsetTop}px`,
                  backgroundColor: theme.palette.background.default,
                  zIndex: 10,
                }}
              >
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={10}
                  container
                  alignItems={"center"}
                  justifyContent="flex-start"
                  gap={2}
                  paddingTop={1}
                >
                  <Grid item>
                    <h2>{formatName(`${_source?.title}`)}</h2>
                  </Grid>

                  <Grid item>
                    <BreadCrumbs
                      pathname={window.location.pathname}
                      releaseType={_source?.type_of_release}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  md={12}
                  xl={12}
                  className="d-flex justify-content-end"
                  sx={{
                    paddingTop: "10px",
                    paddingX: {
                      lg: 20,
                      md: 1,
                      sm: 1,
                    },
                  }}
                >
                  <Grid item lg={1} md={2} sm={2}>
                    <MuiButton
                      disabled={
                        _source
                          ? _source.status &&
                            ["1", "4", "10", "9"].includes(_source.status.id)
                            ? false
                            : true
                          : false
                      }
                      loading={loading}
                      type="submit"
                      name="Save"
                      width="100%"
                      save
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                lg={12}
                sm={12}
                md={12}
                xl={12}
                mt={2}
                // className="bg-info"
              >
                <Grid
                  item
                  lg={4}
                  xl={4}
                  md={4}
                  sm={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    component={"div"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: {
                        xl: "384px",
                        lg: "200px",
                        md: "100px",
                        sm: "100px",
                        xs: "100px",
                      },
                      height: {
                        xl: "216px",
                        lg: "200px",
                        md: "100px",
                        sm: "100px",
                        xs: "100px",
                      },
                      cursor: `${
                        _source && _source.status.id === "2"
                          ? "default"
                          : "pointer"
                      }`,
                      backgroundColor: theme.palette.background.paper,
                      border: `1px dashed ${theme.palette.text.dark}`,
                    }}
                  >
                    <Field
                      name="VevoSettings.VevoThumbnailAsset"
                      // disabled={true}
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {value && highImage ? (
                            <Typography
                              component={"img"}
                              src={highImage}
                              alt="Invalid Image"
                              onError={(e) => {}}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : highImageLoader ? (
                            <CircleLoader />
                          ) : (
                            <Typography
                              component={"label"}
                              htmlFor="thumbnail-upload"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileArrowUp}
                                style={{
                                  color: theme.palette.common.grey,
                                  width: "37px",
                                  height: "50px",
                                }}
                              ></FontAwesomeIcon>
                              <Typography
                                component={"span"}
                                sx={{
                                  color: theme.palette.common.grey,
                                  fontSize: "16px",
                                  mt: "15px",
                                }}
                              >
                                Vevo Custom Thumbnail (optional)
                              </Typography>
                            </Typography>
                          )}

                          <input
                            id="thumbnail-upload"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileUpload(
                                e.target.files[0],
                                setFieldValue,
                                e
                              );
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      textAlign: "center",
                      fontSize: "12px",
                      color: theme.palette.primary.light,
                      mt: 1,
                    }}
                  >
                    Image size should be in 1920 x 1080,
                    <br />
                    3840 x 2160 or 4096 x 2160 pixels (1920 x 1080 minimum)
                    <br />
                    JPG, PNG, TIFF RGB Color Model
                    <ErrorMessage name="thumbnail_url" />
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={8}
                  xl={8}
                  md={8}
                  sm={8}
                  rowGap={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    gap={3}
                    alignItems={"center"}
                    justifyContent="center"
                    display="flex"
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                    >
                      <Typography>Vevo Channel</Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} container>
                      <Grid item lg={6} md={6} sm={6}>
                        <Field name={"VevoSettings.VevoChannel.ChannelType"}>
                          {({ field, meta }) => (
                            <Tooltip title={""} arrow placement="right-end">
                              <Select
                                input={
                                  <OutlinedInput
                                    {...inputProps}
                                    disabled={false}
                                  />
                                }
                                MenuProps={menuProps}
                                value={field.value ? field.value.id : ""}
                                onChange={(event) => {
                                  const selectedOption = options.find(
                                    (option) => option.id === event.target.value
                                  );
                                  field.onChange(selectedOption); // Update the hidden field with the selected object
                                }}
                                {...field}
                                displayEmpty
                                size={"small"}
                                style={{ width: "250px", height: "30px" }}
                                error={
                                  meta.touched && meta.error ? true : false
                                }
                              >
                                {/* {placeholder && (
                                <MenuItem value="" disabled>
                                  <span style={{ color: "#b7c4c2" }}>
                                    {placeholder}
                                  </span>
                                </MenuItem>
                              )} */}
                                {["New Channel", "Existing Channel"].map(
                                  (option) => (
                                    <MenuItem
                                      key={option}
                                      value={option}
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "normal",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Tooltip>
                          )}
                        </Field>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6}>
                        <Tooltip title={""} arrow placement="right-end">
                          <Field
                            variant="outlined"
                            as={MuiTextField}
                            fieldheight="30px"
                            id={name}
                            size="small"
                            name={"VevoSettings.VevoChannel.ChannelName"}
                            placeholder={"Channel Name"}
                            disabled={false}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    gap={3}
                    alignItems={"center"}
                    justifyContent="center"
                    display="flex"
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                    >
                      <Typography>Vevo Custom Version</Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} container>
                      <Field name={"VevoSettings.CustomVersion"}>
                        {({ field, meta }) => (
                          <Tooltip title={""} arrow placement="right-end">
                            <Select
                              input={
                                <OutlinedInput
                                  {...inputProps}
                                  disabled={false}
                                />
                              }
                              MenuProps={menuProps}
                              value={field.value ? field.value.id : ""}
                              onChange={(event) => {
                                const selectedOption = options.find(
                                  (option) => option.id === event.target.value
                                );
                                field.onChange(selectedOption); // Update the hidden field with the selected object
                              }}
                              {...field}
                              displayEmpty
                              size={"small"}
                              style={{ width: "250px", height: "30px" }}
                              error={meta.touched && meta.error ? true : false}
                            >
                              <MenuItem value="" disabled>
                                <span style={{ color: "#b7c4c2" }}>
                                  Choose Version{" "}
                                </span>
                              </MenuItem>
                              {[
                                "Official",
                                "Official Video",
                                "Official Music Video",
                                "Official Lyric Video",
                                "Lyric Video",
                                "Original Content",
                                "Alternate Version",
                              ].map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "normal",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </Tooltip>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    gap={3}
                    alignItems={"center"}
                    justifyContent="center"
                    display="flex"
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                    >
                      <Typography>Vevo Description</Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} container>
                      <FastField
                        className={
                          touched.title && errors.title
                            ? "ticket_desc_test_area_error scrollable"
                            : "ticket_desc_test_area scrollable"
                        }
                        name="VevoSettings.VevoDescription"
                        component="textarea"
                        value={values.VevoSettings?.VevoDescription}
                        onChange={handleChange}
                        maxLength={5000}
                        type="text"
                        maxRows={4}
                        minRows={4}
                        style={{
                          width: "100%",
                          height: 200,
                          padding: "10px",
                          boxSizing: "border-box",
                          color: `${theme.palette.text.primary}`,
                        }}
                        aria-label="maximum height"
                        placeholder="Max 5000 characters"
                        label="description"
                      />

                      <Box textAlign="left" px={2}>
                        {5000 - values.VevoSettings?.VevoDescription?.length}{" "}
                        characters left
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default connect(mapStateToProps)(Vevo);
