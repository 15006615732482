import BaseReducer from "../../utilities/BaseReducer";
import MusicLabelsChangeRequestTrackShadowAction from "./MusicLabelsChangeRequestTrackShadowAction";

export default class musicLabelsChangeRequestTrackShadowReducer extends BaseReducer {
  initialState = {
    musiclabelRequestChangeTrackShadow: [],
  };
  [MusicLabelsChangeRequestTrackShadowAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_SHADOW_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      musiclabelRequestChangeTrackShadow: action.payload,
    };
  }
}
