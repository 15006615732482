import environment from "./base";

/*
 * base.ts is the default environment for production.
 * You shouldn't have override anything.
 */
const baseApi = "https://dashboardapi.a3labels.in";
const logsApi = "https://workspaceapi.a3labels.in";
const a3TunesApi = "https://a3labels.in/quicklinks";
const socketApi = "clientnotifi.a3labels.in";

const env = environment({ baseApi, socketApi, a3TunesApi, logsApi });

const productionEnv = {
  ...env,
  // override anything that gets added from base.
};

export default productionEnv;
