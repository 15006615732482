import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import MusicLabelsChangeRequestReleaseModel from "./musicLabelsChangeRequestReleaseModel/MusicLabelsChangeRequestReleaseGetModel";
import MusicLabelsChangeRequestReleasePutModel from "./musicLabelsChangeRequestReleaseModel/MusicLabelsChangeRequestReleasePutModel";
import MusicLabelsChangeRequestReleasePostModel from "./musicLabelsChangeRequestReleaseModel/MusicLabelsChangeRequestReleasePostModel";
import MusicLabelsChangeRequestReleaseByIdModel from "./musicLabelsChangeRequestReleaseModel/MusicLabelsChangeRequestReleaseByIdModel";
export default class MusicLabelsChangeRequestReleaseEffect {
  // Get method for the
  static async requestMusicLabelsChangeRequestRelease(params, callBack) {
    const endpoint = environment.api.musiclabelRequestChangeRelease;
    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestReleaseModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestMusicLabelsFilterChangeRequestRelease(params, callBack) {
    const endpoint = environment.api.musiclabelRequestChangeRelease;
    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestReleaseModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestPutMusicLabelsChangeRequestRelease(
    data,
    id,
    params,
    callBack
  ) {
    const endpoint = environment.api.musiclabelRequestChangeRelease + "/" + id;
    let Response = EffectUtility.putToModel(
      MusicLabelsChangeRequestReleasePutModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      Response.then((res) => {
        callBack(res);
      });
    }

    return Response;
  }

  static async requestPostMusicLabelsChangeRequestRelease(
    data,
    callBack,
    params
  ) {
    const endpoint = environment.api.musiclabelRequestChangeRelease;
    let response = EffectUtility.postToModel(
      MusicLabelsChangeRequestReleasePostModel,
      endpoint,
      data,
      params
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestMusicLabelsChangeRequestReleaseById(
    id,
    params,
    callBack
  ) {
    const endpoint = environment.api.musiclabelRequestChangeRelease + "/" + id;

    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestReleaseByIdModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }
  static async requestDeleteMusicLabelsChangeRequestRelease(id, callback) {
    const endpoint = environment.api.musiclabelRequestChangeRelease + "/" + id;
    let response = EffectUtility.deleteToModel(
      MusicLabelsChangeRequestReleasePostModel,
      endpoint
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
