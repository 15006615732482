import {
  Alert,
  Badge,
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Snackbar,
  Typography,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useRef, useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadPopper from "../../components/poppers/UploadPopper";
import { connect } from "react-redux";
import TrackAction from "../../stores/track/TrackAction";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArtistAction from "../../stores/artist/ArtistAction";
import ClientAction from "../../stores/client/ClientAction";
import LanguageAction from "../../stores/language/LanguageAction";
import GenreAction from "../../stores/genre/GenreAction";
import SubGenreAction from "../../stores/subgenre/SubGenreAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PlanAction from "../../stores/plan/PlanAction";
import { useLocation } from "react-router";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import DeliveredTrackDetailsDialog from "../deliveredReleases/DeliveredTrackDetailsDialog";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LaunchIcon from "@mui/icons-material/Launch";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SyncIcon from "@mui/icons-material/Sync";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import axios from "axios";

import { theme } from "../App";
import copy from "copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import environment from "environment";
import popperClasses from "@mui/material/Popper/popperClasses";
import TrackPost from "../track/TrackPost";
import ThreeDotsPopper from "../../components/threeDotsPopper/ThreeDotsPopper";
import ChangeRequestTrackPost from "./ChangeRequestTrackPost";
import MusicLabelsChangeRequestReleaseAction from "../../stores/musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseAction";
import MusicLabelsChangeRequestTrackAction from "../../stores/musicLabelsChangeRequestTrack/MusicLabelsChangeRequestTrackAction";
import Upload from "../ReleaseSubPages/Upload";
import RetriveAssetDialog from "../assets/RetriveAssetDialog";
import AssetsAction from "../../stores/assets/AssetsAction";
import AssetsPost from "../assets/AssetsPost";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ArtistAction.REQUEST_ARTIST_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    GenreAction.REQUEST_GENRE_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    PlanAction.REQUEST_PLAN,
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
    ActorAction.REQUEST_ACTOR_FILTER,
    ActressAction.REQUEST_ACTRESS_FILTER,
    DirectorAction.REQUEST_DIRECTOR_FILTER,
    MusicLabelsChangeRequestReleaseAction.REQUEST_CHANGE_REQUEST_RELEASE_BY_ID,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
  ]),
  track: state.track.track.hits || [],
  assets: state.assets.assets.hits || [],
  assetsFilter: state.assetsFilter.assetsFilter || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  language: state.language.language || [],
  genreFilter: state.genreFilter.genreFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  plan: state.plan.plan || {},
  actorFilter: state.actorFilter.actorFilter || [],
  actressFilter: state.actressFilter.actressFilter || [],
  directorFilter: state.directorFilter.directorFilter || [],
  releaseById: state.releaseById.releaseById || {},
  language: state.language.language || [],
  changeRequestReleaseById:
    state.changeRequestReleaseById.changeRequestReleaseById || {},
  musiclabelRequestChangeTrack:
    state.musiclabelRequestChangeTrack.musiclabelRequestChangeTrack || [],
});

function ChangeRequestReleaseTracks(props) {
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [trackData, setTrackData] = useState(null);
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [trackPost, setTrackPost] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState(null);
  const [edit, setEdit] = useState(false);
  const [isTrackError, setIsTrackError] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState([]);
  const [submissionRedirect, setSubmissionRedirect] = useState(false);
  const [selectedgenre, setSelectedGenre] = useState(null);
  const [changTrackId, setChangTrackId] = useState([]);
  const [retriveAssetOpen, setretriveAssetOpen] = useState(false);
  const [openAssetsForm, setOpenAssetsForm] = useState(false);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const boxRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [matchedAsset, setMatchedAsset] = useState(null);
  const [url, setUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [rows, setRows] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteID, setDeleteId] = useState(false);

  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params));
  }

  useEffect(() => {
    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: "none",
      current_page: "delivered_release_details",
      filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
    };
    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "change_requests",
    };
    fetchTracks(params);
    fetchResource(props?.releaseId);
    fetchChangeRequestTrack(location?.state?.requestId);
    fetchlanguageFilter(filterparam);
    fetchActorFilter(filterparam);
    fetchActressFilter(filterparam);
    fetchDirectorFilter(filterparam);
  }, []);
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "10px 4px 4px rgba(0, 0, 0, 0.5)",
    },
  }));
  const rowsDatagrid = [];
  const getSortedJSONString = (arr) => arr && JSON.stringify([...arr]?.sort());
  function compareArtistIds(arr1, arr2, param) {
    const artistIds1 = arr1?.map((item) => item?.[param]?.id || item?.[param]);
    const artistIds2 = arr2?.map((item) => item?.[param]?.id || item?.[param]);

    artistIds1?.sort();
    artistIds2?.sort();

    return getSortedJSONString(artistIds1) !== getSortedJSONString(artistIds2);
  }
  const [assetIds, setAssetIds] = useState(() => {
    return localStorage.getItem("assetIds")
      ? JSON.parse(localStorage.getItem("assetIds"))
      : [];
  });

  const OpenDailog = (val) => {
    setMediaType(val);
    setOpenAssetsForm(true);
  };
  const isKeyPresent = (obj, param2) => {
    let Count = 0; // Initialize the count of changed keys
    const keys = [
      "content_type",
      "primary_track_type",
      "secondary_track_type",
      "instrumental",
      "title",
      "primary_artists",
      "featuring",
      "remixer",
      "author",
      "composer",
      "arranger",
      "producer",
      "copyright_year",
      "phonographic_copyright_year",
      "production_year",
      "copyrighted_to",
      "publisher",
      "published_by",
      "genre",
      "sub_genre_name",
      "featuring_actor",
      "featuring_actress",
      "director",
      "lyrics_language",
      "lyrics",
      "track_title_language",
      "producer_catalouge_number",
      "plan_id",
      "parental_advisory",
      "music/album",
      "preview_start",
      "primary_actress",
      "primary_actor",
    ];

    keys.forEach((key) => {
      let TypeOfKey = false;

      if (typeof obj?.[key] === "string") {
        const oldTrackDataValue = param2?.[key];
        const oldTrackDataLower =
          typeof oldTrackDataValue === "string"
            ? oldTrackDataValue.toLowerCase()
            : oldTrackDataValue;

        // Compare values
        if (obj?.[key].toLowerCase() !== oldTrackDataLower) {
          TypeOfKey = true;
        }
      } else if (Array.isArray(obj?.[key])) {
        const filteredArray = obj?.[key]?.filter((item) => item !== "");

        if (key === "primary_artists" || key === "featuring") {
          TypeOfKey = compareArtistIds(
            filteredArray,
            param2?.[key],
            "artist_id"
          );
        } else if (key === "primary_actress") {
          TypeOfKey = compareArtistIds(
            filteredArray,
            param2?.[key],
            "actress_id"
          );
        } else if (key === "primary_actor") {
          TypeOfKey = compareArtistIds(
            filteredArray,
            param2?.[key],
            "actor_id"
          );
        } else if (key === "director") {
          TypeOfKey = compareArtistIds(
            filteredArray,
            param2?.[key],
            "director_id"
          );
        } else if (
          key === "arranger" ||
          key === "composer" ||
          key === "producer" ||
          key === "author" ||
          key === "publisher" ||
          key === "music/album"
        ) {
          TypeOfKey =
            getSortedJSONString(filteredArray) !==
            getSortedJSONString(
              param2?.[key]?.filter((artist) => artist !== "")
            );
        }
      } else if (key === "lyrics_language" || key === "track_title_language") {
        TypeOfKey = obj?.[key]?.[key] !== param2?.[key]?.[key];
      } else if (key === "production_year" || key === "instrumental") {
        TypeOfKey = obj?.[key] !== param2?.[key];
      } else if (typeof obj?.[key] === "object") {
        TypeOfKey = obj?.[key]?.id !== param2?.[key]?.id;
      }

      if (TypeOfKey && obj?.hasOwnProperty(key)) {
        Count += 1;
      }
    });

    return Count; // Return the total count of changed keys
  };

  if (props.track.hits) {
    rowCreator(props.track.hits, rowsDatagrid);
  }

  const { _id, _source } = props?.releaseById;

  function fetchResource(resourceId) {
    let params = {
      current_page: "tracks",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }
  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }
  function fetchChangeRequestTrack(id) {
    let params = {
      current_page: "change_request",
      filters: `[["change_request_release_id", "must", "", "match", "${id}"]]`,
    };
    props.dispatch(
      MusicLabelsChangeRequestTrackAction.requestMusicLabelsChangeRequestTrack(
        params
      )
    );
  }

  const ChangeRequestTracksArray =
    props?.musiclabelRequestChangeTrack?.hits?.hits?.map((item) => ({
      changeRequestTrackId: item._id,
      track_id: item._source.track_id,
    }));

  function rowCreator(data, row) {
    data.map((item, index) => {
      const matchedTrack =
        props?.musiclabelRequestChangeTrack?.hits?.hits?.find(
          (match) => match?._source?.track_id === item._id
        );

      return row.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        changeTrackId: matchedTrack?._id,
        ChangedErrors: isKeyPresent(matchedTrack?._source, item?._source),
        arranger: matchedTrack?._source?.arranger || item._source.arranger,
        ask_to_generate_an_isrc:
          matchedTrack?._source?.ask_to_generate_an_isrc ||
          item._source.ask_to_generate_an_isrc,
        author: matchedTrack?._source?.author || item._source.author,
        client_id: matchedTrack?._source?.client_id || item._source.client_id,
        client_id_object:
          matchedTrack?._source?.client_id_object || item._source.client_id,
        composer: matchedTrack?._source?.composer || item._source.composer,
        content_type:
          matchedTrack?._source?.content_type || item._source.content_type,
        created_by:
          matchedTrack?._source?.created_by ||
          (item._source.created_by && item._source.created_by.user_name),
        created_on:
          matchedTrack?._source?.created_on || item._source.created_on,
        featuring: matchedTrack?._source?.featuring || item._source.featuring,
        genre: matchedTrack?._source?.genre || item._source.genre,
        director: matchedTrack?._source?.director || item._source.director,
        duplication_number:
          matchedTrack?._source?.duplication_number ||
          item._source.duplication_number,
        primary_actor:
          matchedTrack?._source?.primary_actor || item._source.primary_actor,
        primary_actress:
          matchedTrack?._source?.primary_actress ||
          item._source.primary_actress,
        instrumental:
          matchedTrack?._source?.instrumental || item._source.instrumental,
        isrc: matchedTrack?._source?.isrc || item._source.isrc,
        lyrics: matchedTrack?._source?.lyrics || item._source.lyrics,
        lyrics_language:
          matchedTrack?._source?.lyrics_language ||
          item._source.lyrics_language,
        parental_advisory:
          matchedTrack?._source?.parental_advisory ||
          item._source.parental_advisory,
        plan_id: matchedTrack?._source?.plan_id || item._source.plan_id,
        preview_start:
          matchedTrack?._source?.preview_start || item._source.preview_start,
        primary_artists:
          matchedTrack?._source?.primary_artists ||
          item._source.primary_artists,
        primary_track_type:
          matchedTrack?._source?.primary_track_type ||
          item._source.primary_track_type,
        producer: matchedTrack?._source?.producer || item._source.producer,
        producer_catalouge_number:
          matchedTrack?._source?.producer_catalouge_number ||
          item._source.producer_catalouge_number,
        production_year:
          matchedTrack?._source?.production_year ||
          item._source.production_year,
        published_by:
          matchedTrack?._source?.published_by || item._source.published_by,
        publisher: matchedTrack?._source?.publisher || item._source.publisher,
        remixer: matchedTrack?._source?.remixer || item._source.remixer,
        release_id:
          matchedTrack?._source?.release_id || item._source.release_id,
        secondary_genre:
          matchedTrack?._source?.secondary_genre ||
          item._source.secondary_genre,
        secondary_track_type:
          matchedTrack?._source?.secondary_track_type ||
          item._source.secondary_track_type,
        seconday_sub_genre:
          matchedTrack?._source?.seconday_sub_genre ||
          item._source.seconday_sub_genre,
        stream_id: matchedTrack?._source?.stream_id || item._source.stream_id,
        sub_genre_name:
          matchedTrack?._source?.sub_genre_name || item._source.sub_genre_name,
        title: matchedTrack?._source?.title || item._source.title,
        track_title_language:
          matchedTrack?._source?.track_title_language ||
          item._source.track_title_language,
        ["music/album"]:
          matchedTrack?._source?.["music/album"] || item._source["music/album"],
        Upload_Asset: "",
        views: matchedTrack?._source?.views || item._source.views,
        phonographic_copyright_year:
          matchedTrack?._source?.phonographic_copyright_year ||
          item._source.phonographic_copyright_year,
        copyright_year:
          matchedTrack?._source?.copyright_year || item._source.copyright_year,
        copyrighted_to:
          matchedTrack?._source?.copyrighted_to || item._source.copyrighted_to,
        label: matchedTrack?._source?.label || item._source.label?.label_name,
        release:
          matchedTrack?._source?.release || item._source.release_id?.title,
        oldTrackData: item?._source,
      });
    });
  }
  let nameslist = [];
  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      // const name = item.name;
      nameslist.push({ id: key, name: item.name });
    }
  }
  const returnLyricsObj = (id) => {
    return nameslist &&
      nameslist.length !== 0 &&
      nameslist.filter((item) => {
        return item.id === id;
      })[0]
      ? nameslist &&
          nameslist.length !== 0 &&
          nameslist.filter((item) => {
            return item.id === id;
          })[0]
      : null;
  };
  let plan = props.releaseById?._source?.plan_id;
  let primary_artist = props.releaseById?._source?.artists;
  let featuring_artists = props.releaseById?._source?.featuring_artists;
  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  function fetchActorFilter(param) {
    props.dispatch(ActorAction.requestActorFilter(param));
  }

  function fetchActressFilter(param) {
    props.dispatch(ActressAction.requestActressFilter(param));
  }
  function fetchDirectorFilter(param) {
    props.dispatch(DirectorAction.requestDirectorFilter(param));
  }
  let artistArray = [];

  if (props.artistFilter.hits) {
    props.artistFilter.hits.hits.forEach((value) => {
      artistArray.push({
        artist_id: value._id,
        artist_name: value._source.artist_name,
        stream_platforms: value._source.stream_platforms,
      });
    });
  }
  let AssetAndTrackList = [];

  props.releaseById?._source?.tracks?.map((value) => {
    AssetAndTrackList.push({
      assets_id: value.assets_id ?? "",
      track_id: value.track_id?.id ?? "",
    });
  });
  let subgenrelist = [];

  if (props.subgenreFilter.hits) {
    props.subgenreFilter.hits.hits
      .filter((item) => item._source.genre?.id === parseInt(selectedgenre))
      .map((value) => {
        subgenrelist.push({
          id: value._id,
          name: value._source.subgenre_name,
          genre: value._source.genre,
        });
      });
  }
  let genreList = [];
  if (props.genreFilter.hits) {
    props.genreFilter.hits.hits.forEach((value) => {
      genreList.push({
        id: value._id,
        name: value._source.genre_name,
      });
    });
  }
  let planList = [];

  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });
  }
  planList = planList.filter((plan) => /T1|T2|T3/.test(plan.name));
  let actorarray = [];
  if (props.actorFilter.hits) {
    props.actorFilter.hits.hits.forEach((value) => {
      actorarray.push({
        actor_id: value._id,
        actor_name: value._source.actor_name,
      });
    });
  }
  let actressarray = [];
  if (props.actressFilter.hits) {
    props.actressFilter.hits.hits.forEach((value) => {
      actressarray.push({
        actress_id: value._id,
        actress_name: value._source.actress_name,
      });
    });
  }

  let directorarray = [];
  if (props.directorFilter.hits) {
    props.directorFilter.hits.hits.forEach((value) => {
      directorarray.push({
        director_id: value?._id,
        director_name: value?._source?.director_name,
      });
    });
  }
  async function DownloadFile(api, file_name) {
    try {
      // Make an API request
      fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const reader = response.body.getReader();
          const chunks = []; // Array to store the chunks
          let receivedLength = 0; // To track the size of the received data

          // Read the stream chunks
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              break; // No more data to read
            }

            chunks.push(value); // Push each chunk to the array
            receivedLength += value.length;
          }

          // Combine all chunks into a Blob
          const blob = new Blob(chunks, {
            type: response.headers.get("content-type"),
          });

          // Create a hidden anchor element for downloading
          const downloadLink = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;

          // Set the desired file name (you can set this dynamically)
          downloadLink.download = file_name;

          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);

          // Trigger the download
          downloadLink.click();

          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
          setDownloadLoading((prev) =>
            prev.filter((loadingId) => loadingId !== file_name)
          );
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  }

  function extractFileName(inputString) {
    const parts = inputString?.split("_");
    const extractedWord = parts?.[0];

    return extractedWord;
  }
  function deleteFile(Change_trackId) {
    let page_params = {
      current_page: "change_request",
    };
    const foundChange =
      props.changeRequestReleaseById?._source?.change_request_tracks?.find(
        (item) => item.track_id === Change_trackId
      );
    const callBack = (res) => {
      if (res.status === "Deleted") {
        const newTracks =
          props.changeRequestReleaseById?._source?.change_request_tracks?.map(
            (item) => {
              if (item?.assets_id?.id === foundChange?.assets_id?.id) {
                return {
                  track_id: item?.track_id ?? "",
                  assets_id: "",
                }; // Set the entire dictionary to an empty object
              } else {
                return {
                  track_id: item?.track_id ?? "",
                  assets_id: item?.assets_id?.id ?? "",
                }; // Return the original dictionary
              }
            }
          );

        let put_obj = {
          change_request_tracks: newTracks,
          release_id: props?.releaseById?._id,
        };
        return props
          .dispatch(
            MusicLabelsChangeRequestReleaseAction.requestPutMusicLabelsChangeRequestRelease(
              JSON.stringify(put_obj),
              props.changeRequestReleaseById?._id,
              {}
            )
          )
          .then(() => {
            const param = {
              current_page: "changeRequestForm",
            };

            // After successfully updating the release, fetch the latest release details
            return props.dispatch(
              MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
                props.changeRequestReleaseById?._id,
                param
              )
            );
          });
      }
    };
    if (foundChange) {
      props
        .dispatch(
          AssetsAction.requestAssetsDelete(
            foundChange?.assets_id?.id,
            page_params,
            callBack
          )
        )
        .then(() => {
          setConfirmDialog(false);
          let params = {
            page_limit: "none",
            filters: `[["release_id.keyword","must","","match","${props.releaseById?._id}"]]`,
            current_page: "upload",
          };
          props.dispatch(AssetsAction.requestAssets(params));
        });
    }
  }
  const returnBadgeCount = (In_ChangeRequestTracksArray, track_id) => {
    const found_id = In_ChangeRequestTracksArray?.find((item) => {
      return item?.track_id === track_id;
    })?.changeRequestTrackId;

    const found_obj = props?.musiclabelRequestChangeTrack?.hits?.hits?.find(
      (change) => {
        return change?._id === found_id;
      }
    );

    function countKeysInObject(found_obj_in, keys_in) {
      return keys_in.reduce((count, key) => {
        if (found_obj_in?._source?.hasOwnProperty(key)) {
          return count + 1;
        }
        return count;
      }, 0);
    }

    const keys = [
      "content_type",
      "primary_track_type",
      "secondary_track_type",
      "instrumental",
      "title",
      "primary_artists",
      "featuring",
      "remixer",
      "author",
      "composer",
      "arranger",
      "producer",
      "copyright_year",
      "phonographic_copyright_year",
      "production_year",
      "copyrighted_to",
      "publisher",
      "published_by",
      "genre",
      "sub_genre_name",
      "featuring_actor",
      "featuring_actress",
      "director",
      "lyrics_language",
      "lyrics",
      "track_title_language",
      "producer_catalouge_number",
      "plan_id",
      "parental_advisory",
      "music/album",
      "preview_start",
    ];
    return countKeysInObject(found_obj, keys);
  };
  console.log("dflkjslnk", editRowsModel);
  function sanitizeFilename(filename) {
    // Remove spaces and special characters from the filename
    let cleanedFilename;
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      cleanedFilename = filename.replace(/[^\w]/g, (match, index) => {
        return index === lastDotIndex ? match : "";
      });
    }
    return cleanedFilename;
  }

  const uploadChunks = async (
    chunks,
    file,
    multipart_upload_id,
    chunkIndex,
    chunkUploadPercentage,
    assetId
  ) => {
    try {
      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      // CHUNK MULTIPART UPLOAD

      const uploadPromises = chunks.map(async (chunk, i) => {
        const formData = new FormData();
        formData.append("file", chunk);

        try {
          const chunk_upload_response = await axios.post(
            `${environment.api.baseApi}/upload_multipart/${
              chunkIndex + i + 1
            }?path=${_source?.client_id?.id}/${_id}/${sanitizeFilename(
              file.name
            )}&upload_id=${multipart_upload_id}`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + access_token,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // console.log("chunk_upload response", chunk_upload_response);

          // Function to update an object based on ID
          function updateObjectById(array, idToUpdate, updatedData) {
            const updatedArray = array?.map((obj) => {
              if (obj.asset_id === idToUpdate) {
                return { ...obj, ...updatedData };
              }
              return obj;
            });

            return updatedArray;
          }

          if (chunk_upload_response.status === 200) {
            if (
              localStorage.getItem(
                `multipart_keys${sanitizeFilename(file.name)}`
              )
            ) {
              let previous_keys = JSON.parse(
                localStorage.getItem(
                  `multipart_keys${sanitizeFilename(file.name)}`
                )
              );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify(
                    updateObjectById(prev, assetId, {
                      percentUploaded:
                        previous_keys.length * chunkUploadPercentage +
                        chunkUploadPercentage,
                    })
                  )
                );

                setUploadProgress(
                  updateObjectById(prev, assetId, {
                    percentUploaded:
                      previous_keys.length * chunkUploadPercentage +
                      chunkUploadPercentage,
                  })
                );
              } else {
                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress([
                  {
                    asset_id: assetId,
                    percentUploaded: chunkUploadPercentage,
                    fileName: sanitizeFilename(file.name),
                  },
                ]);
              }

              // console.log(
              //   "previous_keys if",
              //   previous_keys,
              //   JSON.stringify([chunk_upload_response.data])
              // );

              const updatedKeysArray = [
                ...previous_keys,
                chunk_upload_response.data,
              ];

              localStorage.setItem(
                `multipart_keys${sanitizeFilename(file.name)}`,
                JSON.stringify(updatedKeysArray)
              );
            } else {
              // console.log(
              //   "previous_keys else",
              //   JSON.stringify([chunk_upload_response.data])
              // );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    ...prev,
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress((prev) => {
                  return [
                    ...prev,
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ];
                });
              } else {
                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress([
                  {
                    asset_id: assetId,
                    percentUploaded: chunkUploadPercentage,
                    fileName: sanitizeFilename(file.name),
                  },
                ]);
              }

              localStorage.setItem(
                `multipart_keys${sanitizeFilename(file.name)}`,
                JSON.stringify([chunk_upload_response.data])
              );
            }
          }
        } catch (err) {
          console.log("multipart_upload failed", err);
        }
      });

      await Promise.all(uploadPromises);

      // Handle successful chunk upload, update progress, etc.
    } catch (error) {
      // Handle errors, retry, or resume uploads
      console.log("get_multipart_id error", error);
    }
  };
  async function handleFileUpload(event) {
    setLoading(true);
    setUploadStarted(true);
    const file = event.target.files[0];
    let videoDuration;

    if (file.type !== "video/quicktime" && file.type !== "application/mxf") {
      if (!event.target.accept.split(",").includes(file.type)) {
        setAlertPopUp(true);
        return;
      }
    }
    if (file) {
      const video = document.createElement("video"); // Create a video element
      video.src = URL.createObjectURL(file); // Set the video source to the file URL

      // Wait for the metadata to be loaded
      video.addEventListener("loadedmetadata", () => {
        videoDuration = video.duration; // Get the duration of the video
        console.log("Video Duration:", videoDuration); // Print the video duration (in seconds)
      });
    }

    // Check if the file type matches the allowed format
    const allowedFormats = event.target.accept
      .split(",")
      .map((format) => format.trim());

    let allowedSampleRates = event.target.getAttribute("acceptedrates");
    allowedSampleRates =
      allowedSampleRates &&
      allowedSampleRates.split(",").map((format) => format.trim());

    let allowedChannels = event.target.getAttribute("allowedChannels");
    allowedChannels =
      allowedChannels &&
      allowedChannels.split(",").map((format) => format.trim());

    const isAllowedFormat = allowedFormats.includes(file.type);

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    let filesSampleRate = (audioContext.sampleRate / 1000).toFixed(1);

    let filesChannel = audioContext.destination.channelCount;

    const isAllowedsampleRate = allowedSampleRates.includes(
      `${filesSampleRate}KHz`
    );

    const isAllowedchannels = allowedChannels.includes(`${filesChannel}`);

    // if (!isAllowedFormat || !isAllowedsampleRate || !isAllowedchannels) {
    //   alert("File format is not allowed");
    //   setLoading(false);
    //   return; // Do not proceed with the upload
    // } else {

    // }

    if (file) {
      const updatedRows = rows.map((item) => {
        setAssetIds([...assetIds, assetId.toString()]);

        const updatedAssetIdsString = JSON.stringify([
          ...assetIds,
          assetId.toString(),
        ]);
        localStorage.setItem("assetIds", updatedAssetIdsString);
        if (item.id.toString() === assetId.toString()) {
          return {
            ...item,
            upload: "loading",
          };
        }
        return item;
      });

      setRows(updatedRows);

      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      const chunkSize = 1024 * 1024 * 15; // 15 MB chunk size
      const totalChunks = Math.ceil(file.size / chunkSize);
      const chunkBatchSize = 5; // Number of chunks to upload at a time
      let chunkIndex = 0;
      const chunkUploadPercentage = 100 / totalChunks;

      // Clearing if the previous upload keys present in localStorage

      if (
        localStorage.getItem(`multipart_keys${sanitizeFilename(file.name)}`)
      ) {
        localStorage.removeItem(`multipart_keys${sanitizeFilename(file.name)}`);
      }

      let multipart_upload_id;

      // MULTIPART UPLOAD ID REQUEST
      try {
        let getMultipartIdPostData = {
          client_id: _source?.client_id?.id,
          filename: sanitizeFilename(file.name),
          release_id: _id,
        };

        const get_multipart_id_response = await axios.post(
          `${environment.api.baseApi}/get_multipart_id`,
          getMultipartIdPostData,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              Accept: "application/json", // Specify the expected response format
              "Content-Type": "application/json", // Specify the request data format
            },
          }
        );

        if (get_multipart_id_response.status === 200) {
          // storing the multipart upload id
          multipart_upload_id = await get_multipart_id_response.data?.upload_id;

          while (chunkIndex < totalChunks) {
            const originalChunkIndex = chunkIndex;
            const remainingChunks = totalChunks - chunkIndex;
            const batchSize = Math.min(chunkBatchSize, remainingChunks);

            const batchChunks = [];

            for (let i = 0; i < batchSize; i++) {
              const start = chunkIndex * chunkSize;
              const end = Math.min((chunkIndex + 1) * chunkSize, file.size);
              const chunk = file.slice(start, end);
              batchChunks.push(chunk);
              chunkIndex++;
            }

            await uploadChunks(
              batchChunks,
              file,
              multipart_upload_id,
              originalChunkIndex,
              chunkUploadPercentage,
              assetId
            );

            // Optionally, you can update progress here
            if (chunkIndex < totalChunks) {
              // Implement a delay before the next batch (e.g., for better UI responsiveness)
              await new Promise((resolve) => setTimeout(resolve, 100));
            }
          }
          // COMPLETE MULTIPART Upload

          try {
            let eTagsArray = localStorage.getItem(
              `multipart_keys${sanitizeFilename(file.name)}`
            )
              ? JSON.parse(
                  localStorage.getItem(
                    `multipart_keys${sanitizeFilename(file.name)}`
                  )
                )
              : [];

            // console.log("etagsArray", eTagsArray);

            const postObj = {
              release_id: _id,
              client_id: _source?.client_id?.id,
              filename: sanitizeFilename(file.name),
              upload_id: `${multipart_upload_id}`,
              etags: eTagsArray,
              file_type: mediaType === "audio" ? "audio" : "video",
            };

            const complete_multipart_response = await axios.post(
              `${environment.api.baseApi}/complete_multipart_upload`,
              JSON.stringify(postObj),
              {
                headers: {
                  Authorization: "Bearer " + access_token,
                  "Content-Type": "application/json", // Specify the request data format
                },
              }
            );

            // console.log("MULTIPART_response: ", complete_multipart_response);

            if (complete_multipart_response.status === 200) {
              localStorage.removeItem(
                `multipart_keys${sanitizeFilename(file.name)}`
              );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                let filteredUploadProgress = prev?.filter(
                  (obj) => obj.asset_id !== assetId
                );

                if (filteredUploadProgress?.length > 0) {
                  localStorage.setItem(
                    "upload_progress",
                    JSON.stringify(filteredUploadProgress)
                  );

                  setUploadProgress(filteredUploadProgress);
                } else {
                  localStorage.removeItem("upload_progress");
                  setUploadProgress([]);
                  setUploadStarted(false);
                }
              }

              let url = sanitizeFilename(file.name);
              await setUrl(url);
              // await setOpenAssetsForm(true);
              setLoading(false);
              const mapTrackandAssetToReleaseCallback = () => {
                let params = {
                  current_page: "upload",
                  page_limit: "none",
                  filters: `[["release_id.keyword","must","","match","${props?.releaseById?._id}"]]`,
                };
                let paramsChangeTrack = {
                  current_page: "Change_request",
                };
                let IsChangedTrack;
                setLoading(true);

                props.dispatch(AssetsAction.requestAssets(params)).then(() => {
                  setLoading(false); // Reset loading after the action is complete

                  IsChangedTrack =
                    props.musiclabelRequestChangeTrack?.hits?.hits.find(
                      (item) => item?._source?.track_id === matchedAsset.id
                    );

                  const AfterpostCallback = (res) => {
                    const ReqChangReleaseCallBack = (response) => {
                      return handleTrackUpdate(response, res);
                    };
                    // Check if `res` is available
                    if (res) {
                      // Dispatch only if `res` is available
                      return props.dispatch(
                        MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
                          location?.state?.requestId,
                          paramsChangeTrack,
                          ReqChangReleaseCallBack
                        )
                      );
                    } else {
                      // If `res` is not available, directly execute the remaining code
                      return handleTrackUpdate(null, res);
                    }
                  };

                  const handleTrackUpdate = (response, res) => {
                    // This part will always execute after the `if` block (whether it ran or not)
                    let newReleaseTracks = [];

                    // Build the newReleaseTracks array based on current release tracks

                    if (response && response?._source?.change_request_tracks) {
                      response?._source?.change_request_tracks.forEach(
                        (item) => {
                          newReleaseTracks.push({
                            assets_id: item?.assets_id?.id, // Ensure the ID exists
                            track_id: item?.track_id,
                          });
                        }
                      );
                    } else if (
                      props.changeRequestReleaseById?._source
                        ?.change_request_tracks
                    ) {
                      props.changeRequestReleaseById._source.change_request_tracks.forEach(
                        (item) => {
                          newReleaseTracks.push({
                            assets_id: item?.assets_id?.id, // Ensure the ID exists
                            track_id: item?.track_id,
                          });
                        }
                      );
                    }
                    // Check for a matched track and update assets_id or add a new one
                    const matchedTrack = newReleaseTracks.find((item) => {
                      const trackIdToCompare = res?.id || IsChangedTrack?._id;
                      return item.track_id === trackIdToCompare;
                    });

                    if (matchedTrack) {
                      matchedTrack.assets_id = assetId;
                    } else {
                      newReleaseTracks.push({
                        assets_id: assetId,
                        track_id: res?.id || IsChangedTrack?._id,
                      });
                    }

                    // Prepare object for the PUT request
                    let put_obj = {
                      release_id: props?.releaseById?._id,
                      change_request_tracks: newReleaseTracks,
                    };

                    // Dispatch the PUT release action
                    return props
                      .dispatch(
                        MusicLabelsChangeRequestReleaseAction.requestPutMusicLabelsChangeRequestRelease(
                          JSON.stringify(put_obj),
                          props.changeRequestReleaseById?._id,
                          {}
                        )
                      )
                      .then(() => {
                        const param = {
                          current_page: "changeRequestForm",
                        };

                        // After successfully updating the release, fetch the latest release details
                        return props.dispatch(
                          MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
                            props.changeRequestReleaseById?._id,
                            param
                          )
                        );
                      })
                      .catch((error) => {
                        setAssetId(null); // Reset asset ID to initial state
                        setLoading(false); // Ensure loading is reset in case of an error
                        console.error("Error in handleTrackUpdate:", error);
                      });
                  };

                  if (!IsChangedTrack) {
                    // If no changed track, perform the POST action
                    let ParamsForTrack = {
                      track_id: matchedAsset.id,
                      change_request_release_id: location?.state?.requestId,
                    };

                    return props
                      .dispatch(
                        MusicLabelsChangeRequestTrackAction.requestPostMusicLabelsChangeRequestTrack(
                          ParamsForTrack,
                          paramsChangeTrack,
                          AfterpostCallback
                        )
                      )
                      .then(() => {
                        fetchChangeRequestTrack(location?.state?.requestId);
                      });
                  } else {
                    AfterpostCallback();
                  }
                });
              };

              let obj = {
                asset_url: url,
                asset_duration: videoDuration ? videoDuration : null,
              };

              if (url) {
                let params = { current_page: "upload", change_request: true };
                props
                  .dispatch(
                    AssetsAction.requestPutAssets(
                      JSON.stringify(obj),
                      assetId,
                      params
                    )
                  )
                  .then(() => {
                    mapTrackandAssetToReleaseCallback();
                  });
              }
            }
          } catch (error) {
            console.log("complete multipart upload failed", error);
          }
        }
      } catch (error) {
        console.log("multipart upload error: ", error);
      }

      // console.log("FILE", { file, chunks, totalChunks });
    }
  }

  const columns = [
    {
      // width: 20,
      flex: 1,
      field: "play",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingRight: { xl: "27px", lg: "22px", laptop: "18px" },
            paddingLeft: { xl: "20px", lg: "17px", laptop: "15px" },
            fontFamily: "Inter",
            fontSize: { xl: "16px", lg: "12px", laptop: "10px" },
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            // color: theme.palette.text.ReleaseDetailsTextColor,
            textTransform: "uppercase",
          }}
        >
          <PlayCircleIcon
            style={
              {
                // color: theme.palette.checkBox.Color,
              }
            }
            // color: theme.palette.background.icon
          />
        </Box>
      ),
      renderCell: (params) => {
        let foundChange;
        const foundChangeTrack =
          props.musiclabelRequestChangeTrack?.hits?.hits?.find(
            (item, index) => item._source?.track_id === params.row.id
          );
        if (foundChangeTrack) {
          foundChange =
            props.changeRequestReleaseById?._source?.change_request_tracks?.find(
              (item, index) => item.track_id === foundChangeTrack?._id
            );
        }

        const foundTrack = props.releaseById?._source?.tracks?.find(
          (item, index) => item.track_id.id === params.row.id
        );

        // const isPlaying = params.row.assetID === editRowsModel?.id;

        return (
          <IconButton
            sx={{
              paddingRight: { xl: "27px", lg: "22px", laptop: "18px" },
              paddingLeft: { xl: "20px", lg: "17px", laptop: "15px" },
              fontFamily: "Inter",
              fontSize: { xl: "16px", lg: "12px", laptop: "10px" },
              // width: "129px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAlign: "left",
              color: theme.palette.text.ReleaseDetailsTextColor,
              textTransform: "uppercase",
            }}
            onClick={(event) => {
              event.ignore = true;
              //   // setEdit(true);
              if (
                foundChange?.assets_id?.asset_url ||
                foundTrack?.assets_id?.asset_url
              ) {
                params &&
                  setEditRowsModel({
                    ...params.row,
                    typeofrelease: props.releaseById?._source?.type_of_release,
                    sl_no: params.row.sl_no,
                    asset_url:
                      foundChange?.assets_id?.asset_url ||
                      foundTrack?.assets_id?.asset_url,
                    assets_name:
                      foundChange?.assets_id?.assets_name ||
                      foundTrack?.assets_id?.assets_name,
                    id: foundChange?.assets_id?.id || foundTrack?.assets_id?.id,
                    file_name:
                      foundChange?.assets_id?.asset_url ||
                      foundTrack?.assets_id?.asset_url,
                    upload: "checked",
                    client_id: props.releaseById?._source?.client_id,
                  });
                setretriveAssetOpen(true);
              }
            }}
          >
            <PlayCircleIcon
              style={
                {
                  // color: theme.palette.checkBox.Color, // Change the color based on the playing state
                }
              }
            />
          </IconButton>
        );
      },
    },
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
      renderCell: (params, index) => {
        let play_link = params.row.title;
        play_link = play_link?.replace(/ /g, "-");

        let tunesUrl = `${environment.api.a3TunesApi}/${play_link}${
          params.row.duplication_number
            ? params.row.duplication_number === 0
              ? ""
              : "--" + params.row.duplication_number
            : ""
        }`;

        return (
          <>
            <Typography>{params.row ? params.row.sl_no : " "}</Typography>
            <IconButton
              title="Play Link url"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard(tunesUrl);
              }}
            >
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
            <Snackbar
              message="Play Link url to clipboard"
              sx={{ height: "70%" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={500}
              onClose={() => setCopied(false)}
              open={copied}
            />
          </>
        );
      },
    },
    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.primary_artists?.length ? "By " : ""}
                  {params?.row?.primary_artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.primary_artists?.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      field: "music/album",
      headerName: "Version",
      width: 150,
      hide: true,
      flex: 1,
    },
    {
      field: "Upload_Asset",
      headerName: "Asset",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        let foundChange;
        const foundChangeTrack =
          props.musiclabelRequestChangeTrack?.hits?.hits?.find(
            (item) => item._source?.track_id === params.row.id
          );
        if (foundChangeTrack) {
          foundChange =
            props.changeRequestReleaseById?._source?.change_request_tracks?.find(
              (item, index) => item.track_id === foundChangeTrack?._id
            );
        }
        const foundTrack = props.releaseById?._source?.tracks?.find(
          (item, index) => item.track_id.id === params.row.id
        );

        let progress = uploadProgress?.filter(
          (uploadingItem) => matchedAsset?.id === params.row?.id
        )[0]?.percentUploaded;

        let access_token = localStorage.getItem("Auth")
          ? JSON.parse(localStorage.getItem("Auth")).access_token
          : "";
        return (
          <>
            {foundChange?.assets_id?.asset_url ? (
              <>
                <CustomTooltip
                  title={
                    <div>
                      <strong>{"Previous Value"}</strong>
                      <div>{foundTrack?.assets_id?.asset_url}</div>
                    </div>
                  }
                  arrow
                  placement="right-end"
                  enterDelay={300}
                  followCursor={true}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={1}
                      color={"error"}
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: { laptop: "7px", xl: "11px" },
                          height: { laptop: "12px" },
                          width: { laptop: "12px" },
                          padding: 0,
                          minWidth: 0,
                        },
                      }}
                    >
                      <CheckCircleOutlinedIcon
                        sx={{
                          color: theme.palette.text.createaccountcolor,
                          height: "17px",
                          width: "17px",
                          cursor: "pointer",
                        }}
                      />
                    </Badge>
                  </Box>
                </CustomTooltip>
                <Box sx={{ paddingLeft: "10px" }}>
                  <DeleteForeverIcon
                    sx={{
                      fontSize: { xl: "16px", laptop: "14px" },
                    }}
                    onClick={() => {
                      setConfirmDialog(true);
                      setDeleteId(foundChangeTrack._id);
                    }}
                  />
                </Box>
                <Box sx={{ paddingLeft: "10px" }}>
                  {downloadLoading.includes(
                    extractFileName(foundChange?.assets_id?.asset_url)
                  ) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FileDownloadOutlinedIcon
                      sx={{
                        fontSize: { xl: "16px", laptop: "14px" },
                      }}
                      onClick={() => {
                        foundChangeTrack?._source?.track_id === params.row.id &&
                          setDownloadLoading((prev) => [
                            ...prev,
                            extractFileName(foundChange?.assets_id?.asset_url),
                          ]);

                        let url = `${environment.api.retriveTrackAsset}?path=${foundChange?.assets_id?.asset_url}&release_id=${props?.releaseById?._id}&client_id=${params?.row?.client_id?.id}&token=${access_token}`;
                        DownloadFile(
                          url,
                          extractFileName(foundChange?.assets_id?.asset_url)
                        );
                      }}
                    />
                  )}
                </Box>
              </>
            ) : (
              <>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setOpenAssetsForm(true);
                    setMediaType(params.row?.primary_track_type);
                    setMatchedAsset(params?.row);
                  }}
                >
                  {progress ? (
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        size={35}
                        variant="determinate"
                        value={progress}
                        sx={{ color: theme.palette.primary.main }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.primary"
                          fontSize={12}
                        >
                          {`${Math.round(progress)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <FileUploadOutlinedIcon
                      sx={{
                        fontSize: "17px",
                      }}
                    />
                  )}
                </IconButton>
                <Box sx={{ paddingLeft: "10px" }}>
                  <DeleteForeverIcon
                    sx={{
                      fontSize: { xl: "16px", laptop: "14px" },
                      color: "#cccccc",
                    }}
                    onClick={() => {}}
                  />
                </Box>
                <Box sx={{ paddingLeft: "10px" }}>
                  {!downloadLoading.includes(
                    extractFileName(foundTrack?.assets_id?.asset_url)
                  ) ? (
                    <FileDownloadOutlinedIcon
                      sx={{
                        fontSize: { xl: "16px", laptop: "14px" },
                      }}
                      onClick={() => {
                        setDownloadLoading((prev) => [
                          ...prev,
                          extractFileName(foundTrack?.assets_id?.asset_url),
                        ]);
                        let url = `${environment.api.retriveTrackAsset}?path=${foundTrack?.assets_id?.asset_url}&release_id=${props?.releaseById?._id}&client_id=${params?.row?.client_id?.id}&token=${access_token}`;
                        DownloadFile(
                          url,
                          extractFileName(foundTrack?.assets_id?.asset_url)
                        );
                      }}
                    />
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Box>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 200,
      renderCell: (params) => {
        return `${params.row.genre?.genre_name} > ${params.row.sub_genre_name?.subgenre_name}`;
      },
      flex: 2,
    },
    {
      field: "isrc",
      headerName: "ISRC",
      width: 150,
      flex: 2,
    },
    {
      field: "plan_id",
      headerName: "Price tier",
      width: 150,
      renderCell: (params) => {
        return params.row.plan_id?.plan_name;
      },
      flex: 2,
    },
    {
      field: "Changes",
      headerName: "Changes",
      width: 150,
      renderCell: (params) => {
        const handleClick = () => {
          setOpen((prev) => !prev);
        };

        const handleClose = (event) => {
          if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
          }
          setOpen(false);
        };

        return (
          <ThreeDotsPopper
            options={[
              {
                option_name: "Edit Track",
                page: "changeRequests",
                action: () => {
                  setTrackPost(true);
                  setEditRowsModel(params?.row);
                  setEdit(
                    ChangeRequestTracksArray?.some(
                      (item) => item.track_id === params?.row?.id
                    )
                  );
                  setChangTrackId([
                    params?.row?.id,
                    location?.state?.requestId,
                  ]);
                },
              },
            ]}
          >
            <Badge badgeContent={params?.row?.ChangedErrors} color="secondary">
              <MoreVertIcon
                style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
              />
            </Badge>
          </ThreeDotsPopper>
        );
      },
      flex: 2,
    },
  ];

  return (
    <Box width={"100%"}>
      <DeliveredTrackDetailsDialog
        open={openTrackDialog}
        onClose={() => {
          setOpenTrackDialog(false);
          setTrackData(null);
        }}
        trackData={trackData}
      />
      <RetriveAssetDialog
        open={retriveAssetOpen}
        onClose={() => setretriveAssetOpen(false)}
        RetriveData={editRowsModel}
        releaseId={props?.releaseById?._id}
        // edit={edit}
      />
      <AssetsPost
        url={url}
        // releaseId={props.releaseById._id}
        releaseId={props?.releaseById?._id}
        open={openAssetsForm}
        setAssetId={setAssetId}
        onSubmit={() => {
          //setLoading(true);
          let params = {
            page_limit: "none",
            filters: `[["release_id.keyword","must","","match","${props.releaseById?._id}"]]`,
            current_page: "upload",
          };
          let param = {
            order_by: `[["modified_on","desc"]]`,
            page_limit: 15,
            current_page: "upload",
          };

          props.dispatch(AssetsAction.requestAssets(params));

          if (mediaType === "audio") {
            if (audioRef.current) {
              audioRef.current.value = null;
              audioRef?.current?.click();
            }
          } else if (mediaType === "video") {
            if (videoRef.current) {
              videoRef.current.value = null;
              videoRef?.current?.click();
            }
          }
        }}
        onClose={() => setOpenAssetsForm(false)}
      />

      <>
        <Typography
          id={`asset-audio/wav-upload`}
          component={"input"}
          ref={audioRef}
          type="file"
          //multiple
          accept={`audio/wav,audio/x-wav,aif,aiff,flac`}
          acceptedrates={`44.1KHz, 48KHz, 88.2KHz, 96KHz`}
          allowedChannels={`1 , 2`}
          onChange={handleFileUpload}
          sx={{ display: "none" }}
        ></Typography>
        <Typography
          id={`asset-video/wav-upload`}
          component={"input"}
          ref={videoRef}
          type="file"
          //multiple
          accept=".mov, .mxf"
          acceptedrates={`44.1KHz, 96KHz, `}
          allowedChannels={`1 , 2`}
          onChange={handleFileUpload}
          sx={{ display: "none" }}
        ></Typography>
      </>

      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        // className="bg-warning"
        alignItems={"center"}
        justifyContent="space-between"
        my={0.5}
      >
        <Grid item>
          <Typography component={"span"}>
            {formatName(`${rowsDatagrid?.length}`)} Results found
          </Typography>
        </Grid>

        <Grid item></Grid>
      </Grid>

      <Dialog open={Boolean(confirmDialog)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete the uploaded Asset ?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              deleteFile(deleteID);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>
      {navigator.onLine ? (
        <Grid
          display="flex"
          justifyContent="center"
          container
          item
          lg={12}
          md={12}
          sm={12}
        >
          <LoadingIndicator isActive={props.isRequesting}>
            {props.track.hits ? (
              <DataGridFunction
                curser={"pointer"}
                columns={columns}
                rows={rowsDatagrid}
                rowHeight={60}
                height={rowsDatagrid.length * 60 + 100}
              />
            ) : null}
          </LoadingIndicator>

          <ChangeRequestTrackPost
            plan={plan}
            primary_artist={primary_artist}
            featuring_artists={featuring_artists}
            isTrackError={isTrackError}
            submissionRedirect={submissionRedirect}
            releasedata={_source ? _source : null}
            returnLyricsObj={returnLyricsObj}
            isRequesting={props.isRequesting}
            changTrackId={changTrackId}
            open={trackPost}
            onSubmit={() => {
              fetchChangeRequestTrack(editRowsModel.id);
            }}
            onGet={(name) => {
              let params = {
                order_by: `[["created_on","desc"]]`,
                page_limit: "none",
                current_page: "tracks",
              };

              if (name === "primary_actor") {
                fetchActorFilter(params);
              } else if (name === "primary_actress") {
                fetchActressFilter(params);
              } else if (name === "director") {
                fetchDirectorFilter(params);
              }
            }}
            onClose={() => {
              setTrackPost(false);
              setEdit(false);
            }}
            projectEditData={editRowsModel}
            edit={edit}
            artistArray={artistArray}
            release_id={localStorage.getItem("id")}
            AssetAndTrackList={AssetAndTrackList}
            nameslist={nameslist}
            subgenrelist={subgenrelist ?? []}
            genreList={genreList ?? []}
            planList={planList}
            actorarray={actorarray}
            actressarray={actressarray}
            directorarray={directorarray}
            setIsTrackError={setIsTrackError}
            setSelectedGenre={setSelectedGenre}
            currentTracksLength={props.track?.hits?.length}
            changeRequestTrackById={ChangeRequestTracksArray?.find(
              (item) => item.track_id === changTrackId[0]
            )}
            musiclabelRequestChangeTrack={props?.musiclabelRequestChangeTrack}
            track={props.track}
            status={props.status}
          />
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(ChangeRequestReleaseTracks);
const UploadSection = ({
  accept,
  icon,
  title,
  handleFileUpload,
  OpenDailog,
  format,
  type,
  disabled,
}) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        color: theme.palette.background.primary,
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Grid
        item
        sx={{
          width: {
            xl: "192px",
            lg: "200px",
            md: "100px",
            sm: "100px",
            xs: "100px",
          },
          height: {
            xl: "192px",
            lg: "200px",
            md: "100px",
            sm: "100px",
            xs: "100px",
          },
          backgroundColor: theme.palette.background.paper,
          border: `1px dashed ${theme.palette.borderColor.uploadButtons}`,
        }}
      >
        <Typography
          component={"label"}
          // htmlFor={`asset-${accept}-upload`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            cursor: disabled ? "not-allowed" : "pointer",
            marginY: 1,
          }}
        >
          <Typography
            onClick={() => {
              if (!disabled) {
                OpenDailog(icon);
              }
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {icon === "audio" ? (
              <LibraryMusicIcon
                sx={{
                  fontSize: {
                    lg: "40px",
                    md: "30px",
                    sm: "20px",
                  },
                  color: theme.palette.primary.light,
                }}
              />
            ) : (
              <VideocamIcon
                sx={{
                  fontSize: {
                    lg: "40px",
                    md: "30px",
                    sm: "20px",
                  },
                  color: theme.palette.primary.light,
                }}
              />
            )}

            <Typography
              component={"span"}
              sx={{
                minHeight: {
                  lg: "100px",
                  md: "50px",
                  sm: "50px",
                },
                padding: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: {
                  lg: "16px",
                  md: "13px",
                  sm: "13px",
                },
              }}
            >
              <span>{title}</span>
            </Typography>
          </Typography>
        </Typography>
      </Grid>

      <UploadPopper format={format} type={type} disabled={disabled} />
    </Grid>
  );
};
