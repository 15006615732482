import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import MusicLabelsChangeRequestTrackModel from "./musicLabelsChangeRequestTrackModel/MusicLabelsChangeRequestTrackGetModel";
import MusicLabelsChangeRequestTrackPutModel from "./musicLabelsChangeRequestTrackModel/MusicLabelsChangeRequestTrackPutModel";
import MusicLabelsChangeRequestTrackPostModel from "./musicLabelsChangeRequestTrackModel/MusicLabelsChangeRequestTrackPostModel";
import MusicLabelsChangeRequestTrackByIdModel from "./musicLabelsChangeRequestTrackModel/MusicLabelsChangeRequestTrackByIdModel";
export default class MusicLabelsChangeRequestTrackEffect {
  // Get method for the
  static async requestMusicLabelsChangeRequestTrack(params, callBack) {
    const endpoint = environment.api.musiclabelRequestChangeTrack;
    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestTrackModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestPutMusicLabelsChangeRequestTrack(data, id, params) {
    const endpoint = environment.api.musiclabelRequestChangeTrack + "/" + id;
    return EffectUtility.putToModel(
      MusicLabelsChangeRequestTrackPutModel,
      endpoint,
      data,
      params
    );
  }

  static async requestPostMusicLabelsChangeRequestTrack(
    data,
    params,
    callBack
  ) {
    const endpoint = environment.api.musiclabelRequestChangeTrack;
    let response = EffectUtility.postToModel(
      MusicLabelsChangeRequestTrackPostModel,
      endpoint,
      data,
      params,
      callBack
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestMusicLabelsChangeRequestTrackById(id, params) {
    const endpoint = environment.api.musiclabelRequestChangeTrack + "/" + id;
    return EffectUtility.getToModel(
      MusicLabelsChangeRequestTrackByIdModel,
      endpoint,
      params
    );
  }

  static async requestDeleteMusicLabelsChangeRequestTrack(id, callback) {
    const endpoint = environment.api.musiclabelRequestChangeTrack + "/" + id;
    let response = EffectUtility.deleteToModel(
      MusicLabelsChangeRequestTrackPostModel,
      endpoint
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
