import BaseReducer from "../../utilities/BaseReducer";
import MusicLabelsChangeRequestReleaseAction from "./MusicLabelsChangeRequestReleaseAction";

export default class musicLabelsChangeRequestReleaseReducer extends BaseReducer {
  initialState = {
    musiclabelRequestChangeRelease: [],
  };
  [MusicLabelsChangeRequestReleaseAction.REQUEST_MUSICLABELSCHANGEREQUESTRELEASE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      musiclabelRequestChangeRelease: action.payload,
    };
  }
}
