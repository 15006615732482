import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  IconButton,
  RadioGroup,
  Radio,
  createFilterOptions,
  Dialog,
  Paper,
  DialogActions,
  Badge,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import { useLocation, useNavigate } from "react-router";
import { connect } from "react-redux";
import environment from "environment";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReleasesAction from "../../../stores/releases/ReleasesAction";
import {
  inputProps,
  menuProps,
} from "../../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import { theme } from "../../App";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import GenreAction from "../../../stores/genre/GenreAction";
import SubGenreAction from "../../../stores/subgenre/SubGenreAction";
import ArtistAction from "../../../stores/artist/ArtistAction";
import LabelAction from "../../../stores/label/LabelAction";
import StreamPlatformAction from "../../../stores/streamPlatform/StreamPlatformAction";
import StatusAction from "../../../stores/status/StatusAction";
import LanguageAction from "../../../stores/language/LanguageAction";
import { formatName } from "../../../components/reUsedFuntions/ReUsedFunctions";
import PlanAction from "../../../stores/plan/PlanAction";
import MuiDatePicker from "../../../components/datePicker/MuiDatePicker";
import InfoIcon from "@mui/icons-material/Info";
import UploadPopper from "../../../components/poppers/UploadPopper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import AutocompleteDialoge from "../../../components/autoCompleteComponent/AutocompleteDialoge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import MusicLabelsChangeRequestReleaseAction from "../../../stores/musicLabelsChangeRequestRelease/MusicLabelsChangeRequestReleaseAction";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, styled } from "@material-ui/core";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    GenreAction.REQUEST_GENRE_FILTER,
    LabelAction.REQUEST_LABELS_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    PlanAction.REQUEST_PLAN,
  ]),
  finalReleaseById: state.finalReleaseById.finalReleaseById || {},
  genreFilter: state.genreFilter.genreFilter || [],
  labelFilter: state.labelFilter.labelFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  artistFilter: state.artistFilter.artistFilter || [],
  stream_platform_filter:
    state.stream_platform_filter.stream_platform_filter || [],
  statusFilter: state.statusFilter.statusFilter || [],
  language: state.language.language || [],
  plan: state.plan.plan || [],
  changeRequestReleaseById:
    state.changeRequestReleaseById.changeRequestReleaseById || {},
});

const useStyles = makeStyles(() => ({
  scrollbar: {
    overflow: "auto",
    overflowX: "hidden",
    borderRadius: "6px",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b0d2da",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#85bac6",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));

const ReleaseChangeForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const formRef = useRef(null);
  const boxRef = useRef(null);

  const classes = useStyles();

  function fetchChangeRequestId(requestId) {
    const params = {
      current_page: "changeRequestForm",
    };
    props.dispatch(
      MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
        requestId,
        params
      )
    );
  }

  function fetchGenreFilter(param) {
    props.dispatch(GenreAction.requestGenreFilter(param));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  function fetchSubgenreFilter(param) {
    props.dispatch(SubGenreAction.requestSubgenreFilter(param));
  }

  function fetchartistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchPlatformsFilter(param) {
    props.dispatch(StreamPlatformAction.requestPlatformFilter(param));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }

  function fetchPlan(params) {
    props.dispatch(PlanAction.requestPlan(params));
  }

  let AskToGenerateUPCEAN = ["Yes", "No"];
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "changeRequestForm",
    };

    let id = location.state.requestId;

    const label_param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "changeRequestForm",
    };

    fetchGenreFilter(param);
    fetchLabelFilter(label_param);
    fetchSubgenreFilter(param);
    fetchartistFilter(param);
    fetchPlatformsFilter(param);
    fetchStatusFilter(param);
    fetchPlan(param);
    fetchlanguageFilter(param);
    // fetchChangeRequestId(id);
  }, [localStorage.getItem("change_request_id")]);

  let formProps = {};
  const { _id, _source } = props?.finalReleaseById;
  const { _source: _sourceFromChangeRequest } = props?.changeRequestReleaseById;

  function areValuesEqual(val1, val2) {
    if (val1 === val2) {
      return true; // Handles primitive equality and identical references
    }

    if (typeof val1 !== typeof val2) {
      return false; // Different types
    }

    if (Array.isArray(val1) && Array.isArray(val2)) {
      // Compare arrays
      return (
        val1.length === val2.length &&
        val1.every((item, index) => areValuesEqual(item, val2[index]))
      );
    }

    if (typeof val1 === "object" && val1 !== null && val2 !== null) {
      // Compare objects
      const keys1 = Object.keys(val1);
      const keys2 = Object.keys(val2);
      return (
        keys1.length === keys2.length &&
        keys1.every(
          (key) =>
            val2.hasOwnProperty(key) && areValuesEqual(val1[key], val2[key])
        )
      );
    }

    return false; // If none of the above cases matched, the values are different
  }

  const isKeyPresent = (key) => {
    let obj1 = _sourceFromChangeRequest;
    let obj2 = _source;

    if (obj1?.hasOwnProperty(key)) {
      const value1 = obj1[key];
      const value2 = obj2?.[key];

      // Check if values are not equal using deep comparison
      return !areValuesEqual(value1, value2);
    }

    return false; // Key is not present in obj1
  };

  if (props?.finalReleaseById) {
    Object.assign(
      formProps,
      { id: _id },
      {
        ask_to_generate_upc:
          _source?.ask_to_generate_upc !== null
            ? _source?.ask_to_generate_upc
              ? "Yes"
              : "No"
            : "Yes",
      },
      { thumbnail_url: _source?.thumbnail_url },
      {
        title: isKeyPresent("title")
          ? _sourceFromChangeRequest?.title
          : _source?.title,
      },
      {
        artists: isKeyPresent("artists")
          ? _sourceFromChangeRequest && _sourceFromChangeRequest.artists
            ? _sourceFromChangeRequest.artists
            : [""]
          : _source && _source.artists
          ? _source.artists
          : [""],
      },
      {
        featuring_artists: isKeyPresent("featuring_artists")
          ? _sourceFromChangeRequest &&
            _sourceFromChangeRequest.featuring_artists
            ? _sourceFromChangeRequest.featuring_artists
            : [""]
          : _source && _source.featuring_artists
          ? _source.featuring_artists
          : [""],
      },
      {
        genre_type: isKeyPresent("genre_type")
          ? _sourceFromChangeRequest?.genre_type
          : _source?.genre_type,
      },
      {
        copyrighted_to: isKeyPresent("copyrighted_to")
          ? _sourceFromChangeRequest?.copyrighted_to
          : _source?.copyrighted_to,
      },

      {
        sub_genre_type: isKeyPresent("sub_genre_type")
          ? _sourceFromChangeRequest?.sub_genre_type
          : _source?.sub_genre_type,
      },
      { label: _source?.label },
      {
        plan_id: isKeyPresent("plan_id")
          ? _sourceFromChangeRequest
            ? _sourceFromChangeRequest.plan_id
              ? _sourceFromChangeRequest.plan_id.id
                ? _sourceFromChangeRequest.plan_id.id
                : ""
              : ""
            : ""
          : _source
          ? _source.plan_id
            ? _source.plan_id.id
              ? _source.plan_id.id
              : ""
            : ""
          : "",
      },

      {
        published_by: isKeyPresent("published_by")
          ? _sourceFromChangeRequest?.published_by
          : _source?.published_by,
      },

      {
        production_year: isKeyPresent("production_year")
          ? _sourceFromChangeRequest && _sourceFromChangeRequest.production_year
            ? _sourceFromChangeRequest.production_year
            : currentYear
          : _source && _source.production_year
          ? _source.production_year
          : currentYear,
      },
      { UPC_EAN: _source?.UPC_EAN },
      { format: _source?.format },
      {
        producer_catalogue_number: isKeyPresent("producer_catalogue_number")
          ? _sourceFromChangeRequest?.producer_catalogue_number
          : _source?.producer_catalogue_number,
      },
      {
        physical_release_date: isKeyPresent("physical_release_date")
          ? _sourceFromChangeRequest?.physical_release_date
          : _source?.physical_release_date,
      },
      { status: _source?.status },
      { tracks: _source?.tracks },
      { type_of_release: _source?.type_of_release },
      {
        "music/album": isKeyPresent("music/album")
          ? _sourceFromChangeRequest &&
            _sourceFromChangeRequest["music/album"] &&
            _sourceFromChangeRequest["music/album"]?.length !== 0
            ? _sourceFromChangeRequest["music/album"]
            : [""]
          : _source &&
            _source["music/album"] &&
            _source["music/album"]?.length !== 0
          ? _source["music/album"]
          : [""],
      },
      {
        meta_language: isKeyPresent("meta_language")
          ? _sourceFromChangeRequest?.meta_language
          : _source?.meta_language,
      },
      {
        phonographic_copyright_year: isKeyPresent("phonographic_copyright_year")
          ? _sourceFromChangeRequest &&
            _sourceFromChangeRequest.phonographic_copyright_year
            ? _sourceFromChangeRequest.phonographic_copyright_year
            : currentYear
          : _source && _source.phonographic_copyright_year
          ? _source.phonographic_copyright_year
          : currentYear,
      },
      {
        copyright_year: isKeyPresent("copyright_year")
          ? _sourceFromChangeRequest && _sourceFromChangeRequest.copyright_year
            ? _sourceFromChangeRequest.copyright_year
            : currentYear
          : _source && _source.copyright_year
          ? _source.copyright_year
          : currentYear,
      }
    );
  }

  const [openCoverPicUploader, setOpenCoverPicUploader] = useState(false);

  const releaseTypeList = [
    { id: "audio", name: "audio" },
    { id: "video", name: "video" },
  ];

  let formatArray = ["single", "EP", "album"];

  const [highImage, sethighImage] = useState("");
  const [lowImage, setLowImage] = useState("");

  useEffect(() => {
    if (!props.isRequesting) {
      setLowImage(() => {
        !props.isRequesting &&
          formProps &&
          formProps.thumbnail_url &&
          RetrieveLowImageUrl(formProps.thumbnail_url);
      });
    }
  }, [!props.isRequesting]);

  const startYear = 0;
  const yearArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  let initial = {};

  let artistArray = [];
  formProps && formProps.artists && formProps.artists.length === 0
    ? artistArray.push("")
    : formProps?.artists?.map((item) => {
        artistArray.push({
          artist_id: item?.artist_id?.id,
          artist_name: item?.artist_id?.artist_name,
          stream_platforms: item?.artist_id?.stream_platforms,
        });
      });

  // artist list for primaray and featuring artist dropdowns
  let artistList = [];
  if (props.artistFilter.hits) {
    props.artistFilter.hits.hits.forEach((value, index) => {
      artistList.push({
        key: index,
        artist_id: value._id,
        artist_name: value._source.artist_name,
        stream_platforms: value._source.stream_platforms,
      });
    });
  }

  const [selectedgenre, setSelectedGenre] = useState(null);
  let genreList = [];
  if (props.genreFilter.hits) {
    props.genreFilter.hits.hits.forEach((value) => {
      genreList.push({
        id: value._id,
        name: value._source.genre_name,
      });
    });
  }

  let subgenrelist = [];

  if (props.subgenreFilter.hits) {
    props.subgenreFilter.hits?.hits
      ?.filter((item) => item?._source?.genre?.id === parseInt(selectedgenre))
      .map((item) => {
        return subgenrelist.push({
          id: item._id,
          name: item._source?.subgenre_name,
        });
      });
  }

  let labelList = [];
  if (props.labelFilter.hits) {
    props.labelFilter.hits.hits.forEach((value) => {
      labelList.push({
        id: value._id,
        name: value._source.label_name,
      });
    });
  }

  let nameslist = [];

  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      // const name = item.name;
      nameslist.push({ id: key, name: item.name });
    }
  }

  let featuringArtistArray = [];
  formProps &&
  formProps.featuring_artists &&
  formProps.featuring_artists.length === 0
    ? featuringArtistArray.push("")
    : formProps?.featuring_artists?.map((item) => {
        featuringArtistArray.push({
          artist_id: item?.artist_id?.id,
          artist_name: item?.artist_id?.artist_name,
          stream_platforms: item?.artist_id?.stream_platforms,
        });
      });

  let allPlatforms = [];
  if (props.stream_platform_filter.hits) {
    props.stream_platform_filter.hits.hits.forEach((value) => {
      allPlatforms.push({
        stream_platform_id: value._id,
        stream_platform_name: value._source.stream_platform_name,
      });
    });
  }

  if (formProps) {
    initial = {
      thumbnail_url: formProps.thumbnail_url,
      title: formProps.title,
      "music/album": formProps["music/album"],
      artists: artistArray,
      featuring_artists: featuringArtistArray,
      genre_type:
        formProps && formProps.genre_type
          ? {
              name: formProps.genre_type?.genre_name ?? "",
              id: formProps.genre_type?.id ?? "",
            }
          : null,
      copyrighted_to: formProps.copyrighted_to,
      various_artist: false,
      sub_genre_type:
        formProps && formProps.genre_type
          ? {
              name: formProps.sub_genre_type?.subgenre_name ?? "",
              id: formProps.sub_genre_type?.id ?? "",
            }
          : null,
      label: formProps.label?.id ?? "",
      plan_id: formProps.plan_id,
      published_by: formProps.published_by,
      physical_release_date: formProps.physical_release_date,
      production_year: formProps.production_year ?? "",
      UPC_EAN: formProps.UPC_EAN,
      ask_to_generate_upc: formProps.ask_to_generate_upc,
      format: formProps.format ?? "",
      producer_catalogue_number: formProps.producer_catalogue_number,
      status: formProps.status?.id ?? "",
      type_of_release: formProps.type_of_release,
      copyright_year: formProps.copyright_year ?? "",
      phonographic_copyright_year: formProps.phonographic_copyright_year ?? "",
      meta_language: formProps.meta_language?.meta_language ?? "",
    };
  } else {
    initial = {
      title: "",
      "music/album": [""],
      artists: [""],
      featuring_artists: [],
      genre_type: null,
      copyrighted_to: "",
      various_artist: false,
      sub_genre_type: null,
      label: "",
      plan_id: "",
      status: "",
      published_by: "",
      physical_release_date: "",
      production_year: new Date().getFullYear(),
      UPC_EAN: "",
      ask_to_generate_upc: "Yes",
      format: "",
      producer_catalogue_number: "",
      thumbnail_url: "",
      type_of_release: "",
      copyright_year: `${new Date().getFullYear()}`,
      phonographic_copyright_year: `${new Date().getFullYear()}`,
      meta_language: "",
    };
  }

  let planList = [];
  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });

    if (initial.format == "single")
      planList = planList.filter((plan) => /T1|T2|T3/.test(plan.name));
  }

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(key)) {
      event.preventDefault();
    }
  };

  const handleNumericKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9]*$/; // Only allow numbers
    if (!numericRegex.test(key)) {
      event.preventDefault(); // Prevent non-numeric input
    }
  };

  function Required() {
    return (
      <>
        <span style={{ color: "red" }}>Required</span>{" "}
        <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  function RequiredText() {
    return (
      <p
        style={{
          color: "red",
          fontSize: "14px",
          marginTop: "-8px",
          marginLeft: "-10px",
        }}
      >
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </p>
    );
  }
  const [loading, setLoading] = useState(false);
  const [lowImageLoader, setlowImageLoader] = useState(false);
  const [highImageLoader, sethighImageLoader] = useState(false);

  const handleFileUpload = (value, setFieldValue, event) => {
    const MAX_FILE_SIZE_MB = 5;
    let formData = new FormData();

    const fileSizeMB = event.target?.files[0]?.size / (1024 * 1024); // Convert bytes to MB

    const image = new Image();
    const reader = new FileReader();

    reader.onloadend = function () {
      image.src = reader.result;

      image.onload = function () {
        const width = this.width;
        const height = this.height;

        if (fileSizeMB <= MAX_FILE_SIZE_MB) {
          if (width === 3000 && height === 3000) {
            sethighImageLoader(true);

            fetch(
              `${environment.api.fileUpload}/add?current_page=releaseInfo&release_id=${formProps.id}&thumbnail=true`,
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + access_tocken,
                },
                body: formData,
              }
            )
              .then((res) => res.json())
              .then((response) => {
                if (response?.status === "success") {
                  setFieldValue("thumbnail_url", response.url);
                  RetrieveHighImageUrl(response.url);
                } else {
                  sethighImageLoader(false);
                }
              });
          } else {
            // Image doesn't meet the required dimensions, handle the error.
            alert("Please select a 3000x3000 pixel image.");
          }
        } else {
          // Image doesn't meet the required dimensions, handle the error.
          alert(`Please select a file less than ${MAX_FILE_SIZE_MB}MB.`);
        }
      };
    };

    if (value) {
      reader.readAsDataURL(value);
    }

    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
  };

  function RetrieveLowImageUrl(thumbnail_url) {
    setlowImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    // Low Quality Image

    let lowQualityImage = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=releaseInfo&path=thumbnail/${thumbnail_url}&release_id=${formProps.id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setlowImageLoader(false);
        lowQualityImage = URL.createObjectURL(blob);

        setLowImage(lowQualityImage);
      });
  }

  // Hight Quality Image
  function RetrieveHighImageUrl(thumbnail_url) {
    sethighImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let highQualityImage = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=releaseInfo&path=${thumbnail_url}&release_id=${formProps.id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        sethighImageLoader(false);
        highQualityImage = URL.createObjectURL(blob);
        sethighImage(highQualityImage);
      });
  }

  useEffect(() => {
    if (openCoverPicUploader) {
      sethighImage(() => {
        !props.isRequesting &&
          formProps &&
          formProps.thumbnail_url &&
          RetrieveHighImageUrl(formProps.thumbnail_url);
      });
    }
  }, [openCoverPicUploader]);

  const artistFieldRef = useRef("");
  const primaryArtistFieldRef = useRef(null);
  const featuringArtistFieldRef = useRef(null);

  const CustomOption = ({ option }) => (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Typography component={"p"}>
        {/* {option.artist_name} */}
        {option.hasOwnProperty("artist_name") ? option.artist_name : ""}
      </Typography>
      <Typography component={"div"} className="d-flex">
        {option.stream_platforms?.map((item) => (
          <>
            <Typography
              sx={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                marginX: 1,
              }}
            >
              {item.stream_platform_artist_id &&
              item.stream_platform_id === "1" ? (
                <FontAwesomeIcon icon={faSpotify}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "2" ? (
                <FontAwesomeIcon icon={faApple}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "3" ? (
                <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
              ) : null}
            </Typography>
          </>
        ))}
      </Typography>
    </Box>
  );

  const ArtistSelectDailoge = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
        disabled,
        title,
      },
      ref
    ) => {
      let alreadyEdited = false;

      if (isKeyPresent(name)) {
        alreadyEdited = true;
      }

      const returnOldValue2 = (key, index) => {
        if (
          !_source ||
          typeof _source !== "object" ||
          !_source.hasOwnProperty(key)
        ) {
          return undefined; // Return undefined if key is not found or _source is invalid
        }

        const value = _source[key];

        return value[index]?.artist_id?.artist_name ?? null;
      };

      return (
        <FieldArray name={name}>
          {({ insert, remove, push }) => (
            <Box sx={{ width: "100%" }}>
              {Array.isArray(values[name]) &&
                values[name].map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    gap={3}
                    justifyContent="center"
                    //alignItems={"flex-start"}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                      mt={1.5}
                    >
                      {index === 0 && (
                        <Typography>
                          {label} {required && "*"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={8} md={8} sm={8} my={0.5}>
                      <Grid lg={12} md={12} sm={12} item container>
                        <Grid
                          item
                          xs={
                            values[name][index] || values[name][index - 1]
                              ? 10
                              : 12
                          }
                        >
                          <Tooltip title={title} arrow placement="right-end">
                            <Field
                              width="100%"
                              style={{
                                sx: "100%",
                              }}
                              // name={name}
                              name={`${name}[${index}]`}
                              size="small"
                              component={AutocompleteDialoge}
                              innerRef={ref}
                              remove={() => {
                                values[name].length !== 1 && remove(index);
                              }}
                              fetchartistFilter={fetchartistFilter}
                              values={values}
                              options={options ? options : []}
                              textFieldProps={{
                                variant: "outlined",
                                placeholder: placeholder,
                              }}
                              renderOption={(props, option) => {
                                if (option && option.artist_name) {
                                  return (
                                    <li {...props} key={item.artist_id}>
                                      <CustomOption option={option} />
                                    </li>
                                  );
                                }
                              }}
                              filterOptions={(options, { inputValue }) => {
                                ref.current = inputValue;
                                let minCharsToShowDropdown = 2;
                                let filteredOptions;

                                if (
                                  inputValue.length < minCharsToShowDropdown
                                ) {
                                  return []; // If less than minCharsToShowDropdown, hide the dropdown
                                } else {
                                  filteredOptions = options.filter(
                                    (option) =>
                                      option.artist_name &&
                                      option.artist_name
                                        .toLowerCase()
                                        .includes(
                                          inputValue && inputValue.toLowerCase()
                                        )
                                  );

                                  filteredOptions = filteredOptions.sort(
                                    (a, b) =>
                                      a.artist_name.localeCompare(b.artist_name)
                                  );

                                  filteredOptions = [
                                    ...filteredOptions,
                                    {
                                      artist_name: `Add "${inputValue}"`,
                                    },
                                  ];
                                }

                                return filteredOptions;
                              }}
                              sx={{
                                width: "-webkit-fill-available",
                              }}
                              // groupBy={(group) => group.artist_name}
                              getOptionLabel={(option) => {
                                return option && option.artist_name;
                              }}
                              value={values[name][index]}
                              isOptionEqualToValue={(option, value) => {
                                return option.artist_name === value.artist_name;
                              }}
                              error={
                                Boolean(errors[name]) && Boolean(touched[name])
                              }
                              helperText={
                                Boolean(touched[name]) && errors[name]
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1}>
                          {(values[name][index] || values[name][index - 1]) && (
                            <IconButton
                              onClick={() => {
                                remove(index);
                                values[name].length === 1 && push("");
                              }}
                            >
                              <DeleteIcon color="primary" />
                            </IconButton>
                          )}
                        </Grid>

                        {alreadyEdited ? (
                          <Grid
                            item
                            xs={1}
                            // className="bg-warning"
                            alignItems={"center"}
                            textAlign={"center"}
                            mt={1}
                          >
                            <CustomTooltip
                              title={
                                <div>
                                  <strong>{"Previous Value"}</strong>
                                  <div>{returnOldValue2(name, index)}</div>
                                </div>
                              }
                              arrow
                              placement="right-end"
                              enterDelay={300}
                              followCursor={true}
                            >
                              <CheckCircleIcon
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </CustomTooltip>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {values[name] &&
                values[name][values[name].length - 1] &&
                values[name][values[name] && values[name].length - 1].length !==
                  0 && (
                  <Grid
                    container
                    lg={11}
                    md={11}
                    sm={11}
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    px={1}
                  >
                    <IconButton onClick={() => push("")}>
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                )}
            </Box>
          )}
        </FieldArray>
      );
    }
  );

  function ReturnDisableReleaseType(releaseData) {
    if (releaseData?.type_of_release !== null) return true;
    if (
      releaseData?.status?.id === "1" ||
      releaseData?.status?.id === "10" ||
      releaseData?.status?.id === "4" ||
      releaseData?.status?.id === "9"
    ) {
      return false;
    } else {
      return true;
    }
  }

  // Custom filter function

  const returnOldValue = (key) => {
    if (
      !_source ||
      typeof _source !== "object" ||
      !_source.hasOwnProperty(key)
    ) {
      return undefined; // Return undefined if key is not found or _source is invalid
    }

    const value = _source[key];

    // If the value is an object, handle with switch case
    if (typeof value === "object" && value !== null) {
      switch (key) {
        case "genre_type":
          return value.genre_name; // Return username for key 'name'
        case "sub_genre_type":
          return value.subgenre_name; // Return username for key 'name'
        case "meta_language":
          return value.meta_language_name; // Return username for key 'name'
        case "plan_id":
          return value.plan_name; // Return username for key 'name'

        default:
          return value; // Return the object itself if no specific case is defined
      }
    }

    // If the value is not an object, return it directly
    return value;
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "10px 4px 4px rgba(0, 0, 0, 0.5)",
    },
  }));

  const TextFieldWithLabel = ({
    label,
    name,
    placeholder,
    errors,
    touched,
    inputMode,
    handleKeyPress,
    required,
    disabled,
    title,
  }) => {
    let alreadyEdited = false;

    if (isKeyPresent(name)) {
      alreadyEdited = true;
    }

    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          container
          gap={3}
          alignItems={"center"}
          justifyContent="center"
          display="flex"
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            textAlign="right"
            className="form_label"
            style={{ direction: "ltr" }}
          >
            <Typography>
              {label} {required && "*"}
            </Typography>
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={8}
            container
            // className="bg-warning"
            alignItems={"center"}
          >
            <Grid xs={alreadyEdited ? 11 : 12} item>
              <Tooltip title={title} arrow placement="right-end">
                <Field
                  variant="outlined"
                  as={MuiTextField}
                  fieldheight="30px"
                  id={name}
                  inputMode={inputMode}
                  handleKeyPress={handleKeyPress}
                  size="small"
                  name={name}
                  placeholder={placeholder}
                  error={Boolean(errors[name]) && Boolean(touched[name])}
                  helperText={Boolean(touched[name]) && errors[name]}
                  disabled={disabled}
                />
              </Tooltip>
            </Grid>
            {alreadyEdited ? (
              <Grid item xs={1} alignItems={"center"}>
                <CustomTooltip
                  title={
                    <div>
                      <strong>{"Previous Value"}</strong>
                      <div>{returnOldValue(name)}</div>
                    </div>
                  }
                  arrow
                  placement="right-end"
                  enterDelay={300}
                  followCursor={true}
                >
                  <CheckCircleIcon
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </CustomTooltip>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  };

  const AutoCompleteForm = (props) => {
    const {
      name,
      label,
      placeholder,
      options,
      errors,
      touched,
      required,
      values,
      disabled,
      title,
    } = props;

    let alreadyEdited = false;

    if (isKeyPresent(name)) {
      alreadyEdited = true;
    }

    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        container
        gap={3}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          textAlign="right"
          className="form_label"
          style={{
            direction: "ltr",
          }}
        >
          <Typography>
            {label} {required && "*"}{" "}
          </Typography>
        </Grid>

        <Grid item lg={8} md={8} sm={8} container alignItems={"center"}>
          <Grid xs={alreadyEdited ? 11 : 12} item>
            <Tooltip title={title} arrow placement="right-end">
              <Field
                name={name}
                size="small"
                component={AutoCompleteMultiSelect}
                disableClearable
                disabled={disabled}
                options={options ? options : []}
                // filterOptions={customFilterOptionslyrics}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                margin={0}
                getOptionLabel={(option) => option.name}
                textFieldProps={{
                  margin: "normal",
                  variant: "outlined",
                  placeholder: placeholder,
                }}
                // error={Boolean(errors[name]) && Boolean(touched[name])}
                // helperText={Boolean(touched[name]) && errors[name]}
              />
            </Tooltip>
          </Grid>

          {alreadyEdited ? (
            <Grid item xs={1} alignItems={"center"}>
              <CustomTooltip
                title={
                  <div>
                    <strong>{"Previous Value"}</strong>
                    <div>{returnOldValue(name)}</div>
                  </div>
                }
                arrow
                placement="right-end"
                enterDelay={300}
                followCursor={true}
              >
                <CheckCircleIcon
                  style={{
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const SelectFieldObject = ({
    label,
    name,
    options,
    size,
    placeholder,
    errors,
    touched,
    required,
    disabled,
    title,
  }) => {
    let alreadyEdited = false;

    if (isKeyPresent(name)) {
      alreadyEdited = true;
    }

    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        container
        gap={3}
        display="flex"
        justifyContent="center"
        //alignItems={"center"}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          textAlign="right"
          className="form_label"
          style={{ direction: "ltr" }}
          mt={0.5}
        >
          <Typography>
            {label} {required && "*"}
          </Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          container
          // className="bg-warning"
          alignItems={"flex-start"}
        >
          <Grid xs={alreadyEdited ? 11 : 12} item>
            <Field name={name}>
              {({ field, meta }) => (
                <Tooltip title={title} arrow placement="right-end">
                  <Select
                    input={
                      <OutlinedInput {...inputProps} disabled={disabled} />
                    }
                    MenuProps={menuProps}
                    value={field.value ? field.value.id : ""}
                    onChange={(event) => {
                      const selectedOption = options.find(
                        (option) => option.id === event.target.value
                      );
                      field.onChange(selectedOption); // Update the hidden field with the selected object
                    }}
                    {...field}
                    displayEmpty
                    size={size}
                    style={{ width: "100%", height: "30px" }}
                    error={meta.touched && meta.error ? true : false}
                  >
                    {placeholder && (
                      <MenuItem value="" disabled>
                        <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                      </MenuItem>
                    )}
                    {options &&
                      options.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          style={{
                            overflow: "hidden",
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Tooltip>
              )}
            </Field>
            &nbsp;
            <ErrorMessage name={name} />
          </Grid>

          {alreadyEdited ? (
            <Grid item xs={1} alignItems={"flex-start"}>
              <CustomTooltip
                title={
                  <div>
                    <strong>{"Previous Value"}</strong>
                    <div>{returnOldValue(name)}</div>
                  </div>
                }
                arrow
                placement="right-end"
                enterDelay={300}
                followCursor={true}
              >
                <CheckCircleIcon
                  style={{
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const FieldArrayComponent = ({
    name,
    label,
    required,
    values,
    errors,
    touched,
    title,
    disabled,
  }) => {
    let alreadyEdited = false;

    if (isKeyPresent(name)) {
      alreadyEdited = true;
    }

    const returnOldValue2 = (key, index) => {
      if (
        !_source ||
        typeof _source !== "object" ||
        !_source.hasOwnProperty(key)
      ) {
        return undefined; // Return undefined if key is not found or _source is invalid
      }

      const value = _source[key];

      return value && value[index] ? value[index] : null;
    };

    return (
      <FieldArray name={name}>
        {({ insert, remove, push }) => (
          <Box sx={{ width: "100%" }}>
            {Array.isArray(values[name]) &&
              values[name].map((item, index) => (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  gap={3}
                  alignItems={"center"}
                  justifyContent="center"
                  display="flex"
                >
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    textAlign="right"
                    className="form_label"
                    style={{ direction: "ltr" }}
                  >
                    {index === 0 && (
                      <Typography color={theme.palette.primary.main}>
                        {label} {required && "*"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} alignItems={"center"}>
                    <Grid item className="d-flex mb-1">
                      <Tooltip title={title} arrow placement="right-end">
                        <Field
                          as={MuiTextField}
                          variant="outlined"
                          placeholder={"Enter Version/Subtitle"}
                          id={`${name}[${index}]`}
                          size="small"
                          name={`${name}[${index}]`}
                          error={errors && Boolean(errors.hasOwnProperty(name))}
                        />
                      </Tooltip>
                      {values[name].length !== 1 && (
                        <IconButton onClick={() => remove(index)}>
                          <DeleteIcon color="primary" />
                        </IconButton>
                      )}

                      {alreadyEdited ? (
                        <Grid
                          item
                          xs={1}
                          // className="bg-warning"
                          alignItems={"center"}
                          textAlign={"center"}
                          mt={1}
                        >
                          <CustomTooltip
                            title={
                              <div>
                                <strong>{"Previous Value"}</strong>
                                <div>{returnOldValue2(name, index)}</div>
                              </div>
                            }
                            arrow
                            placement="right-end"
                            enterDelay={300}
                            followCursor={true}
                          >
                            <CheckCircleIcon
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </CustomTooltip>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ))}

            {values[name] &&
              values[name][values[name].length - 1] &&
              values[name][values[name] && values[name].length - 1].length !==
                0 && (
                <Grid
                  container
                  lg={11}
                  md={11}
                  sm={11}
                  mt={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  px={1}
                >
                  <IconButton onClick={() => push("")}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Grid>
              )}
          </Box>
        )}
      </FieldArray>
    );
  };

  const SelectField = ({
    label,
    name,
    options,
    size,
    placeholder,
    errors,
    touched,
    required,
    setFieldValue,
    setvalue,
    disabled,
    title,
  }) => {
    let alreadyEdited = false;

    if (isKeyPresent(name)) {
      alreadyEdited = true;
    }

    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        container
        gap={3}
        display="flex"
        justifyContent="center"
        //alignItems={"center"}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          textAlign="right"
          className="form_label"
          style={{
            direction: "ltr",
          }}
          mt={0.5}
        >
          <Typography>
            {label} {required && "*"}
          </Typography>
        </Grid>

        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          container
          // className="bg-warning"
          alignItems={"flex-start"}
        >
          <Grid xs={alreadyEdited ? 11 : 12} item>
            <FastField name={name}>
              {({ field, meta }) => (
                <Tooltip title={title} arrow placement="right-end">
                  <Select
                    {...field}
                    MenuProps={menuProps}
                    input={
                      <OutlinedInput {...inputProps} disabled={disabled} />
                    }
                    displayEmpty
                    size={size}
                    style={{ width: "100%", height: "30px" }}
                    error={meta.touched && meta.error ? true : false}
                    onChange={(event) => {
                      // Custom logic to handle the onChange event
                      const newValue = event.target.value;

                      if (newValue === "single") {
                        setFieldValue("plan_id", setvalue);
                      }
                      // Update the form field with the selected value
                      field.onChange(event);
                    }}
                  >
                    {placeholder && (
                      <MenuItem value="" disabled>
                        <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                      </MenuItem>
                    )}
                    {options.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              )}
            </FastField>
            &nbsp;
            <ErrorMessage name={name} />
          </Grid>

          {alreadyEdited ? (
            <Grid item xs={1} alignItems={"center"}>
              <CustomTooltip
                title={
                  <div>
                    <strong>{"Previous Value"}</strong>
                    <div>{returnOldValue(name)}</div>
                  </div>
                }
                arrow
                placement="right-end"
                enterDelay={300}
                followCursor={true}
              >
                <CheckCircleIcon
                  style={{
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={props.open}
      // onClose={props.onClose}
      fullWidth
      maxWidth="sm"
      className="scrollable"
      onClose={null}
    >
      <Box
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          px={3}
          py={0.5}
          sx={{
            backgroundColor: theme.palette.background.tab,
            boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item>
            <Typography fontSize={16}>
              {formatName(`Release Details`)}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                props.onClose();
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: `${theme.palette.text.primary}`,
                  fontSize: "18px",
                }}
              ></FontAwesomeIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box //p={1}
        ref={boxRef}
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={2}
      >
        <Paper className={classes.scrollbar}>
          {!props.isRequesting && (
            <Formik
              initialValues={initial}
              validate={(values) => {
                function isAlphanumericWithBoth(values) {
                  const containsLetters = /[a-zA-Z]/.test(values);
                  const containsNumbers = /[0-9]/.test(values);
                  const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

                  if (
                    containsLetters &&
                    containsNumbers &&
                    !containsSpecialChars
                  ) {
                    // The string contains both letters and numbers (alphanumeric) and no special characters.
                    return true;
                  } else {
                    // The string does not meet the criteria.
                    return false;
                  }
                }
                var errors = {};
                var priceErr = {};
                if (!values.title) {
                  errors.title = <RequiredText />;
                }
                if (!values.format) {
                  errors.format = <Required />;
                }
                if (!values.label) {
                  errors.label = <Required />;
                }

                if (!values.producer_catalogue_number) {
                  errors.producer_catalogue_number = <RequiredText />;
                }

                if (values.producer_catalogue_number) {
                  // errors.producer_catalogue_number = <Required />;

                  if (
                    !isAlphanumericWithBoth(values.producer_catalogue_number)
                  ) {
                    errors.producer_catalogue_number =
                      "producer catalogue number should only contain alphanumeric characters";
                  }
                }
                if (!values.genre_type) {
                  errors.genre_type = <RequiredText />;
                }
                if (!values.sub_genre_type) {
                  errors.sub_genre_type = <RequiredText />;
                }
                if (!values.physical_release_date) {
                  errors.physical_release_date = <Required />;
                }
                if (!values.plan_id) {
                  errors.plan_id = <Required />;
                  priceErr.plan_id = <Required />;
                }
                if (!values.copyright_year) {
                  errors.copyright_year = <Required />;
                }
                if (!values.production_year) {
                  errors.production_year = <Required />;
                }
                // if (values.artists.length == 0) {
                //   errors.artists = <Required />;
                // }

                if (values.artists) {
                  let trueValue = values.artists.some((item) => item !== "");
                  !trueValue && (errors.artists = []);
                  !trueValue && (errors.artists[0] = <RequiredText />);
                }

                if (!values.thumbnail_url) {
                  errors.thumbnail_url = <Required />;
                }

                if (!values.copyrighted_to) {
                  errors.copyrighted_to = <RequiredText />;
                }

                if (!values.published_by) {
                  errors.published_by = <RequiredText />;
                }

                if (!values.phonographic_copyright_year) {
                  errors.phonographic_copyright_year = <Required />;
                }

                if (!values.type_of_release) {
                  errors.type_of_release = <Required />;
                }

                if (!values.meta_language) {
                  errors.meta_language = <Required />;
                }

                if (values.ask_to_generate_upc === "No") {
                  if (!values.UPC_EAN) {
                    errors.UPC_EAN = <Required />;
                  }
                }

                return location.state?.shouldSubmit && location.state.priceError
                  ? priceErr
                  : location.state?.shouldSubmit && errors;
              }}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange
              onSubmit={(values) => {
                setLoading(true);
                let artistIds = [];

                values &&
                  values.artists &&
                  values.artists[0] !== null &&
                  values?.artists?.map((item) => {
                    item.artist_id &&
                      artistIds.push({
                        artist_id: item.artist_id,
                      });
                  });

                let featuring_artist_ids = [];

                values &&
                  values.featuring_artists &&
                  values.featuring_artists[0] !== null &&
                  values?.featuring_artists?.map((item) => {
                    item.artist_id &&
                      featuring_artist_ids.push({
                        artist_id: item.artist_id,
                      });
                  });

                let obj = {};

                const returnFormattedDate = (dateString) => {
                  const date = new Date(dateString);

                  // Get the date components
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
                  const day = String(date.getDate()).padStart(2, "0");

                  // Create the formatted date string
                  const formattedDate = `${year}-${month}-${day}`;

                  return formattedDate;
                };

                function compareArrays(array1, array2) {
                  // Extract artist_id from array2 for easier comparison
                  const array2Ids = array2.map((item) => item.artist_id.id);

                  // Check if lengths are different
                  const isLengthModified = array1.length !== array2.length;

                  // Check if artist_id values in array1 are all present in array2
                  const isContentModified = array1.some(
                    (item) => !array2Ids.includes(item.artist_id)
                  );

                  // If either length or content is modified, return true
                  const isModified = isLengthModified || isContentModified;

                  return isModified;
                }

                function DcompareArrays(arr1, arr2) {
                  // Check if lengths are different
                  if (arr1?.length !== arr2?.length) {
                    return true; // Arrays are modified
                  }

                  // Check if every element in arr1 exists in arr2 (and vice versa)
                  const isContentModified =
                    !arr1.every((item) => arr2.includes(item)) ||
                    !arr2.every((item) => arr1.includes(item));

                  return isContentModified; // Return true if content is modified
                }

                const musicAlbumValue =
                  Array.isArray(values["music/album"]) &&
                  values["music/album"].length === 1 &&
                  values["music/album"][0] === ""
                    ? null
                    : values["music/album"];

                Object.assign(
                  obj,
                  { release_id: formProps?.id },
                  values.title && {
                    title: values.title.trim(),
                  },
                  values.copyrighted_to && {
                    // values.copyrighted_to !== _source.copyrighted_to &&
                    copyrighted_to: values.copyrighted_to.trim(),
                  },
                  // values.format && {
                  //   format: values.format,
                  // },
                  values.genre_type && {
                    // values.genre_type.id !== _source.genre_type.id &&
                    genre_type: values.genre_type.id,
                  },

                  // values.label && {
                  //   label: values.label,
                  // },

                  // values.status && {
                  //   status: values.status,
                  // },

                  values.sub_genre_type && {
                    // _source.sub_genre_type?.id && //   values.sub_genre_type?.id !==
                    sub_genre_type: values.sub_genre_type.id,
                  },
                  values.production_year && {
                    // values.production_year !== _source.production_year &&
                    production_year: values.production_year,
                  },
                  values.published_by && {
                    // values.published_by !== _source.published_by &&
                    published_by: values.published_by.trim(),
                  },
                  values.producer_catalogue_number && {
                    // _source.producer_catalogue_number && //   values.producer_catalogue_number !==
                    producer_catalogue_number:
                      values.producer_catalogue_number.trim(),
                  },
                  values.physical_release_date && {
                    // _source.physical_release_date && //   values.physical_release_date !==
                    physical_release_date: returnFormattedDate(
                      values.physical_release_date
                    ),
                  },
                  artistIds?.length !== 0 && {
                    // compareArrays(artistIds, _source?.artists) &&
                    artists: artistIds,
                  },
                  featuring_artist_ids?.length !== 0 && {
                    // ) && //     _source?.featuring_artists //     featuring_artist_ids, //   compareArrays(
                    featuring_artists: featuring_artist_ids,
                  },
                  // values.thumbnail_url
                  //   ? {
                  //       thumbnail_url: values.thumbnail_url,
                  //     }
                  //   : {
                  //       thumbnail_url: null,
                  //     },
                  // values.ask_to_generate_upc && {
                  //   ask_to_generate_upc:
                  //     values.ask_to_generate_upc === "No" ? false : true,
                  // },
                  // values.UPC_EAN
                  //   ? {
                  //       UPC_EAN:
                  //         values.ask_to_generate_upc === "Yes"
                  //           ? ""
                  //           : values.UPC_EAN.trim(),
                  //     }
                  //   : {
                  //       UPC_EAN: null,
                  //     },
                  values.plan_id && {
                    // values.plan_id !== _source.plan_id?.id &&
                    plan_id: values.plan_id,
                  },
                  // values.type_of_release && {
                  //   type_of_release: values.type_of_release,
                  // },
                  values["music/album"] && {
                    ...(musicAlbumValue && {
                      ["music/album"]: musicAlbumValue,
                    }),
                  },

                  values.meta_language && {
                    // _source.meta_language?.meta_language && //   values.meta_language !==
                    meta_language: values.meta_language,
                  },

                  values.phonographic_copyright_year && {
                    // _source.phonographic_copyright_year && //   values.phonographic_copyright_year !==
                    phonographic_copyright_year:
                      values.phonographic_copyright_year.toString(),
                  },
                  values.copyright_year && {
                    // values.copyright_year !== _source.copyright_year &&
                    copyright_year: values.copyright_year.toString(),
                  }
                );

                if (formProps?.id) {
                  let callBack = (res) => {
                    if (res.status === "success") {
                      const param = {
                        current_page: "changeRequestForm",
                      };

                      props.dispatch(
                        MusicLabelsChangeRequestReleaseAction.requestMusicLabelsChangeRequestReleaseById(
                          props.requestId,
                          param
                        )
                      );
                    }
                    setLoading(false);
                    props.onClose();
                  };

                  props.dispatch(
                    MusicLabelsChangeRequestReleaseAction.requestPutMusicLabelsChangeRequestRelease(
                      obj,
                      props.requestId,
                      {},
                      callBack
                    )
                  );
                }
              }}
              innerRef={formRef}
            >
              {({
                errors,
                isValid,
                helperText,
                touched,
                dirty,
                handleChange,
                setFieldValue,
                values,
                submitForm,
              }) => (
                <Form style={{ width: "100%" }}>
                  {console.log("VALUES", { values, errors })}
                  <LoadingIndicator isActive={props.isRequesting}>
                    {!props.isRequesting && (
                      <>
                        <Grid
                          container
                          item
                          lg={12}
                          xl={12}
                          md={12}
                          sm={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Grid
                            container
                            sm={12}
                            sx={{
                              padding: {
                                lg: 5,
                                md: 1,
                                sm: 1,
                              },
                            }}
                          >
                            <Grid item sm={12}>
                              <TextFieldWithLabel
                                label="Release Title"
                                name="title"
                                placeholder="YYY Official Music Video"
                                size="small"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Albums require a title"
                              />

                              <br />

                              <FieldArrayComponent
                                name="music/album"
                                label={"Version/Subtitle"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                title="Enter Version/Subtitle"
                              />

                              <br />

                              <SelectFieldObject
                                label="Release Type"
                                name="type_of_release"
                                options={releaseTypeList}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                disabled={ReturnDisableReleaseType(
                                  _source && _source
                                )}
                                title="Enter the Type of Release"
                              />
                              <br />
                              <ArtistSelectDailoge
                                ref={primaryArtistFieldRef}
                                name="artists"
                                artistFieldRef={artistFieldRef}
                                label="Primary Artist"
                                placeholder="Primary Artist"
                                options={artistList ? artistList : []}
                                values={values}
                                touched={touched}
                                errors={errors}
                                required={true}
                                openProp={
                                  values && values.artists ? values.artists : []
                                }
                                setFieldValue={setFieldValue}
                                title="Enter a new artist or select an existing catalog artist as an additional main artist or other artist role.Each field should contain only one artist name unless the artists are generally listed together as a group.Spelling should be consistent across all artist's content"
                              />
                              <br />

                              <ArtistSelectDailoge
                                ref={featuringArtistFieldRef}
                                name="featuring_artists"
                                label="Featuring"
                                placeholder="Featuring Artist"
                                options={artistList ? artistList : []}
                                values={values}
                                touched={touched}
                                errors={errors}
                                openProp={
                                  values && values.featuring_artists
                                    ? values.featuring_artists
                                    : []
                                }
                                setFieldValue={setFieldValue}
                                title="Enter the Featuring Artist"
                              />
                              <br />

                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={3}
                                marginBottom={3}
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  mt={2}
                                  className="form_label"
                                  style={{ direction: "rtl" }}
                                ></Grid>

                                <Grid item lg={8} md={8} sm={8}>
                                  <Typography className="mx-3">
                                    Various Artists/Compilation
                                  </Typography>
                                </Grid>
                              </Grid>
                              <AutoCompleteForm
                                label="Genre"
                                name="genre_type"
                                options={genreList}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Choose the product's genre.Storefront genre categorization often occurs at the discretion of the music service and may differ slightly from the chosen genre."
                              />
                              {setSelectedGenre(values.genre_type?.id)}
                              <br />
                              <AutoCompleteForm
                                label="Subgenre"
                                name="sub_genre_type"
                                options={subgenrelist}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                values={values}
                                required={true}
                                title="Enter the SubGenreType from the selected Genre Field"
                              />

                              <br />
                              <SelectFieldObject
                                label="Label Name"
                                name="label"
                                options={labelList}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                disabled={true}
                                title="Search and choose labels associated to user account.Contact label representative to add new labels."
                              />
                              <br />
                              <SelectFieldObject
                                label="Meta Language"
                                name="meta_language"
                                options={nameslist}
                                size="small"
                                required={true}
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                title="Choose the Language in which the metadata on this product is primarily written.If there is more than one language,choose the most prevalent"
                              />
                              <br />
                              <SelectField
                                label="Format"
                                name="format"
                                options={formatArray}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                setFieldValue={setFieldValue}
                                setvalue={
                                  planList &&
                                  planList.filter((item) => {
                                    return item.id === "1";
                                  })
                                    ? planList.filter((item) => {
                                        return item.id === "1";
                                      })[0] &&
                                      planList.filter((item) => {
                                        return item.id === "1";
                                      })[0].id
                                    : ""
                                }
                                disabled={true}
                                title={"Enter the format as Single,EP,Album"}
                              />
                              <br />
                              <SelectFieldObject
                                label="Plan"
                                name="plan_id"
                                options={planList}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter Product Price.All proces are wholesale/suggested retail list price(SRLP) in US dollars.The final retail price is set at the discretion of music service and cannot be guaranteed."
                              />
                              <br />
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={3}
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  className="form_label"
                                >
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                    }}
                                  >
                                    <UploadPopper
                                      format={
                                        "Input the first date a music was officially released."
                                      }
                                    >
                                      <InfoIcon sx={{ fontSize: 15 }} />
                                      Physical/Original Release Date *
                                    </UploadPopper>{" "}
                                  </Typography>
                                </Grid>

                                <Grid item lg={8} md={8} sm={8} container>
                                  <Grid
                                    item
                                    xs={
                                      isKeyPresent("physical_release_date")
                                        ? 11
                                        : 12
                                    }
                                  >
                                    <Tooltip
                                      title="Choose the date on which the earliest format of this product was first made available for consumption.This is often the same as the Release Date and would most often differ when there is a previously released version in another format."
                                      arrow
                                      placement="right-end"
                                    >
                                      <div>
                                        <FastField
                                          component={MuiDatePicker}
                                          size="small"
                                          value={
                                            values.physical_release_date
                                              ? values.physical_release_date
                                              : null
                                          }
                                          disablePast={false}
                                          format="DD/MM/YYYY"
                                          name={`physical_release_date`}
                                          textFieldProps={{
                                            fullWidth: true,
                                            margin: "normal",
                                            variant: "outlined",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    &nbsp;
                                    <ErrorMessage name="physical_release_date" />
                                  </Grid>

                                  {isKeyPresent("physical_release_date") ? (
                                    <Grid item xs={1} alignItems={"center"}>
                                      <CustomTooltip
                                        title={
                                          <div>
                                            <strong>{"Previous Value"}</strong>
                                            <div>
                                              {returnOldValue(
                                                "physical_release_date"
                                              )}
                                            </div>
                                          </div>
                                        }
                                        arrow
                                        placement="right-end"
                                        enterDelay={300}
                                        followCursor={true}
                                      >
                                        <CheckCircleIcon
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </CustomTooltip>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </Grid>
                              <br />
                              <SelectField
                                label="Production Year"
                                name="production_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the production Year"
                              />
                              <br />
                              <SelectField
                                label="Copyright Year"
                                name="copyright_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the copyright year"
                              />
                              <br />
                              <SelectField
                                label="Phonographic Copyright Year"
                                name="phonographic_copyright_year"
                                options={yearArray.sort((a, b) => b - a)}
                                size="small"
                                placeholder="Select an option"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter phonographic copyright text.Enter the year date on which the sound recording was produced and the name of the copyright owner of the sound recording"
                              />
                              <br />

                              {values.ask_to_generate_upc === "Yes" ? (
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  gap={3}
                                  alignItems={"center"}
                                  justifyContent="center"
                                  display="flex"
                                >
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    textAlign="right"
                                    className="form_label"
                                    style={{ direction: "ltr" }}
                                  >
                                    <Typography>UPC/EAN</Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    textAlign="left"
                                  >
                                    <Tooltip
                                      title="Choose UPC to have Ingrooves assign a upc on submission of the product"
                                      arrow
                                      placement="right-end"
                                    >
                                      <FastField
                                        as={MuiTextField}
                                        variant="outlined"
                                        placeholder="UPC/EAN"
                                        id={"UPC_EAN"}
                                        fieldheight="30px"
                                        size="small"
                                        name="UPC_EAN"
                                        disabled={true}
                                        className="col-10"
                                      />
                                    </Tooltip>
                                    {/* <ErrorMessage name={name} /> */}
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  gap={3}
                                  alignItems={"center"}
                                  justifyContent="center"
                                  display="flex"
                                >
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    textAlign="right"
                                    className="form_label"
                                    style={{ direction: "ltr" }}
                                  >
                                    <Typography>UPC/EAN *</Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    textAlign="left"
                                  >
                                    <Tooltip
                                      title=" Specify a value for UPC/EAN "
                                      arrow
                                      placement="right-end"
                                    >
                                      <FastField
                                        as={MuiTextField}
                                        variant="outlined"
                                        onPaste={(e) => e.preventDefault()}
                                        placeholder="UPC/EAN"
                                        id={"UPC_EAN"}
                                        fieldheight="30px"
                                        handleKeyPress={handleNumericKeyPress}
                                        maxLength={14}
                                        disabled={true}
                                        size="small"
                                        name="UPC_EAN"
                                        error={
                                          Boolean(errors.UPC_EAN) &&
                                          Boolean(touched.UPC_EAN)
                                        }
                                        className="col-10"
                                      />
                                    </Tooltip>
                                    <ErrorMessage name="UPC_EAN" />
                                  </Grid>
                                </Grid>
                              )}
                              <br />
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={3}
                                alignItems={"center"}
                                justifyContent="center"
                                display="flex"
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  className="form_label"
                                  style={{ direction: "ltr" }}
                                >
                                  <Typography>
                                    Ask to generate an UPC/EAN
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  textAlign="left"
                                >
                                  <Field name="ask_to_generate_upc">
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        // value={askToGenerateISRC}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "ask_to_generate_upc",
                                            event.target.value
                                          );

                                          if (event.target.value === "Yes") {
                                            setFieldValue("UPC_EAN", "");
                                          }
                                        }}
                                      >
                                        {AskToGenerateUPCEAN.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={<Radio disabled={true} />}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                              <br />
                              <TextFieldWithLabel
                                label="&#169; Copyright"
                                name="copyrighted_to"
                                placeholder="Copyright"
                                errors={errors}
                                size="small"
                                touched={touched}
                                required={true}
                                title="Enter copyrighted notice text.Enter the year date of first publication of the entire musical work and the name of the copyright owner.For derivative works or compilations,enter the date of publication of the work as a hole."
                              />
                              <br />
                              <TextFieldWithLabel
                                label="&#x24C5; Published"
                                name="published_by"
                                placeholder="Published"
                                size="small"
                                errors={errors}
                                touched={touched}
                                required={true}
                                title="Enter the  published by field"
                              />
                              <br />
                              <TextFieldWithLabel
                                label="Producer Catalogue Number"
                                name="producer_catalogue_number"
                                placeholder="Producer Catalogue Number"
                                errors={errors}
                                touched={touched}
                                size="small"
                                handleKeyPress={handleKeyPress}
                                required={true}
                                title="Enter catalog # that is unique within choosen labels's catalog"
                              />
                              <br />
                              <br />
                            </Grid>
                          </Grid>
                        </Grid>
                        <DialogActions className="m-1">
                          <Grid
                            container
                            item
                            lg={6}
                            md={8}
                            sm={8}
                            justifyContent="flex-end"
                            alignItems={"center"}
                          >
                            {(props.status === "draft" ||
                              props.status === "rejected") && (
                              <Grid item lg={5} md={5} sm={5} mr={2}>
                                {props.edit ? (
                                  <MuiButton
                                    loading={loading}
                                    type="submit"
                                    name="Submit"
                                    save
                                    width="100%"
                                  />
                                ) : (
                                  <MuiButton
                                    loading={loading}
                                    type="submit"
                                    save
                                    name="Save"
                                    width="100%"
                                  />
                                )}
                              </Grid>
                            )}

                            <Grid item lg={5} md={5} sm={5}>
                              {!props.edit ? (
                                <MuiButton
                                  name={"Cancel"}
                                  width="100%"
                                  onClick={() => {
                                    props.onClose();
                                  }}
                                  cancel
                                />
                              ) : (
                                <MuiButton
                                  type="reset"
                                  name="Reset"
                                  onClick={Formik.resetForm}
                                  width="100%"
                                  cancel
                                />
                              )}
                            </Grid>
                          </Grid>
                        </DialogActions>
                      </>
                    )}
                  </LoadingIndicator>
                </Form>
              )}
            </Formik>
          )}
        </Paper>
      </Box>
    </Dialog>
  );
};

export default connect(mapStateToProps)(ReleaseChangeForm);
