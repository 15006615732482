import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import MuiButton from "../../components/muiButton/MuiButton";
import { useDispatch } from "react-redux";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import environment from "environment";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import CircularLoadingIndicator from "../../components/loading-indicator/CircularLoadingIndicator";

export default function VideoFrameSelector({
  url,
  time,
  setVideoFrameSelectorOpen,
  videoFramesSelectorOpen,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [frames, setFrames] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);

  const dispatch = useDispatch();

  let access_token = localStorage.getItem("Auth")
    ? JSON.parse(localStorage.getItem("Auth")).access_token
    : "";

  const fetchVideo = async () => {
    try {
      if (url) {
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const videoBlob = new Blob([response.data], { type: "video/mp4" }); // Assuming it's an MP4, adjust if necessary
        const videoURL = URL.createObjectURL(videoBlob);
        console.log("VIDEO_URL", videoURL, response, videoRef.current);
        if (videoRef.current) {
          videoRef.current.src = videoURL;
          videoRef.current.load(); // Ensure the video is ready to play/load
          setVideoLoaded("loading"); // Reset the video load state
        }
      }
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };

  useEffect(() => {
    if (!videoFramesSelectorOpen) {
      // Clear the video reference when the dialog is closed
      if (videoRef.current) {
        videoRef.current.src = ""; // Remove the video source
        videoRef.current.load(); // Reset video state
        videoRef.current = null; // Clear the videoRef's current value
      }

      setFrames([]);
    }

    // Optionally, you can perform other actions when the dialog is open
    if (videoFramesSelectorOpen) {
      fetchVideo();
    }
  }, [videoFramesSelectorOpen]);

  const extractFrames = () => {
    console.log("extractFrames_running", videoRef.current);
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const context = canvasElement.getContext("2d");

    if (!videoElement || !context) return;

    const captureFrame = (time) => {
      console.log("captureFrame_running", time);

      return new Promise((resolve) => {
        const handleSeeked = () => {
          // Draw the frame on the canvas once the seek is complete
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;
          context.drawImage(
            videoElement,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          const frameData = canvasElement.toDataURL("image/jpeg");
          console.log("frameData", frameData);
          videoElement.removeEventListener("seeked", handleSeeked); // Remove event listener after use
          resolve({ frame: frameData, time });
        };

        // Attach the event listener for the seek operation
        videoElement.addEventListener("seeked", handleSeeked);
        videoElement.currentTime = time; // Set the current time to trigger seeking
      });
    };

    // Capture frames between 4 to 6 seconds
    const startCapture = async () => {
      console.log("startCaptureRunning");
      const framePromises = [];
      for (let val = time - 0.5; val <= time + 1; val += 1 / 3) {
        framePromises.push(await captureFrame(val)); // Wait for each frame extraction to complete
      }
      const capturedFrames = await Promise.all(framePromises);
      console.log("capturedFrames", capturedFrames);
      setFrames(capturedFrames); // Update state to display captured frames
    };

    startCapture();
  };

  const videoElement = videoRef.current;
  useEffect(() => {
    console.log("VIDEO_ELEMENT", videoRef.current);

    if (videoElement) {
      const handleLoadedData = () => {
        console.log("Video is fully loaded and ready to play");
        setVideoLoaded(true);
        extractFrames();
      };

      videoElement.addEventListener("loadeddata", handleLoadedData);

      // Cleanup the listener when component unmounts or dialog closes
      return () => {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      };
    }
  }, [videoElement, videoFramesSelectorOpen]);

  console.log("VIDEO_REFFFF", videoRef.current);

  // Function to convert base64 data to Blob
  const base64ToBlob = (base64Data, contentType = "image/jpeg") => {
    // Check if the base64Data has a header like 'data:image/png;base64,'
    const base64String = base64Data.includes(",")
      ? base64Data.split(",")[1]
      : base64Data;

    // Decode the base64 string into binary data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    // Convert each character to its corresponding byte value
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Create a Blob using the binary data
    return new Blob([byteNumbers], { type: contentType });
  };

  // Function to send the selected frame to the server
  const sendFrameToServer = async (selectedFrame) => {
    setThumbnailLoading(true);
    try {
      const { frame, time } = selectedFrame;

      // Convert base64 frame data to a Blob
      const frameBlob = base64ToBlob(frame, "image/jpeg");

      console.log("frameBlob", frameBlob);

      // Create a FormData object and append the Blob as a file
      const formData = new FormData();
      formData.append("file", frameBlob, `frame_at_${time}.jpg`); // Using time as the filename

      fetch(
        `${
          environment.api.fileUpload
        }/add?current_page=releaseInfo&release_id=${localStorage.getItem(
          "id"
        )}&thumbnail=true`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_token,
          },
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response?.status === "success") {
            let params = {
              current_page: "releaseInfo",
            };

            let obj = {};
            Object.assign(obj, { thumbnail_url: response.url });

            let callBack = (res) => {
              if (res.status === "success") {
                dispatch(
                  ReleasesAction.requestReleaseById(
                    localStorage.getItem("id"),
                    params
                  )
                );

                setVideoFrameSelectorOpen(false);
              }
              setThumbnailLoading(false);
            };

            dispatch(
              ReleasesAction.requestPutRelease(
                obj,
                localStorage.getItem("id"),
                params,
                callBack
              )
            );
          } else {
          }
        });
    } catch (error) {
      setThumbnailLoading(false);
      console.error("Error sending frame to the server:", error);
    }
  };

  console.log("FRAMES", frames);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const displayValue = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  function displayTimeValue(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const displayValue = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return displayValue;
  }

  return (
    <Dialog open={videoFramesSelectorOpen} maxWidth={"md"} fullWidth={true}>
      <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
        Choose Thumbnail
      </DialogTitle>
      {videoFramesSelectorOpen && (
        <>
          <video
            ref={videoRef}
            controls
            style={{ display: "none" }} // Hide the video, only using for frame extraction
          ></video>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        </>
      )}

      <DialogContent dividers>
        <Box>
          <Typography>Thumbnail Time : {displayValue}</Typography>
        </Box>
        {thumbnailLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10, // Ensures loader appears above other elements
            }}
          >
            <CircularLoadingIndicator isActive={thumbnailLoading} />
          </Box>
        )}
        <Grid
          container
          direction="row"
          wrap="nowrap"
          sx={{
            overflowX: "auto", // Enables horizontal scrolling
            display: "flex", // Use flexbox for horizontal scrolling
            width: "100%",

            padding: videoLoaded && frames.length ? 3 : 0,
            justifyContent: "center",
            position: "relative",
          }}
          columnSpacing={3}
        >
          {videoLoaded && frames.length > 0 ? (
            frames.map((frameData, index) => (
              <Grid
                item
                key={index}
                sx={{ opacity: thumbnailLoading ? 0.3 : null }}
              >
                <Typography
                  component="div"
                  onClick={() => sendFrameToServer(frameData)}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    src={frameData?.frame}
                    style={{ width: "250px", aspectRatio: "auto" }}
                    alt={`Frame ${index}`}
                  />
                  <Typography textAlign="center">
                    {displayTimeValue(frameData?.time)}
                  </Typography>
                </Typography>
              </Grid>
            ))
          ) : (
            <LoadingBgImage styling={{ padding: "0px", width: 250 }} />
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <MuiButton
          name={"Close"}
          onClick={() => {
            setVideoFrameSelectorOpen(false);
            setVideoLoaded(false);
          }}
          width={"150px"}
          size={"small"}
        />
      </DialogActions>
    </Dialog>
  );
}
