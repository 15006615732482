import { BaseModel } from "sjs-base-model";

export default class MusicLabelsChangeRequestReleaseShadowByIdModel extends BaseModel {
  // response getting from the api mentioned end point
  _id = "";
  index = "";
  _primary_term = 0;
  _seq_no = 0;
  _source = {};
  _version = 0;
  found = false;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
