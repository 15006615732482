import ActionUtility from "../../utilities/ActionUtility";
import MusicLabelsChangeRequestTrackEffect from "./MusicLabelsChangeRequestTrackEffect";

export default class MusicLabelsChangeRequestTrackAction {
  // MusicLabelsChangeRequestTrackAction action with an function declaration
  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK";
  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_FINISHED";

  static REQUEST_MUSICLABELSCHANGEREQUESTTRACK_BY_ID =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_BY_ID";
  static REQUEST_REQUEST_MUSICLABELSCHANGEREQUESTTRACK_BY_ID_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_BY_ID_FINISHED";

  static REQUEST_PUT_MUSICLABELSCHANGEREQUESTTRACK =
    "MusicLabelsChangeRequestTrackAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTTRACK";
  static REQUEST_PUT_MUSICLABELSCHANGEREQUESTTRACK_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTTRACK_FINISHED";

  static REQUEST_POST_MUSICLABELSCHANGEREQUESTTRACK =
    "MusicLabelsChangeRequestTrackAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTTRACK";
  static REQUEST_POST_MUSICLABELSCHANGEREQUESTTRACK_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTTRACK_FINISHED";

  static REQUEST_DELETE_MUSICLABELSCHANGEREQUESTTRACK =
    "MusicLabelsChangeRequestTrackAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTTRACK";
  static REQUEST_DELETE_MUSICLABELSCHANGEREQUESTTRACK_FINISHED =
    "MusicLabelsChangeRequestTrackAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTTRACK_FINISHED";

  // METHODS
  // Organization GET function
  static requestMusicLabelsChangeRequestTrack(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK,
        MusicLabelsChangeRequestTrackEffect.requestMusicLabelsChangeRequestTrack,
        params,
        callBack
      );
    };
  }

  static requestPutMusicLabelsChangeRequestTrack(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackAction.REQUEST_PUT_MUSICLABELSCHANGEREQUESTTRACK,
        MusicLabelsChangeRequestTrackEffect.requestPutMusicLabelsChangeRequestTrack,
        data,
        id,
        params
      );
    };
  }

  static requestPostMusicLabelsChangeRequestTrack(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackAction.REQUEST_POST_MUSICLABELSCHANGEREQUESTTRACK,
        MusicLabelsChangeRequestTrackEffect.requestPostMusicLabelsChangeRequestTrack,
        data,
        params,
        callBack
      );
    };
  }

  static requestMusicLabelsChangeRequestTrackById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackAction.REQUEST_MUSICLABELSCHANGEREQUESTTRACK_BY_ID,
        MusicLabelsChangeRequestTrackEffect.requestMusicLabelsChangeRequestTrackById,
        id,
        params
      );
    };
  }

  static requestDeleteMusicLabelsChangeRequestTrack(id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MusicLabelsChangeRequestTrackAction.REQUEST_DELETE_MUSICLABELSCHANGEREQUESTTRACK,
        MusicLabelsChangeRequestTrackEffect.requestDeleteMusicLabelsChangeRequestTrack,
        id,
        callback
      );
    };
  }
}
