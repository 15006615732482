import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import MusicLabelsChangeRequestTrackShadowModel from "./musicLabelsChangeRequestTrackShadowModel/MusicLabelsChangeRequestTrackShadowGetModel";
import MusicLabelsChangeRequestTrackShadowByIdModel from "./musicLabelsChangeRequestTrackShadowModel/MusicLabelsChangeRequestTrackShadowByIdModel";
export default class MusicLabelsChangeRequestTrackEffect {
  // Get method for the
  static async requestMusicLabelsChangeRequestTrackShadow(params, callBack) {
    const endpoint = environment.api.musiclabelRequestChangeTrackShadow;
    let response = EffectUtility.getToModel(
      MusicLabelsChangeRequestTrackShadowModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestMusicLabelsChangeRequestTrackShadowById(id, params) {
    const endpoint =
      environment.api.musiclabelRequestChangeTrackShadow + "/" + id;
    return EffectUtility.getToModel(
      MusicLabelsChangeRequestTrackShadowByIdModel,
      endpoint,
      params
    );
  }
}
